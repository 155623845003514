import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import {
  Children, FC, useCallback, useEffect, useRef, useState,
} from 'react';
import useStyles from './styles';

export interface CarouselProps {
  className?: string;
  autoplay?: boolean;
  timeout?: number;
}

const Carousel: FC<CarouselProps> = ({
  className, children, autoplay = false, timeout = 5000,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const arrayChildren = Children.toArray(children);
  const { classes, cx, theme } = useStyles();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const startAutoplay = useCallback(() => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    if (autoplay) {
      timeoutRef.current = setInterval(() => {
        setActiveIndex((prev) => (prev + 1) % arrayChildren.length);
      }, timeout);
    }
  }, [arrayChildren.length, autoplay, timeout]);

  const handleSetActive = (index: number) => {
    setActiveIndex(index);
    startAutoplay();
  };

  useEffect(() => {
    startAutoplay();

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, [startAutoplay, timeout]);

  return (
    <Box className={cx(classes.root, className)}>
      <Box className={classes.indicators}>
        {Children.map(arrayChildren, (child, index) => (
          <Button
            className={cx(classes.indicatorItem, activeIndex === index && 'active')}
            onClick={() => handleSetActive(index)}
          />
        ))}
      </Box>

      <Box className={classes.container}>
        {Children.map(arrayChildren, (child, index) => {
          const isActive = activeIndex === index;

          return (
            <Fade
              in={isActive}
              mountOnEnter
              unmountOnExit
              timeout={{
                exit: 0,
                enter: theme.transitions.duration.enteringScreen,
              }}
            >
              <Box className={classes.item}>{child}</Box>
            </Fade>
          );
        })}
      </Box>
    </Box>
  );
};

export default Carousel;
