import { lighten } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: theme.spacing(6),
    padding: theme.spacing(4, 3),
    textAlign: 'center',
    boxShadow: `0px 4px 114px ${lighten(theme.palette.primary.light, 0.2)}`,
    [theme.breakpoints.up('sm')]: {
      boxShadow: 'none',
      padding: theme.spacing(4),
    },
    '& > .MuiCardContent-root': {
      padding: theme.spacing(0, 1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
  },
  termsTitle: {
    margin: '0 auto',
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    marginTop: theme.spacing(4),
  },
  monthlyPayment: {
    fontWeight: 400,
    '& > .MuiCircularProgress-root': {
      display: 'block',
      margin: 'auto',
    },
  },
}));

export default useStyles;
