import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import api from '@/api/.';
import { DocumentTypeEnum, LoanRequest, OnboardingStatus } from '@/api/onboarding/types';
import { DocumentItemStatus } from '@/components/DocumentItem';
import accountDataConfig from '@/config/accountData';
import amortization from '@/constants/amortization';
import ROUTES from '@/constants/routes';
import { mapDocumentToFile } from '@/modules/Documents/useDocumentsPage';
import useOnboardingStore from '@/modules/Onboarding/store';
import { ContractFormData } from './components/ContractForm';

const useContractPage = () => {
  const [{
    folio, firstname, loanRequest, contract, status,
  }, actions] = useOnboardingStore();
  const navigate = useNavigate();
  const [uploadStatus, setDocumentUploadStatus] = useState<DocumentItemStatus>();

  const { amount } = loanRequest as LoanRequest;

  const form = useForm<ContractFormData>({
    defaultValues: {
      fullname: '',
      clabe: '',
      accountNumber: '',
      bankName: '',
      signature: '',
      tnc: false,
    },
    mode: 'onChange',
  });

  const accountData = {
    name: accountDataConfig.name,
    account: accountDataConfig.account,
    clabe: accountDataConfig.clabe,
    bankName: accountDataConfig.bankName,
    reference: folio,
    phone: accountDataConfig.phone,
    availability: accountDataConfig.availability,
  };

  const sendData = async (data: ContractFormData) => {
    await api.onboarding.upsertAccount(data);
    await actions.uploadDocuments(
      {
        accountStatementConfirm: data.accountStatement,
      },
      (type, loadStatus, doc) => {
        if (type === DocumentTypeEnum.accountStatementConfirm) {
          setDocumentUploadStatus(loadStatus);

          if (loadStatus === 'completed') {
            form.setValue('accountStatement', mapDocumentToFile(doc));
          }
        }
      },
    );
    await actions.setContract({
      signature: data.signature,
    });
    actions.setStatus(OnboardingStatus.REQUEST_SIGNED);
  };

  const handleFormSubmit = async (data: ContractFormData) => {
    if (status === OnboardingStatus.REQUEST_SIGNED) {
      navigate({ pathname: ROUTES.root });
    } else {
      await sendData(data);
    }
  };

  const handleDownloadClick = async () => {
    const interestItem = amortization.interestItems.find((ir) => {
      return ir.match(loanRequest?.term as number);
    });

    const response = await api.amortization.getFile({
      amount: +(loanRequest?.amount as string),
      interestRate: interestItem?.value as number,
      term: loanRequest?.term as number,
      date: moment(contract?.signDate).format('YYYY-MM-DD'),
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = `Amortizacion-${folio}.pdf`;
    link.click();
  };

  return {
    form,
    firstname,
    amount,
    signDate: contract?.signDate,
    accountData,
    uploadStatus,
    finished: status === OnboardingStatus.REQUEST_SIGNED,
    handleFormSubmit,
    handleDownloadClick,
  };
};

export default useContractPage;
