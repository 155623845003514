import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Check: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="14" height="9" viewBox="0 0 14 9">
      <path d="M12.9205 1.06814L5.74682 8.24181C5.64133 8.37368 5.48308 8.42642 5.32484 8.42642C5.14023 8.42642 4.98198 8.37368 4.87649 8.24181L1.07866 4.44398C0.814925 4.20662 0.814925 3.81101 1.07866 3.57365C1.31603 3.30991 1.71163 3.30991 1.949 3.57365L5.32484 6.92312L12.0502 0.197803C12.2875 -0.0659344 12.6831 -0.0659344 12.9205 0.197803C13.1842 0.435167 13.1842 0.830774 12.9205 1.06814Z" />
    </SvgIcon>
  );
};

export default Check;
