import TextField, { TextFieldProps } from '@mui/material/TextField';
import { ChangeEvent } from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

export type FormTextFieldProps<TFieldValues extends FieldValues = FieldValues> = Pick<ControllerProps<TFieldValues>, 'name' | 'control' | 'rules'> &
Omit<TextFieldProps, 'value' | 'onChange' | 'error'> & {
  transformValue?: (value: string) => string;
};

const FormTextField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  rules,
  helperText,
  transformValue,
  ...textFieldProps
}: FormTextFieldProps<TFieldValues>) => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...args: any[]) => void,
  ) => {
    const sendEvent = event;

    if (typeof transformValue === 'function') {
      sendEvent.target.value = transformValue(event.target.value);
    }

    onChange(sendEvent);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textFieldProps}
          value={value}
          error={!!error}
          helperText={error?.message || helperText}
          onChange={(e) => handleChange(e, onChange)}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default FormTextField;
