import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width: 'fit-content',
    flexWrap: 'nowrap',
  },
  line: {
    position: 'absolute',
    top: theme.spacing(4.5),
    left: 0,
    marginTop: -0.5,
    marginLeft: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    height: 1,
    backgroundColor: theme.palette.secondary.contrastText,
  },
  item: {
    position: 'relative',
    margin: theme.spacing(0, 1),
  },
}));

export default useStyles;
