export default {
  es: {
    mainTitle:
      'Te prestamos hasta $15,000<1>*</1> en menos de 48 hrs sin consultar tu buró de crédito.',
    mainSubtitle: 'Ayudarte jamás había sido tan sencillo.',
    quoterTitle: '¿Cuánto necesitas?',
    quoterTerm: '¿A cuantos meses deseas diferir tus pagos?',
    quoterPaymentText: 'Tu pago mensual estimado',
    requestNow: '¡SOLICÍTALO YA!',
    exclusiveLoan: '<1>*</1> Préstamo exclusivo para residentes de Zibatá.',
    tncConsult: 'Consulta nuestros <1>Términos y Condiciones.</1>',
    stepsTitle: 'Solicítarlo es muy sencillo <1>y rápido!</1>',
    step1: 'Cuéntanos de ti y sobre tus ingresos',
    step2: 'Recibe una oferta',
    step3: 'Firma tu contrato',
    step4: 'Recibe y disfruta en 48 hrs',
    imagesTitle1: 'Date ese gusto',
    imagesText1: 'Seguro hay algo que te mueve y no te has podido comprar.',
    imagesTitle2: 'Despreocúpate',
    imagesText2: 'Soluciona pequeños imprevistos que nos impiden dormir.',
    imagesTitle3: 'Disfruta',
    imagesText3: 'Haz ese viaje con tus seres queridos a donde nunca han ido.',
    bottomStartTitle: '¿COMENZAMOS?',
    requestLoan: 'SOLICITAR PRÉSTAMO',
  },
};
