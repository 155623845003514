import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Logo: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M50 49.7025H37.5V46.0195H46.3169V3.68306H3.68306V46.0195H12.9878V49.7025H0V0H50V49.7025Z" />
      <path d="M16.3365 49.6544C16.122 49.6498 15.908 49.6299 15.6963 49.5949C15.5255 49.5697 15.3588 49.5221 15.2004 49.4532V48.7761C15.2911 48.8101 15.3789 48.8384 15.4639 48.8611C15.5506 48.8865 15.6385 48.9073 15.7274 48.9234C15.8181 48.9404 15.9116 48.9546 16.0107 48.9659C16.1099 48.9772 16.2175 48.9829 16.3365 48.9829V48.2265C16.1905 48.2069 16.0457 48.1785 15.9031 48.1415C15.7664 48.11 15.6361 48.0553 15.5178 47.98C15.4016 47.9101 15.3043 47.8128 15.2345 47.6967C15.1565 47.5577 15.1192 47.3997 15.1268 47.2405C15.125 47.1012 15.153 46.963 15.209 46.8354C15.2656 46.7168 15.3467 46.6116 15.4469 46.5266C15.561 46.4362 15.6893 46.3654 15.8266 46.3169C15.9904 46.2628 16.1594 46.2258 16.3309 46.2064V45.8523H16.8805V46.1979C17.0523 46.2073 17.2235 46.2263 17.3933 46.2546C17.5561 46.2802 17.7163 46.32 17.8721 46.3736V47.0564C17.5514 46.9491 17.218 46.8843 16.8805 46.8637V47.6315C17.0257 47.6542 17.1695 47.6855 17.3111 47.725C17.4458 47.7587 17.574 47.8142 17.6908 47.8893C17.8064 47.9598 17.9036 48.057 17.9741 48.1726C18.0507 48.3111 18.0879 48.4678 18.0817 48.6259C18.0873 48.7579 18.062 48.8893 18.0079 49.0098C17.9538 49.1303 17.8724 49.2365 17.7701 49.3201C17.5134 49.5067 17.2086 49.6158 16.8918 49.6345V50H16.3422L16.3365 49.6544ZM15.8889 47.2065C15.8868 47.2498 15.8959 47.2928 15.9152 47.3315C15.9346 47.3702 15.9636 47.4033 15.9994 47.4275C16.1027 47.4893 16.2173 47.5298 16.3365 47.5465V46.8836C16.2111 46.8875 16.0893 46.9268 15.9852 46.9969C15.9554 47.0231 15.9314 47.0553 15.9148 47.0915C15.8982 47.1276 15.8894 47.1668 15.8889 47.2065ZM17.3055 48.6429C17.3082 48.6002 17.3 48.5575 17.2816 48.5189C17.2633 48.4802 17.2354 48.4469 17.2006 48.422C17.1018 48.3627 16.9919 48.3241 16.8777 48.3086V48.9602C17.1723 48.9234 17.314 48.8158 17.314 48.6429H17.3055Z" />
      <path d="M20.5806 49.6544C20.3669 49.6499 20.1539 49.63 19.9431 49.5949C19.7722 49.5705 19.6054 49.5228 19.4473 49.4532V48.7761C19.6177 48.8396 19.793 48.8889 19.9715 48.9234C20.0621 48.9404 20.1556 48.9546 20.2548 48.9659C20.3539 48.9772 20.4616 48.9829 20.5806 48.9829V48.2265C20.4346 48.2063 20.2899 48.178 20.1471 48.1415C20.0106 48.1094 19.8804 48.0549 19.7618 47.98C19.6457 47.9101 19.5484 47.8128 19.4785 47.6967C19.4016 47.5573 19.3643 47.3996 19.3708 47.2405C19.369 47.1012 19.397 46.963 19.453 46.8354C19.5098 46.7161 19.592 46.6108 19.6938 46.5266C19.8065 46.4356 19.9339 46.3647 20.0706 46.3169C20.2345 46.2628 20.4035 46.2258 20.5749 46.2064V45.8523H21.1245V46.1979C21.2964 46.2073 21.4676 46.2263 21.6373 46.2546C21.8002 46.2799 21.9604 46.3197 22.1161 46.3736V47.0564C21.7955 46.9491 21.4621 46.8843 21.1245 46.8637V47.6315C21.2698 47.6542 21.4136 47.6855 21.5552 47.725C21.69 47.7582 21.8183 47.8137 21.9348 47.8893C22.0505 47.9598 22.1476 48.057 22.2181 48.1726C22.2937 48.3114 22.33 48.4681 22.323 48.6259C22.3289 48.7577 22.304 48.889 22.2504 49.0094C22.1968 49.1299 22.1159 49.2362 22.0142 49.3201C21.7562 49.5062 21.4506 49.6152 21.133 49.6345V50H20.5834L20.5806 49.6544ZM20.133 47.2065C20.1314 47.25 20.1411 47.2931 20.1609 47.3318C20.1807 47.3704 20.2101 47.4034 20.2463 47.4275C20.3486 47.4893 20.4623 47.5298 20.5806 47.5465V46.8836C20.456 46.8874 20.3351 46.9267 20.2321 46.9969C20.2017 47.0229 20.1772 47.055 20.1601 47.0911C20.143 47.1272 20.1338 47.1666 20.133 47.2065ZM21.5495 48.6429C21.5522 48.6002 21.544 48.5575 21.5257 48.5189C21.5074 48.4802 21.4795 48.4469 21.4447 48.422C21.3458 48.3627 21.236 48.3241 21.1217 48.3086V48.9602C21.4164 48.9234 21.558 48.8158 21.558 48.6429H21.5495Z" />
      <path d="M24.8246 49.6544C24.611 49.6498 24.398 49.6299 24.1872 49.5949C24.0163 49.5701 23.8495 49.5224 23.6914 49.4532V48.7761C23.782 48.8101 23.8699 48.8384 23.9549 48.8611C24.0399 48.8866 24.1277 48.9064 24.2155 48.9234C24.3033 48.9404 24.4025 48.9546 24.4988 48.9659C24.5951 48.9772 24.7056 48.9829 24.8218 48.9829V48.2265C24.6767 48.2066 24.5329 48.1782 24.3911 48.1415C24.2543 48.1105 24.1239 48.0559 24.0059 47.98C23.8902 47.9095 23.7931 47.8123 23.7225 47.6967C23.6466 47.5571 23.6103 47.3993 23.6177 47.2405C23.6144 47.1011 23.6425 46.9626 23.6999 46.8354C23.7542 46.7154 23.8357 46.6097 23.9379 46.5266C24.0516 46.4356 24.18 46.3647 24.3175 46.3169C24.4803 46.2625 24.6484 46.2254 24.819 46.2064V45.8523H25.3686V46.1979C25.5414 46.2073 25.7135 46.2262 25.8842 46.2546C26.0469 46.2805 26.2071 46.3203 26.363 46.3736V47.0564C26.0414 46.949 25.707 46.8843 25.3686 46.8637V47.6315C25.5139 47.6539 25.6577 47.6852 25.7992 47.725C25.9347 47.7587 26.0639 47.8142 26.1817 47.8893C26.2967 47.9607 26.3937 48.0576 26.465 48.1726C26.5406 48.3114 26.5768 48.4681 26.5698 48.6259C26.5757 48.7577 26.5509 48.889 26.4973 49.0094C26.4437 49.1299 26.3628 49.2362 26.261 49.3201C26.0032 49.5066 25.6976 49.6157 25.3799 49.6345V50H24.8303L24.8246 49.6544ZM24.3798 47.2065C24.3777 47.2498 24.3868 47.2928 24.4062 47.3315C24.4255 47.3702 24.4545 47.4033 24.4903 47.4275C24.5926 47.4893 24.7063 47.5298 24.8246 47.5465V46.8836C24.7002 46.888 24.5794 46.9273 24.4762 46.9969C24.4463 47.0231 24.4223 47.0553 24.4057 47.0915C24.3891 47.1276 24.3803 47.1668 24.3798 47.2065ZM25.7964 48.6429C25.7986 48.6003 25.7901 48.5578 25.7718 48.5192C25.7535 48.4807 25.726 48.4472 25.6916 48.422C25.5919 48.3623 25.481 48.3237 25.3657 48.3086V48.9602C25.6604 48.9234 25.8049 48.8158 25.8049 48.6429H25.7964Z" />
      <path d="M29.0715 49.6544C28.8578 49.6499 28.6448 49.63 28.434 49.5949C28.2622 49.5701 28.0945 49.5224 27.9354 49.4532V48.7761C28.026 48.8101 28.1139 48.8384 28.1989 48.8611C28.2855 48.8865 28.3735 48.9073 28.4623 48.9234C28.553 48.9404 28.6465 48.9546 28.7457 48.9659C28.8448 48.9772 28.9525 48.9829 29.0715 48.9829V48.2265C28.9254 48.2066 28.7807 48.1782 28.638 48.1415C28.5013 48.11 28.371 48.0553 28.2527 47.98C28.1365 47.9101 28.0393 47.8128 27.9694 47.6967C27.8924 47.5573 27.8552 47.3996 27.8617 47.2405C27.8599 47.1012 27.8879 46.963 27.9439 46.8354C28.0007 46.7161 28.0829 46.6108 28.1847 46.5266C28.2974 46.4356 28.4248 46.3647 28.5615 46.3169C28.7253 46.2628 28.8943 46.2258 29.0658 46.2064V45.8523H29.6154V46.1979C29.7873 46.2073 29.9585 46.2263 30.1282 46.2546C30.2911 46.2799 30.4513 46.3197 30.607 46.3736V47.0564C30.2863 46.9491 29.953 46.8843 29.6154 46.8637V47.6315C29.7607 47.6542 29.9045 47.6855 30.0461 47.725C30.1809 47.7582 30.3092 47.8137 30.4257 47.8893C30.5414 47.9598 30.6385 48.057 30.709 48.1726C30.7833 48.3119 30.8195 48.4682 30.8138 48.6259C30.8197 48.7577 30.7949 48.889 30.7413 49.0094C30.6877 49.1299 30.6068 49.2362 30.505 49.3201C30.247 49.5062 29.9415 49.6152 29.6239 49.6345V50H29.0743L29.0715 49.6544ZM28.6238 47.2065C28.6217 47.2498 28.6308 47.2928 28.6502 47.3315C28.6695 47.3702 28.6985 47.4033 28.7343 47.4275C28.8376 47.4893 28.9523 47.5298 29.0715 47.5465V46.8836C28.946 46.8875 28.8242 46.9268 28.7202 46.9969C28.6903 47.0231 28.6663 47.0553 28.6497 47.0915C28.6331 47.1276 28.6243 47.1668 28.6238 47.2065ZM30.0404 48.6429C30.0431 48.6002 30.0349 48.5575 30.0166 48.5189C29.9982 48.4802 29.9703 48.4469 29.9356 48.422C29.8367 48.3627 29.7268 48.3241 29.6126 48.3086V48.9602C29.9072 48.9234 30.0489 48.8158 30.0489 48.6429H30.0404Z" />
      <path d="M33.3155 49.6544C33.1018 49.6499 32.8888 49.63 32.678 49.5949C32.5071 49.5705 32.3403 49.5228 32.1822 49.4532V48.7761C32.2675 48.8091 32.3545 48.8375 32.4429 48.8611C32.5307 48.8866 32.6185 48.9064 32.7064 48.9234C32.7942 48.9404 32.8905 48.9546 32.9897 48.9659C33.0888 48.9772 33.1993 48.9829 33.3155 48.9829V48.2265C33.1695 48.2063 33.0248 48.178 32.882 48.1415C32.7455 48.1094 32.6153 48.0549 32.4967 47.98C32.3806 47.9101 32.2833 47.8128 32.2134 47.6967C32.1365 47.5573 32.0992 47.3996 32.1057 47.2405C32.1034 47.1008 32.1325 46.9624 32.1907 46.8354C32.2451 46.7154 32.3265 46.6097 32.4287 46.5266C32.5414 46.4356 32.6688 46.3647 32.8055 46.3169C32.9694 46.2628 33.1384 46.2258 33.3098 46.2064V45.8523H33.8594V46.1979C34.0313 46.2072 34.2025 46.2261 34.3722 46.2546C34.5359 46.2802 34.6971 46.32 34.8539 46.3736V47.0564C34.5322 46.9493 34.1979 46.8845 33.8594 46.8637V47.6315C34.0047 47.6539 34.1485 47.6852 34.2901 47.725C34.4249 47.7582 34.5532 47.8137 34.6697 47.8893C34.7854 47.9598 34.8825 48.057 34.953 48.1726C35.0286 48.3114 35.0649 48.4681 35.0579 48.6259C35.0637 48.7577 35.0389 48.889 34.9853 49.0094C34.9317 49.1299 34.8508 49.2362 34.749 49.3201C34.4913 49.5066 34.1856 49.6157 33.8679 49.6345V50H33.3155V49.6544ZM32.8707 47.2065C32.8686 47.2498 32.8777 47.2928 32.897 47.3315C32.9164 47.3702 32.9453 47.4033 32.9812 47.4275C33.0835 47.4893 33.1972 47.5298 33.3155 47.5465V46.8836C33.191 46.888 33.0703 46.9273 32.967 46.9969C32.9371 47.0231 32.9131 47.0553 32.8965 47.0915C32.8799 47.1276 32.8711 47.1668 32.8707 47.2065ZM34.2872 48.6429C34.2896 48.6 34.281 48.5571 34.2622 48.5184C34.2433 48.4798 34.2149 48.4465 34.1796 48.422C34.081 48.3621 33.971 48.3235 33.8566 48.3086V48.9602C34.1513 48.9234 34.2957 48.8158 34.2957 48.6429H34.2872Z" />
      <path d="M30.5993 32.2504C30.8428 32.1814 31.1148 32.1164 31.4111 32.0596C31.7074 32.0028 32.0402 31.9541 32.373 31.9135C32.7059 31.8729 33.0387 31.8485 33.3675 31.8282C33.6962 31.808 34.0291 31.7998 34.3456 31.7998H34.6216V34.8805C34.1102 34.8318 33.4933 34.7953 32.7708 34.7669C32.0483 34.7385 31.2771 34.7222 30.4694 34.7222C29.8606 34.7222 29.3167 34.7222 28.8459 34.7669C28.375 34.8115 27.8798 34.8237 27.3928 34.8602L25.8748 34.9536C25.3471 34.982 24.8316 34.9982 24.3324 34.9982C22.9625 35.0235 21.6011 34.7779 20.3263 34.2758C19.212 33.8275 18.2131 33.1338 17.4039 32.2463C16.592 31.3351 15.9808 30.2634 15.6098 29.1007C15.1894 27.7894 14.9838 26.4187 15.001 25.0418C14.986 23.6634 15.1986 22.2921 15.6301 20.9829C16.0163 19.8076 16.6424 18.7254 17.4688 17.8048C18.2931 16.9063 19.3046 16.1997 20.4318 15.7348C23.0085 14.7551 25.8551 14.7551 28.4319 15.7348C29.5593 16.1957 30.5721 16.8982 31.3989 17.7926C32.2227 18.6966 32.8489 19.7624 33.2376 20.922C33.6672 22.2023 33.8798 23.5453 33.8667 24.8957C33.868 25.6345 33.7946 26.3716 33.6475 27.0956C33.5128 27.7813 33.3018 28.4497 33.0184 29.0885C32.7481 29.7056 32.4018 30.2864 31.9874 30.8176C31.5815 31.3436 31.1032 31.8095 30.5668 32.2017L30.5993 32.2504ZM30.1934 25.0418C30.2115 23.9958 30.065 22.9534 29.7591 21.953C29.5056 21.1439 29.0914 20.3943 28.5414 19.749C28.0463 19.172 27.4218 18.72 26.719 18.4299C25.231 17.8509 23.5799 17.8509 22.0919 18.4299C21.3878 18.7199 20.762 19.1718 20.2654 19.749C19.7153 20.3942 19.3011 21.1439 19.0477 21.953C18.4687 23.9715 18.4687 26.1121 19.0477 28.1306C19.2984 28.9397 19.7129 29.6885 20.2654 30.3305C20.7643 30.9053 21.3894 31.3568 22.0919 31.6497C23.5789 32.2342 25.232 32.2342 26.719 31.6497C27.4202 31.3566 28.044 30.9051 28.5414 30.3305C29.0939 29.6884 29.5083 28.9396 29.7591 28.1306C30.0708 27.1311 30.2229 26.0887 30.2096 25.0418H30.1934Z" />
    </SvgIcon>
  );
};

export default Logo;
