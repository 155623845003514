import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OnboardingDocument } from '@/api/onboarding/types';
import ROUTES from '@/constants/routes';
import useOnboardingStore from '@/modules/Onboarding/store';
import { DocumentsFormData, DocumentsFormProps } from './components/DocumentsForm';

export const mapDocumentToFile = (
  doc: Pick<OnboardingDocument, 'originalFilename' | 'mimeType' | 'size'>,
) => {
  return {
    name: doc.originalFilename,
    type: doc.mimeType,
    size: doc.size,
  } as File;
};

const useDocumentsPage = () => {
  const [{
    folio, firstname, status, documents,
  }, actions] = useOnboardingStore();
  const navigate = useNavigate();
  const [uploadStatus, setDocumentsUploadStatus] = useState<DocumentsFormProps['uploadStatus']>({});
  const [uploaded, setUploaded] = useState(false);
  const initialRender = useRef(true);

  const form = useForm<DocumentsFormData>({
    defaultValues: {},
    mode: 'onTouched',
  });

  const uploadFiles = async (data: DocumentsFormData) => {
    await actions.uploadDocuments(data, (type, loadStatus, doc) => {
      setDocumentsUploadStatus((prev) => ({ ...prev, [type]: loadStatus }));

      if (loadStatus === 'completed') {
        form.setValue(type, mapDocumentToFile(doc));
      }
    });
  };

  const handleFormSubmit = async (data: DocumentsFormData) => {
    if (uploaded) {
      navigate({ pathname: ROUTES.root });
    } else {
      await uploadFiles(data);

      setTimeout(() => {
        setUploaded(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      setUploaded(!!documents);
    }

    initialRender.current = false;
  }, [documents]);

  return {
    form,
    status,
    uploadStatus,
    uploaded,
    firstname,
    folio,
    handleFormSubmit,
  };
};

export default useDocumentsPage;
