import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import mainCoverDivider from '@/assets/images/main-cover-bottom-divider.svg';
import tncConstants from '@/constants/tnc';
import DotsIcon from '@/icons/Dots';
import LogoIcon from '@/icons/Logo';
import QuoterCard from '../QuoterCard';
import useStyles from './styles';

const Banner: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'landing' });

  return (
    <Box className={classes.root}>
      <Container className={classes.content} fixed maxWidth="xl" disableGutters>
        <Grid container rowSpacing={5} alignItems="flex-start" justifyContent="space-around">
          <Grid
            className={classes.responsiveAlign}
            container
            item
            spacing={5}
            xs={10}
            lg={6}
            xl={4}
            direction="column"
            alignItems={{
              xs: 'center',
              lg: 'flex-start',
            }}
            justifyContent="flex-start"
          >
            <Grid item>
              <LogoIcon className={classes.logo} color="primary" />
            </Grid>
            <Grid item>
              <Typography className={classes.title} variant="h1" color="primary.main">
                <DotsIcon color="primary" className={classes.dots} />
                <Trans
                  t={t}
                  i18nKey="mainTitle"
                  components={{
                    1: <sup />,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.subtitle}
                variant="h3"
                component="h2"
                color="primary.main"
              >
                {t('mainSubtitle')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.quoterContainer}
            container
            item
            md={8}
            lg={5}
            xl={4}
            spacing={2}
            direction="column"
            alignItems="stretch"
          >
            <Grid item>
              <QuoterCard />
            </Grid>
            <Grid item>
              <Box className={classes.loanCaption}>
                <Typography variant="body2" component="p" color="text.secondary">
                  <Trans
                    t={t}
                    i18nKey="exclusiveLoan"
                    components={{
                      1: <sup />,
                    }}
                  />
                </Typography>
                <Typography variant="body2" component="p" color="text.secondary">
                  <Trans
                    t={t}
                    i18nKey="tncConsult"
                    components={{
                      1: (
                        <Link
                          className={classes.termsLink}
                          href={tncConstants.fileUrl}
                          target="_blank"
                          underline="always"
                        />
                      ),
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <img className={classes.coverImgDivider} alt="Main cover divider" src={mainCoverDivider} />
    </Box>
  );
};

export default Banner;
