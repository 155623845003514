import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';
import useStyles from './styles';

export interface SummaryItemProps {
  label: string;
  icon: ReactNode;
}

const SummaryItem: FC<SummaryItemProps> = ({ label, icon }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.iconContainer}>
        {icon}
      </Box>
      <Typography className={classes.label} variant="body1" component="h5">
        {label}
      </Typography>
    </Box>
  );
};

export default SummaryItem;
