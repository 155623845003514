import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slider, { SliderProps as MuiSliderProps } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { FC, MouseEvent } from 'react';

import MinusIcon from '@/icons/Minus';
import PlusIcon from '@/icons/Plus';
import useStyles from './styles';

export interface SliderControlProps extends Required<Pick<MuiSliderProps, 'min' | 'max' | 'step'>> {
  value: number;
  textValue?: string;
  size?: MuiSliderProps['size'];
  buttonStep?: MuiSliderProps['step'];
  onChange: (event: Event, value: number) => void;
}

const SliderControl: FC<SliderControlProps> = ({
  value,
  min,
  max,
  step,
  textValue = `${value}`,
  size = 'medium',
  buttonStep,
  onChange,
}) => {
  const { classes } = useStyles({ size });

  const s = step ?? 1;
  const bStep = buttonStep ?? s;

  const handleDecreaseClick = (e: MouseEvent<HTMLButtonElement>) => {
    onChange(e.nativeEvent, Math.max(value - bStep, min));
  };

  const handleIncreaseClick = (e: MouseEvent<HTMLButtonElement>) => {
    onChange(e.nativeEvent, Math.min(value + bStep, max));
  };

  const handleSliderChange = (e: Event, v: number) => {
    onChange(e, v);
  };

  return (
    <Grid container direction="column" rowSpacing={size === 'small' ? 1 : 2}>
      <Grid
        item
        container
        spacing={{
          xs: 1,
          sm: 2,
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <IconButton className={classes.button} onClick={handleDecreaseClick}>
            <MinusIcon color="inherit" fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid className={classes.valueContainer} item xs>
          <Typography component="div" noWrap>
            {textValue}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton className={classes.button} onClick={handleIncreaseClick}>
            <PlusIcon color="inherit" fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <Slider
          color="secondary"
          size={size}
          value={value}
          min={min}
          max={max}
          step={s}
          onChange={handleSliderChange as MuiSliderProps['onChange']}
        />
      </Grid>
    </Grid>
  );
};

export default SliderControl;
