import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  logo: {
    fontSize: 100,
    margin: theme.spacing(0, 2),
  },
}));

export default useStyles;
