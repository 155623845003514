import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  caption: {
    display: 'block',
    fontSize: 12,
  },
}));

export default useStyles;
