import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Thumb: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M42.7473 25.8979L40.5092 40.3898C39.9008 44.3188 36.5756 47.17 32.5994 47.17H16.2256C12.0938 47.17 8.66715 44.0688 8.25681 39.9889L7.20264 26.247C6.9951 23.5137 8.76856 21.0304 11.4217 20.3417C14.3083 19.5941 16.7043 17.4929 17.8245 14.7242L21.6827 5.16826C22.0577 4.24616 22.7676 3.52451 23.6897 3.13303C24.1613 2.93257 24.6589 2.83352 25.1566 2.83352C25.6306 2.83352 26.107 2.92314 26.5621 3.10473C27.9582 3.66365 28.8992 5.05978 28.8992 6.57382C28.8992 7.06671 28.8096 7.52187 28.6257 7.97703L24.8099 17.4245C24.6307 17.8608 24.6849 18.356 24.9467 18.7451C25.2108 19.1366 25.6494 19.3701 26.1188 19.3701H37.1463C38.8042 19.3701 40.3701 20.0917 41.4479 21.3464C42.5256 22.6034 42.9973 24.2636 42.7473 25.8979ZM43.5939 19.5045C41.9809 17.6202 39.6296 16.5401 37.1463 16.5401H28.22L31.2481 9.04062C31.5736 8.24351 31.7292 7.43696 31.7292 6.57382C31.7292 3.91127 30.076 1.46333 27.6139 0.479907C25.9914 -0.175707 24.2038 -0.159198 22.586 0.527074C20.9658 1.21335 19.7112 2.48684 19.0603 4.11173L15.1974 13.6653C14.4144 15.6062 12.7353 17.0778 10.7118 17.6037C6.72862 18.6343 4.06607 22.3628 4.38209 26.4639L5.43862 40.2389C5.99518 45.8022 10.634 50 16.2256 50H32.5994C37.9811 50 42.4832 46.1394 43.3039 40.8238L45.5443 26.3295C45.9216 23.8768 45.2118 21.3888 43.5939 19.5045Z" />
    </SvgIcon>
  );
};

export default Thumb;
