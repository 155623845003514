import Divider from '@mui/material/Divider';
import { FC } from 'react';
import useStyles from './styles';

export interface DashedDividerProps {
  className?: string;
}

const DashedDivider: FC<DashedDividerProps> = ({ className }) => {
  const { classes, cx } = useStyles();

  return <Divider className={cx(classes.root, className)} />;
};

export default DashedDivider;
