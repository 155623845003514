import { SliderProps as MuiSliderProps } from '@mui/material/Slider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ size: MuiSliderProps['size'] }>()((theme, { size = 'medium' }) => ({
  button: {
    border: `2px solid ${theme.palette.secondary.main}`,
    fontSize: size === 'small' ? 40 : 48,
    padding: 0,
    fontWeight: 'bolder',
  },
  valueContainer: {
    overflow: 'hidden',
    '& > div': {
      fontWeight: 500,
      fontSize: size === 'small' ? 32 : 38,
      [theme.breakpoints.up('sm')]: {
        fontSize: size === 'small' ? 36 : 42,
      },
    },
  },
}));

export default useStyles;
