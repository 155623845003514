import FormTextField from '@/components/FormTextField';
import regexPatterns from '@/constants/regexPatterns';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export interface EmailFormData {
  email: string;
  tnc: boolean;
}

const EmailForm: FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'email' });
  const { classes } = useStyles();
  const { control } = useFormContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormTextField
          name="email"
          control={control}
          rules={{
            required: t('fields.email.required') as string,
            pattern: {
              value: regexPatterns.email,
              message: t('fields.email.pattern'),
            },
          }}
          fullWidth
          label={t('fields.email.label')}
          variant="filled"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="tnc"
          control={control}
          rules={{
            required: t('fields.tnc.required') as string,
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} onBlur={onBlur} />}
                label={(
                  <Typography
                    className={classes.termsCaption}
                    variant="caption"
                    color="text.secondary"
                  >
                    {t('fields.tnc.label')}
                  </Typography>
                )}
              />
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EmailForm;
