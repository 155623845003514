import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Chat: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="51" viewBox="0 0 50 51">
      <path d="M38.5172 20.8093C38.5172 22.4603 37.1776 23.7999 35.5266 23.7999C33.8732 23.7999 32.5336 22.4603 32.5336 20.8093C32.5336 19.156 33.8732 17.8163 35.5266 17.8163C37.1776 17.8163 38.5172 19.156 38.5172 20.8093ZM27.9934 20.8093C27.9934 22.4603 26.6538 23.7999 25.0005 23.7999C23.3495 23.7999 22.0098 22.4603 22.0098 20.8093C22.0098 19.156 23.3495 17.8163 25.0005 17.8163C26.6538 17.8163 27.9934 19.156 27.9934 20.8093ZM17.4673 20.8093C17.4673 22.4603 16.1277 23.7999 14.4767 23.7999C12.8233 23.7999 11.4837 22.4603 11.4837 20.8093C11.4837 19.156 12.8233 17.8163 14.4767 17.8163C16.1277 17.8163 17.4673 19.156 17.4673 20.8093ZM42.4017 32.5949C41.397 33.5973 40.2719 34.4558 39.0549 35.1468C37.7436 35.8921 36.9606 37.1917 36.9606 38.6257V44.2861L29.1963 38.4959C28.451 37.7483 27.4604 37.3379 26.4061 37.3379H19.097C10.1275 37.3379 2.83024 30.0524 2.83024 21.097C2.83024 16.7408 4.51423 12.6582 7.57089 9.60391C10.6747 6.52603 14.7691 4.83024 19.097 4.83024H30.168C34.7836 4.83024 39.3026 6.75009 42.5644 10.0992C45.6423 13.2573 47.2744 17.321 47.1659 21.5381C47.0574 25.7292 45.3664 29.6538 42.4017 32.5949ZM44.5928 8.12511C40.8026 4.23117 35.5455 2 30.168 2H19.097C14.0168 2 9.21715 3.98824 5.57557 7.59916C1.97881 11.1912 0 15.9861 0 21.097C0 31.6137 8.56619 40.1681 19.097 40.1681H26.4061C26.7033 40.1681 26.984 40.2837 27.3472 40.6281L36.4653 47.4301C36.8403 47.8051 37.3356 48.0032 37.8403 48.0032C38.0927 48.0032 38.345 47.9537 38.588 47.8546C39.3167 47.5527 39.7908 46.8475 39.7908 46.055V38.6257C39.7908 38.2247 40.0385 37.8426 40.4559 37.6068C41.8899 36.7907 43.2201 35.7789 44.3994 34.5996C47.8782 31.1467 49.8665 26.5335 49.9938 21.6112C50.1235 16.6253 48.2061 11.8327 44.5928 8.12511Z" />
    </SvgIcon>
  );
};

export default Chat;
