import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import StepsList from '@/components/StepsList';
import TitleWithIcon from '@/components/TitleWithIcon';
import ErrorIcon from '@/icons/Error';
import SuccessIcon from '@/icons/Success';
import currencyFormat from '@/utils/currencyFormat';

export interface MadeDecisionProps {
  status: 'APPROVED' | 'REVISION' | 'REJECTED';
  name: string;
  amount: string;
  email: string;
}

const MadeDecision: FC<MadeDecisionProps> = ({
  status, name, amount, email,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'preauthorization.madeDecision' });
  const { t: td } = useTranslation('', { keyPrefix: 'preauthorization.documents' });

  const documentsList = [
    {
      label: td('officialId'),
    },
    {
      label: td(status === 'REVISION' ? 'accountStatementOrBureau' : 'accountStatement'),
    },
    {
      label: td('proofOfAddress'),
    },
    {
      label: td('selfie'),
    },
  ];

  let label = '';
  let icon = null as JSX.Element | null;
  let stepsLabel = '';
  let steps: { label: string }[] = [];
  let showAlert = false;

  if (status === 'APPROVED') {
    label = t('approved.title', {
      name,
      amount: currencyFormat(amount),
    });
    icon = <SuccessIcon color="primary" fontSize="inherit" />;
    stepsLabel = t('approved.stepsLabel');
    steps = documentsList;
    showAlert = true;
  } else if (status === 'REVISION') {
    label = t('revision.title', {
      name,
    });
    stepsLabel = t('revision.stepsLabel');
    steps = documentsList;
    showAlert = true;
  } else if (status === 'REJECTED') {
    label = t('rejected.title', { name });
    icon = <ErrorIcon color="primary" fontSize="inherit" />;
    stepsLabel = t('rejected.stepsLabel');
    steps = [
      {
        label: t('rejected.text'),
      },
    ];
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <TitleWithIcon label={label} icon={icon} />
      </Grid>
      {(stepsLabel || steps.length > 0) && (
        <Grid item xs={12}>
          <StepsList label={stepsLabel} items={steps} />
        </Grid>
      )}
      {showAlert && (
        <Grid item xs={12}>
          <Alert severity="info">
            <Trans
              t={t}
              i18nKey="infoText"
              components={{
                1: <strong />,
              }}
              values={{ email }}
            />
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default MadeDecision;
