import Grid from '@mui/material/Grid';
import { FC, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import ResponsiveTypography from '@/components/ResponsiveTypography';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import DocumentsForm from '../components/DocumentsForm';
import useDocumentsPage from '../useDocumentsPage';

const DocumentsPage: FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'documents' });
  const { t: tOnboarding } = useTranslation('', { keyPrefix: 'onboarding' });
  const {
    form, status, uploadStatus, uploaded, firstname, folio, handleFormSubmit,
  } = useDocumentsPage();

  const { isSubmitting, isValid } = form.formState;

  const disabled = !uploaded && (isSubmitting || !isValid);

  const label = tOnboarding(uploaded ? 'finish' : 'send');

  const content = useMemo(() => {
    if (uploaded) {
      return (
        <Grid item xs={12}>
          <ResponsiveTypography>
            <Trans
              t={t}
              i18nKey="uploadedText"
              components={{
                1: <strong />,
              }}
              values={{ folio }}
            />
          </ResponsiveTypography>
        </Grid>
      );
    }

    if (status) {
      return (
        <>
          <Grid item xs={12}>
            <ResponsiveTypography variant="body1" color="text.secondary">
              {t('formCaption')}
            </ResponsiveTypography>
          </Grid>
          <Grid item xs={12}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormProvider {...form}>
              <DocumentsForm status={status} uploadStatus={uploadStatus} />
            </FormProvider>
          </Grid>
        </>
      );
    }

    return null;
  }, [folio, form, uploaded, status, t, uploadStatus]);

  return (
    <OnboardingStepsLayout
      steps={{
        personal: 'completed',
        location: 'completed',
        incomes: 'completed',
      }}
      continueButton={{
        type: 'submit',
        label,
        disabled,
        hideIcon: uploaded,
      }}
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ResponsiveTypography variant="h4" breakpoint="md">
            {t(uploaded ? 'uploadedTitle' : 'title', {
              name: firstname,
            })}
          </ResponsiveTypography>
        </Grid>

        {content}
      </Grid>
    </OnboardingStepsLayout>
  );
};

export default DocumentsPage;
