import { makeStyles } from 'tss-react/mui';
import mainCover from '@/assets/images/main-cover.webp';
import mainCoverSmall from '@/assets/images/main-cover-small.png';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url(${mainCoverSmall})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: 'center',
      backgroundImage: `url(${mainCover})`,
    },
  },
  content: {
    position: 'relative',
    paddingTop: theme.spacing(8),
    marginBottom: -190,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
      marginBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(-4),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(-10),
    },
  },
  logo: {
    width: '100%',
    height: 75,
    fontSize: '100%',
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('sm')]: {
      height: 100,
      marginBottom: theme.spacing(12),
    },
    [theme.breakpoints.up('lg')]: {
      height: 150,
      marginBottom: 0,
    },
  },
  responsiveAlign: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  title: {
    position: 'relative',
  },
  subtitle: {
    fontWeight: 400,
  },
  dots: {
    position: 'absolute',
    top: '50%',
    left: -180,
    fontSize: 220,
    marginTop: -110,
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  quoterContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
      marginTop: theme.spacing(8),
    },
  },
  loanCaption: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'center',
  },
  termsLink: {
    color: theme.palette.info.main,
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.primary.main,
    },
  },
  coverImgDivider: {
    display: 'block',
    width: '100%',
  },
}));

export default useStyles;
