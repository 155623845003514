import {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { OnboardingStatus } from '@/api/onboarding/types';
import CenterLoader from '@/components/CenterLoader';
import ProtectedRoute from '@/components/ProtectedRoute';
import ROUTES from '@/constants/routes';
import useApiInterceptor from '@/hooks/useApiInterceptor';
import ExpiredRequest from './components/ExpiredRequest';
import useOnboardingStore from './store';

const sessionAttribute = 'accessToken';
const invalidTokenErrors = ['TOKEN_NOT_PROVIDED', 'TOKEN_INVALID'];

export const OnboardingRequireAuth: FC = () => {
  const navigate = useNavigate();
  const [, actions] = useOnboardingStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState<'loading' | 'completed' | 'expired'>('loading');
  const loadedRef = useRef(false);

  const { loaded: loadedInterceptor } = useApiInterceptor(undefined, (err) => {
    if (err.response?.status === 401) {
      if (invalidTokenErrors.includes(err.response.data?.name)) {
        navigate({
          pathname: ROUTES.root,
        });
      }

      setStatus('expired');
    }

    if (err.response?.data?.name === 'ONBOARDING.REGISTER_NOT_FOUND') {
      navigate({
        pathname: ROUTES.root,
      });
    }

    return Promise.reject(err);
  });

  const loadRegister = useCallback(
    async (token?: string) => {
      await actions.getRegister(token);
      setStatus('completed');
    },
    [actions],
  );

  useEffect(() => {
    if (loadedRef.current || !loadedInterceptor) {
      return;
    }

    loadRegister(searchParams.get(sessionAttribute) as string);
    setSearchParams({});
    loadedRef.current = true;
  }, [loadRegister, loadedInterceptor, searchParams, setSearchParams]);

  if (status === 'expired') {
    return <ExpiredRequest />;
  }

  if (status === 'loading') {
    return <CenterLoader />;
  }

  return <ProtectedRoute isAllowed={status === 'completed'} redirectPath={ROUTES.root} />;
};

export interface OnboardingRequireStatusesProps {
  statuses: OnboardingStatus[];
  redirectPath?: string;
}

export const OnboardingRequireStatus: FC<OnboardingRequireStatusesProps> = ({
  statuses,
  ...protectedRouteProps
}) => {
  const [{ status }] = useOnboardingStore();

  const isAllowed = statuses.includes(status as OnboardingStatus);

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      redirectPath={ROUTES.email}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...protectedRouteProps}
    />
  );
};
