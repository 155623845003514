import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Dots: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="60" height="204" viewBox="0 0 60 204">
      <path d="M3 198C4.65685 198 6 199.343 6 201C6 202.657 4.65685 204 3 204C1.34315 204 0 202.657 0 201C0 199.343 1.34315 198 3 198Z" />
      <path d="M3 180C4.65685 180 6 181.343 6 183C6 184.657 4.65685 186 3 186C1.34315 186 0 184.657 0 183C0 181.343 1.34315 180 3 180Z" />
      <path d="M3 162C4.65685 162 6 163.343 6 165C6 166.657 4.65685 168 3 168C1.34315 168 0 166.657 0 165C0 163.343 1.34315 162 3 162Z" />
      <path d="M3 144C4.65685 144 6 145.343 6 147C6 148.657 4.65685 150 3 150C1.34315 150 0 148.657 0 147C0 145.343 1.34315 144 3 144Z" />
      <path d="M3 126C4.65685 126 6 127.343 6 129C6 130.657 4.65685 132 3 132C1.34315 132 0 130.657 0 129C0 127.343 1.34315 126 3 126Z" />
      <path d="M3 108C4.65685 108 6 109.343 6 111C6 112.657 4.65685 114 3 114C1.34315 114 0 112.657 0 111C0 109.343 1.34315 108 3 108Z" />
      <path d="M3 90C4.65685 90 6 91.3431 6 93C6 94.6569 4.65685 96 3 96C1.34315 96 0 94.6569 0 93C0 91.3431 1.34315 90 3 90Z" />
      <path d="M3 72C4.65685 72 6 73.3431 6 75C6 76.6569 4.65685 78 3 78C1.34315 78 0 76.6569 0 75C0 73.3431 1.34315 72 3 72Z" />
      <path d="M3 54C4.65685 54 6 55.3431 6 57C6 58.6569 4.65685 60 3 60C1.34315 60 0 58.6569 0 57C0 55.3431 1.34315 54 3 54Z" />
      <path d="M3 36C4.65685 36 6 37.3431 6 39C6 40.6569 4.65685 42 3 42C1.34315 42 0 40.6569 0 39C0 37.3431 1.34315 36 3 36Z" />
      <path d="M3 18C4.65685 18 6 19.3431 6 21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21C0 19.3431 1.34315 18 3 18Z" />
      <path d="M3 0C4.65685 0 6 1.34315 6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0Z" />
      <path d="M21 198C22.6569 198 24 199.343 24 201C24 202.657 22.6569 204 21 204C19.3431 204 18 202.657 18 201C18 199.343 19.3431 198 21 198Z" />
      <path d="M21 180C22.6569 180 24 181.343 24 183C24 184.657 22.6569 186 21 186C19.3431 186 18 184.657 18 183C18 181.343 19.3431 180 21 180Z" />
      <path d="M21 162C22.6569 162 24 163.343 24 165C24 166.657 22.6569 168 21 168C19.3431 168 18 166.657 18 165C18 163.343 19.3431 162 21 162Z" />
      <path d="M21 144C22.6569 144 24 145.343 24 147C24 148.657 22.6569 150 21 150C19.3431 150 18 148.657 18 147C18 145.343 19.3431 144 21 144Z" />
      <path d="M21 126C22.6569 126 24 127.343 24 129C24 130.657 22.6569 132 21 132C19.3431 132 18 130.657 18 129C18 127.343 19.3431 126 21 126Z" />
      <path d="M21 108C22.6569 108 24 109.343 24 111C24 112.657 22.6569 114 21 114C19.3431 114 18 112.657 18 111C18 109.343 19.3431 108 21 108Z" />
      <path d="M21 90C22.6569 90 24 91.3431 24 93C24 94.6569 22.6569 96 21 96C19.3431 96 18 94.6569 18 93C18 91.3431 19.3431 90 21 90Z" />
      <path d="M21 72C22.6569 72 24 73.3431 24 75C24 76.6569 22.6569 78 21 78C19.3431 78 18 76.6569 18 75C18 73.3431 19.3431 72 21 72Z" />
      <path d="M21 54C22.6569 54 24 55.3431 24 57C24 58.6569 22.6569 60 21 60C19.3431 60 18 58.6569 18 57C18 55.3431 19.3431 54 21 54Z" />
      <path d="M21 36C22.6569 36 24 37.3431 24 39C24 40.6569 22.6569 42 21 42C19.3431 42 18 40.6569 18 39C18 37.3431 19.3431 36 21 36Z" />
      <path d="M21 18C22.6569 18 24 19.3431 24 21C24 22.6569 22.6569 24 21 24C19.3431 24 18 22.6569 18 21C18 19.3431 19.3431 18 21 18Z" />
      <path d="M21 0C22.6569 0 24 1.34315 24 3C24 4.65685 22.6569 6 21 6C19.3431 6 18 4.65685 18 3C18 1.34315 19.3431 0 21 0Z" />
      <path d="M39 198C40.6569 198 42 199.343 42 201C42 202.657 40.6569 204 39 204C37.3431 204 36 202.657 36 201C36 199.343 37.3431 198 39 198Z" />
      <path d="M39 180C40.6569 180 42 181.343 42 183C42 184.657 40.6569 186 39 186C37.3431 186 36 184.657 36 183C36 181.343 37.3431 180 39 180Z" />
      <path d="M39 162C40.6569 162 42 163.343 42 165C42 166.657 40.6569 168 39 168C37.3431 168 36 166.657 36 165C36 163.343 37.3431 162 39 162Z" />
      <path d="M39 144C40.6569 144 42 145.343 42 147C42 148.657 40.6569 150 39 150C37.3431 150 36 148.657 36 147C36 145.343 37.3431 144 39 144Z" />
      <path d="M39 126C40.6569 126 42 127.343 42 129C42 130.657 40.6569 132 39 132C37.3431 132 36 130.657 36 129C36 127.343 37.3431 126 39 126Z" />
      <path d="M39 108C40.6569 108 42 109.343 42 111C42 112.657 40.6569 114 39 114C37.3431 114 36 112.657 36 111C36 109.343 37.3431 108 39 108Z" />
      <path d="M39 90C40.6569 90 42 91.3431 42 93C42 94.6569 40.6569 96 39 96C37.3431 96 36 94.6569 36 93C36 91.3431 37.3431 90 39 90Z" />
      <path d="M39 72C40.6569 72 42 73.3431 42 75C42 76.6569 40.6569 78 39 78C37.3431 78 36 76.6569 36 75C36 73.3431 37.3431 72 39 72Z" />
      <path d="M39 54C40.6569 54 42 55.3431 42 57C42 58.6569 40.6569 60 39 60C37.3431 60 36 58.6569 36 57C36 55.3431 37.3431 54 39 54Z" />
      <path d="M39 36C40.6569 36 42 37.3431 42 39C42 40.6569 40.6569 42 39 42C37.3431 42 36 40.6569 36 39C36 37.3431 37.3431 36 39 36Z" />
      <path d="M39 18C40.6569 18 42 19.3431 42 21C42 22.6569 40.6569 24 39 24C37.3431 24 36 22.6569 36 21C36 19.3431 37.3431 18 39 18Z" />
      <path d="M39 0C40.6569 0 42 1.34315 42 3C42 4.65685 40.6569 6 39 6C37.3431 6 36 4.65685 36 3C36 1.34315 37.3431 0 39 0Z" />
      <path d="M57 198C58.6569 198 60 199.343 60 201C60 202.657 58.6569 204 57 204C55.3431 204 54 202.657 54 201C54 199.343 55.3431 198 57 198Z" />
      <path d="M57 180C58.6569 180 60 181.343 60 183C60 184.657 58.6569 186 57 186C55.3431 186 54 184.657 54 183C54 181.343 55.3431 180 57 180Z" />
      <path d="M57 162C58.6569 162 60 163.343 60 165C60 166.657 58.6569 168 57 168C55.3431 168 54 166.657 54 165C54 163.343 55.3431 162 57 162Z" />
      <path d="M57 144C58.6569 144 60 145.343 60 147C60 148.657 58.6569 150 57 150C55.3431 150 54 148.657 54 147C54 145.343 55.3431 144 57 144Z" />
      <path d="M57 126C58.6569 126 60 127.343 60 129C60 130.657 58.6569 132 57 132C55.3431 132 54 130.657 54 129C54 127.343 55.3431 126 57 126Z" />
      <path d="M57 108C58.6569 108 60 109.343 60 111C60 112.657 58.6569 114 57 114C55.3431 114 54 112.657 54 111C54 109.343 55.3431 108 57 108Z" />
      <path d="M57 90C58.6569 90 60 91.3431 60 93C60 94.6569 58.6569 96 57 96C55.3431 96 54 94.6569 54 93C54 91.3431 55.3431 90 57 90Z" />
      <path d="M57 72C58.6569 72 60 73.3431 60 75C60 76.6569 58.6569 78 57 78C55.3431 78 54 76.6569 54 75C54 73.3431 55.3431 72 57 72Z" />
      <path d="M57 54C58.6569 54 60 55.3431 60 57C60 58.6569 58.6569 60 57 60C55.3431 60 54 58.6569 54 57C54 55.3431 55.3431 54 57 54Z" />
      <path d="M57 36C58.6569 36 60 37.3431 60 39C60 40.6569 58.6569 42 57 42C55.3431 42 54 40.6569 54 39C54 37.3431 55.3431 36 57 36Z" />
      <path d="M57 18C58.6569 18 60 19.3431 60 21C60 22.6569 58.6569 24 57 24C55.3431 24 54 22.6569 54 21C54 19.3431 55.3431 18 57 18Z" />
      <path d="M57 0C58.6569 0 60 1.34315 60 3C60 4.65685 58.6569 6 57 6C55.3431 6 54 4.65685 54 3C54 1.34315 55.3431 0 57 0Z" />
    </SvgIcon>
  );
};

export default Dots;
