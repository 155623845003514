import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.spacing(6),
    width: '100%',
    height: '100%',
  },
  media: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
  content: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    padding: theme.spacing(2, 4),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
}));

export default useStyles;
