import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const User: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M45.8802 45.815C45.2509 46.6186 44.2682 47.0978 43.2516 47.0978H6.74822C5.73161 47.0978 4.74889 46.6186 4.11957 45.815C3.52655 45.0598 3.32323 44.1473 3.54349 43.2444C5.98092 33.3591 14.8036 26.4535 25.0011 26.4535C35.1962 26.4535 44.0189 33.3591 46.4563 43.2444C46.6766 44.1473 46.4733 45.0598 45.8802 45.815ZM14.6777 13.228C14.6777 7.53739 19.3081 2.90458 25.0011 2.90458C30.6917 2.90458 35.3221 7.53739 35.3221 13.228C35.3221 18.9209 30.6917 23.5489 25.0011 23.5489C19.3081 23.5489 14.6777 18.9209 14.6777 13.228ZM49.2762 42.5497C47.0881 33.6714 40.3494 26.9207 31.8971 24.5099C35.69 22.1813 38.2267 17.9963 38.2267 13.228C38.2267 5.93503 32.294 0 25.0011 0C17.7058 0 11.7731 5.93503 11.7731 13.228C11.7731 17.9963 14.3098 22.1813 18.1027 24.5099C9.65038 26.9207 2.91174 33.6714 0.723623 42.5497C0.290356 44.3094 0.694578 46.1538 1.83463 47.6037C3.02551 49.1286 4.81909 50 6.74822 50H43.2516C45.1807 50 46.9743 49.1286 48.1652 47.6037C49.3052 46.1538 49.7094 44.3094 49.2762 42.5497Z" />
    </SvgIcon>
  );
};

export default User;
