const ROUTES = {
  root: '/',
  email: '/email',
  personalInfo: '/personal-info',
  location: '/location',
  incomes: '/incomes',
  preauthorization: '/preauthorization',
  documents: '/documents',
  contract: '/contract',
};

export default ROUTES;
