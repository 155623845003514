import Grid from '@mui/material/Grid';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DocumentTypeEnum, OnboardingStatus } from '@/api/onboarding/types';
import DocumentItem, { DocumentItemProps, DocumentItemStatus } from '@/components/DocumentItem';

export type DocumentsFormData = Record<DocumentTypeEnum, File>;

interface DocumentsFormItem {
  key: DocumentTypeEnum;
  label?: string;
  status?: DocumentItemProps['status'];
  accept: string[];
  maxSize: number;
}

export interface DocumentsFormUploadStatus
  extends Partial<Record<DocumentTypeEnum, DocumentItemStatus>> {}

export interface DocumentsFormProps {
  status?: OnboardingStatus;
  uploadStatus: DocumentsFormUploadStatus;
}

export const maxSize = 2 * 2 ** 20; // 2MB

const DocumentsForm: FC<DocumentsFormProps> = ({ status, uploadStatus }) => {
  const { t } = useTranslation('', { keyPrefix: 'documents' });
  const { control } = useFormContext();

  const documents: DocumentsFormItem[] = useMemo(
    () => [
      {
        key: DocumentTypeEnum.officialId,
        accept: ['image/jpeg', 'image/png', 'application/pdf'],
        maxSize,
        display: true,
      },
      {
        key: DocumentTypeEnum.accountStatement,
        label:
            status === OnboardingStatus.REVISION ? 'accountStatementOrBureau' : 'accountStatement',
        accept: ['image/jpeg', 'image/png', 'application/pdf'],
        maxSize,
        display: true,
      },
      {
        key: DocumentTypeEnum.proofOfAddress,
        accept: ['image/jpeg', 'image/png', 'application/pdf'],
        maxSize,
        display: true,
      },
      {
        key: DocumentTypeEnum.selfie,
        accept: ['image/jpeg', 'image/png'],
        maxSize,
        display: true,
      },
    ].filter((doc) => doc.display),
    [status],
  );

  const handleChange = (fn: (...e: any[]) => void, key: DocumentTypeEnum, file?: File) => {
    if (file) {
      const doc = documents.find((d) => d.key === key) as DocumentsFormItem;
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')) || '';

      if (doc?.accept?.includes(file.type) || doc?.accept.includes(fileExtension)) {
        fn(file);
      }
    } else {
      fn(undefined);
    }
  };

  return (
    <Grid container spacing={4} alignItems="center">
      {documents.map((item) => (
        <Grid key={item.key} item xs={12}>
          <Controller
            name={item.key}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onBlur, onChange } }) => (
              <DocumentItem
                label={t(`fields.${item.label || item.key}.label`)}
                caption={t(`fields.${item.label || item.key}.caption`)}
                TextFieldProps={{
                  label: t('selectFile'),
                  onBlur,
                }}
                file={value}
                status={uploadStatus[item.key]}
                accept={item.accept}
                maxSize={item.maxSize}
                onChange={(file) => handleChange(onChange, item.key, file)}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DocumentsForm;
