import { tooltipClasses } from '@mui/material/Tooltip';
import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  gender: {
    [`& > .${formControlLabelClasses.root}`]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  genderTooltip: {
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.error.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  },
}));

export default useStyles;
