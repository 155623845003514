import DashedDivider from '@/components/DashedDivider';
import ResponsiveTypography from '@/components/ResponsiveTypography';
import TitleWithIcon from '@/components/TitleWithIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export interface LoadingDecisionProps {
  name: string;
}

const LoadingDecision: FC<LoadingDecisionProps> = ({ name }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'preauthorization.loading' });

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <ResponsiveTypography className={classes.label} variant="h5">
          {t('title')}
        </ResponsiveTypography>
      </Grid>
      <Grid item xs={12}>
        <DashedDivider />
      </Grid>
      <Grid item xs={12}>
        <TitleWithIcon
          label={t('text', {
            name,
          })}
          icon={<CircularProgress className={classes.progress} variant="indeterminate" color="primary" size={100} />}
        />
      </Grid>
    </Grid>
  );
};

export default LoadingDecision;
