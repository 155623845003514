import { RadioProps } from '@mui/material/Radio';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ color: RadioProps['color'] }>()((theme, { color = 'default' }) => ({
  root: {
    cursor: 'pointer',
  },
  filled: {
    '&.checked': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      ...(color !== 'default' && {
        backgroundColor: theme.palette[color].main,
        color: theme.palette[color].contrastText,
      }),
    },
  },
  outlined: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    ...(color !== 'default' && {
      borderColor: theme.palette[color].main,
      color: theme.palette[color].main,
    }),
    '&.checked': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      ...(color !== 'default' && {
        backgroundColor: theme.palette[color].main,
        color: theme.palette[color].contrastText,
      }),
    },
  },
}));

export default useStyles;
