import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Error: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="100" height="100" viewBox="0 0 100 100">
      <path d="M50 5C44.0905 5 38.2389 6.16396 32.7793 8.42542C27.3196 10.6869 22.3588 14.0016 18.1802 18.1802C14.0016 22.3588 10.6869 27.3196 8.42542 32.7792C6.16396 38.2389 5 44.0905 5 50C5 55.9095 6.16396 61.7611 8.42542 67.2207C10.6869 72.6804 14.0016 77.6412 18.1802 81.8198C22.3588 85.9984 27.3196 89.3131 32.7792 91.5746C38.2389 93.836 44.0905 95 50 95C55.9095 95 61.7611 93.836 67.2208 91.5746C72.6804 89.3131 77.6412 85.9984 81.8198 81.8198C85.9984 77.6412 89.3131 72.6804 91.5746 67.2208C93.836 61.7611 95 55.9095 95 50C95 44.0905 93.836 38.2389 91.5746 32.7793C89.3131 27.3196 85.9984 22.3588 81.8198 18.1802C77.6412 14.0016 72.6804 10.6869 67.2208 8.42542C61.7611 6.16396 55.9095 5 50 5ZM30.8658 3.80602C36.9321 1.29329 43.4339 0 50 0C56.5661 0 63.0679 1.29329 69.1342 3.80603C75.2005 6.31876 80.7124 10.0017 85.3553 14.6447C89.9983 19.2876 93.6812 24.7996 96.194 30.8658C98.7067 36.9321 100 43.4339 100 50C100 56.5661 98.7067 63.0679 96.194 69.1342C93.6812 75.2005 89.9983 80.7124 85.3553 85.3553C80.7124 89.9983 75.2004 93.6812 69.1342 96.194C63.0679 98.7067 56.5661 100 50 100C43.4339 100 36.9321 98.7067 30.8658 96.194C24.7995 93.6812 19.2876 89.9983 14.6447 85.3553C10.0017 80.7124 6.31876 75.2004 3.80602 69.1342C1.29329 63.0679 0 56.5661 0 50C0 43.4339 1.29329 36.9321 3.80603 30.8658C6.31876 24.7995 10.0017 19.2876 14.6447 14.6447C19.2876 10.0017 24.7996 6.31876 30.8658 3.80602Z" />
      <path d="M62.562 40.9157L53.3648 50.1129L62.562 59.3139C63.4572 60.2053 63.4572 61.6591 62.562 62.5544C62.1144 63.002 61.5252 63.2277 60.9361 63.2277C60.3545 63.2277 59.7654 63.002 59.3139 62.5544L50.1167 53.3572L40.9195 62.5544C40.4719 63.002 39.8789 63.2277 39.2974 63.2277C38.7082 63.2277 38.119 63.002 37.6714 62.5544C36.7762 61.6591 36.7762 60.2053 37.6714 59.3139L46.8686 50.1129L37.6714 40.9157C36.7762 40.0243 36.7762 38.5705 37.6714 37.6714C38.5705 36.7762 40.0205 36.7762 40.9195 37.6714L50.1167 46.8686L59.3139 37.6714C60.2168 36.7762 61.6706 36.78 62.562 37.6714C63.4572 38.5705 63.4572 40.0243 62.562 40.9157Z" />
    </SvgIcon>
  );
};

export default Error;
