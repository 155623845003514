import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Logo: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="326" height="150" viewBox="0 0 326 150">
      <path d="M325.882 149.179H278.551V139.014H315.717V10.1652H10.1652V139.014H210.898V149.179H0V0H325.882V149.179Z" />
      <path d="M79.2187 88.6332C79.6879 88.5002 80.2118 88.3751 80.7826 88.2656C81.3534 88.1562 81.9946 88.0623 82.6358 87.9841C83.277 87.9059 83.9182 87.859 84.5516 87.8199C85.1849 87.7808 85.8261 87.7652 86.436 87.7652H86.9677V93.7001C85.9825 93.6063 84.794 93.5359 83.4021 93.4812C82.0102 93.4265 80.5245 93.3952 78.9685 93.3952C77.7956 93.3952 76.7478 93.3952 75.8407 93.4812C74.9337 93.5672 73.9797 93.5907 73.0414 93.661L70.1169 93.8409C69.1004 93.8956 68.1073 93.9269 67.1455 93.9269C64.5064 93.9755 61.8836 93.5025 59.4277 92.535C57.2811 91.6714 55.3568 90.335 53.7978 88.6253C52.2337 86.8699 51.0562 84.8052 50.3416 82.5653C49.5315 80.0391 49.1355 77.3985 49.1687 74.7459C49.1398 72.0905 49.5493 69.4485 50.3807 66.9264C51.1245 64.6622 52.3308 62.5773 53.9229 60.8038C55.5109 59.0729 57.4595 57.7116 59.6311 56.8159C64.5951 54.9285 70.0791 54.9285 75.0431 56.8159C77.2152 57.704 79.1663 59.0572 80.7591 60.7804C82.3462 62.5218 83.5526 64.5751 84.3013 66.8091C85.129 69.2755 85.5386 71.8629 85.5133 74.4643C85.5158 75.8877 85.3743 77.3076 85.0911 78.7025C84.8316 80.0235 84.425 81.3113 83.8791 82.5418C83.3584 83.7306 82.6912 84.8496 81.8929 85.8729C81.1109 86.8862 80.1895 87.7839 79.1562 88.5393L79.2187 88.6332ZM78.4368 74.7459C78.4717 72.7307 78.1894 70.7226 77.6001 68.7953C77.1117 67.2366 76.3138 65.7925 75.2543 64.5493C74.3003 63.4377 73.0974 62.567 71.7433 62.008C68.8766 60.8927 65.6959 60.8927 62.8292 62.008C61.4728 62.5667 60.2672 63.4373 59.3105 64.5493C58.2507 65.7924 57.4529 67.2365 56.9646 68.7953C55.8492 72.6839 55.8492 76.8078 56.9646 80.6964C57.4476 82.2551 58.2461 83.6977 59.3105 84.9346C60.2716 86.0418 61.4759 86.9116 62.8292 87.4759C65.6939 88.602 68.8786 88.602 71.7433 87.4759C73.0942 86.9113 74.296 86.0415 75.2543 84.9346C76.3185 83.6976 77.117 82.255 77.6001 80.6964C78.2006 78.7709 78.4935 76.7627 78.468 74.7459H78.4368Z" />
      <path d="M125.947 67.3096C126.017 69.6982 125.254 72.0369 123.789 73.9248C122.351 75.7389 120.125 77.0812 117.111 77.9518V78.0613L128.207 93.3873H120.013L109.847 78.9527H105.156V93.3873H98.3292V56.1043H109.675C111.427 56.094 113.177 56.2011 114.914 56.4249C116.33 56.5882 117.723 56.9108 119.066 57.3867C121.091 58.0939 122.858 59.39 124.141 61.1088C125.393 62.9285 126.026 65.1024 125.947 67.3096ZM108.557 73.2445C109.884 73.2574 111.211 73.1869 112.529 73.0334C113.465 72.9304 114.386 72.7205 115.274 72.4078C116.444 72.0481 117.452 71.2936 118.128 70.2731C118.67 69.3219 118.941 68.2399 118.91 67.1453C118.927 66.2183 118.712 65.3018 118.284 64.4789C117.794 63.652 117.043 63.0111 116.15 62.657C115.366 62.3234 114.54 62.1023 113.694 62.0002C112.484 61.8455 111.263 61.7749 110.043 61.789H105.163V73.221L108.557 73.2445Z" />
      <path d="M145.136 87.6792H163.473V93.3952H138.31V56.1044H162.472V61.8126H145.136V71.4618H159.993V77.1699H145.136V87.6792Z" />
      <path d="M190.082 93.3952H183.248V61.8126H171.206V56.1044H202.093V61.8126H190.082V93.3952Z" />
      <path d="M218.326 87.6792H236.663V93.3952H211.5V56.1044H235.662V61.8126H218.326V71.4618H233.183V77.1699H218.326V87.6792Z" />
      <path d="M248.001 93.3952V56.1044H254.827V73.0804H254.937L269.012 56.1044H277.488L261.935 74.5817L278.77 93.3952H270.044L254.96 76.56H254.851V93.3952H248.001Z" />
      <path d="M220.14 149.046C219.548 149.033 218.957 148.979 218.373 148.882C217.902 148.812 217.441 148.681 217.004 148.491V146.622C217.255 146.716 217.497 146.794 217.732 146.857C217.971 146.927 218.214 146.984 218.459 147.029C218.709 147.076 218.967 147.115 219.241 147.146C219.515 147.177 219.812 147.193 220.14 147.193V145.105C219.737 145.051 219.337 144.973 218.944 144.87C218.566 144.783 218.207 144.633 217.88 144.425C217.56 144.232 217.291 143.963 217.098 143.643C216.883 143.259 216.78 142.823 216.801 142.384C216.796 141.999 216.874 141.618 217.028 141.266C217.184 140.938 217.408 140.648 217.685 140.413C218 140.164 218.354 139.968 218.733 139.835C219.185 139.685 219.651 139.583 220.124 139.53V138.552H221.641V139.506C222.116 139.532 222.588 139.584 223.057 139.663C223.506 139.733 223.948 139.843 224.378 139.991V141.876C223.493 141.579 222.573 141.401 221.641 141.344V143.463C222.042 143.526 222.439 143.612 222.83 143.721C223.202 143.814 223.556 143.967 223.878 144.175C224.197 144.369 224.465 144.637 224.66 144.956C224.871 145.339 224.974 145.771 224.957 146.208C224.972 146.572 224.902 146.935 224.753 147.267C224.604 147.6 224.379 147.893 224.097 148.123C223.388 148.638 222.547 148.94 221.673 148.991V150H220.156L220.14 149.046ZM218.905 142.29C218.899 142.409 218.924 142.528 218.977 142.635C219.031 142.742 219.111 142.833 219.21 142.9C219.495 143.071 219.811 143.182 220.14 143.228V141.399C219.794 141.409 219.458 141.518 219.17 141.711C219.088 141.784 219.022 141.873 218.976 141.972C218.93 142.072 218.906 142.18 218.905 142.29ZM222.814 146.254C222.822 146.137 222.799 146.019 222.749 145.912C222.698 145.805 222.621 145.713 222.525 145.645C222.252 145.481 221.949 145.375 221.634 145.332V147.13C222.447 147.029 222.838 146.731 222.838 146.254H222.814Z" />
      <path d="M231.854 149.046C231.264 149.034 230.676 148.979 230.094 148.882C229.622 148.815 229.162 148.683 228.726 148.491V146.622C229.196 146.797 229.68 146.933 230.173 147.029C230.423 147.076 230.681 147.115 230.954 147.146C231.228 147.177 231.525 147.193 231.854 147.193V145.105C231.451 145.049 231.051 144.971 230.657 144.87C230.281 144.782 229.921 144.631 229.594 144.425C229.273 144.232 229.005 143.963 228.812 143.643C228.6 143.258 228.497 142.823 228.515 142.384C228.51 141.999 228.587 141.618 228.742 141.266C228.898 140.937 229.125 140.646 229.406 140.413C229.717 140.162 230.069 139.967 230.446 139.835C230.898 139.685 231.365 139.583 231.838 139.53V138.552H233.355V139.506C233.829 139.532 234.302 139.584 234.77 139.663C235.22 139.732 235.662 139.842 236.092 139.991V141.876C235.207 141.579 234.287 141.401 233.355 141.344V143.463C233.756 143.526 234.153 143.612 234.544 143.721C234.916 143.813 235.27 143.966 235.591 144.175C235.911 144.369 236.179 144.637 236.373 144.956C236.582 145.339 236.682 145.772 236.663 146.208C236.679 146.571 236.61 146.934 236.462 147.266C236.314 147.598 236.091 147.892 235.81 148.123C235.098 148.637 234.255 148.938 233.378 148.991V150H231.861L231.854 149.046ZM230.618 142.29C230.614 142.41 230.641 142.529 230.695 142.636C230.75 142.742 230.831 142.833 230.931 142.9C231.213 143.07 231.527 143.182 231.854 143.228V141.399C231.51 141.409 231.176 141.518 230.892 141.711C230.808 141.783 230.74 141.872 230.693 141.971C230.646 142.071 230.62 142.18 230.618 142.29ZM234.528 146.254C234.535 146.137 234.513 146.019 234.462 145.912C234.412 145.805 234.335 145.713 234.239 145.645C233.966 145.481 233.662 145.375 233.347 145.332V147.13C234.16 147.029 234.551 146.731 234.551 146.254H234.528Z" />
      <path d="M243.567 149.046C242.978 149.033 242.39 148.979 241.808 148.882C241.336 148.813 240.876 148.682 240.439 148.491V146.622C240.69 146.716 240.932 146.794 241.167 146.857C241.401 146.927 241.644 146.982 241.886 147.029C242.128 147.076 242.402 147.115 242.668 147.146C242.934 147.177 243.239 147.193 243.559 147.193V145.105C243.159 145.05 242.762 144.972 242.371 144.87C241.993 144.785 241.633 144.634 241.307 144.425C240.988 144.23 240.72 143.962 240.525 143.643C240.316 143.257 240.216 142.822 240.236 142.384C240.227 141.999 240.305 141.617 240.463 141.266C240.613 140.935 240.838 140.643 241.12 140.413C241.434 140.162 241.788 139.967 242.168 139.835C242.617 139.684 243.081 139.582 243.552 139.53V138.552H245.069V139.506C245.545 139.532 246.021 139.584 246.492 139.663C246.941 139.734 247.383 139.844 247.813 139.991V141.876C246.925 141.579 246.003 141.401 245.069 141.344V143.463C245.47 143.525 245.866 143.611 246.257 143.721C246.631 143.814 246.988 143.967 247.313 144.175C247.63 144.371 247.898 144.639 248.095 144.956C248.303 145.339 248.403 145.772 248.384 146.208C248.4 146.571 248.332 146.934 248.184 147.266C248.036 147.598 247.813 147.892 247.532 148.123C246.82 148.638 245.977 148.939 245.1 148.991V150H243.583L243.567 149.046ZM242.34 142.29C242.334 142.409 242.359 142.528 242.412 142.635C242.466 142.742 242.546 142.833 242.645 142.9C242.927 143.07 243.241 143.182 243.567 143.228V141.399C243.224 141.411 242.89 141.519 242.605 141.711C242.523 141.784 242.457 141.873 242.411 141.972C242.365 142.072 242.341 142.18 242.34 142.29ZM246.249 146.254C246.255 146.137 246.232 146.02 246.182 145.913C246.131 145.807 246.055 145.714 245.96 145.645C245.685 145.48 245.379 145.374 245.061 145.332V147.13C245.874 147.029 246.273 146.731 246.273 146.254H246.249Z" />
      <path d="M255.288 149.046C254.699 149.034 254.111 148.979 253.529 148.882C253.055 148.813 252.592 148.682 252.153 148.491V146.622C252.403 146.716 252.646 146.794 252.88 146.857C253.119 146.927 253.362 146.984 253.607 147.029C253.858 147.076 254.116 147.115 254.389 147.146C254.663 147.177 254.96 147.193 255.288 147.193V145.105C254.885 145.05 254.486 144.972 254.092 144.87C253.715 144.783 253.355 144.633 253.029 144.425C252.708 144.232 252.44 143.963 252.247 143.643C252.034 143.258 251.932 142.823 251.95 142.384C251.945 141.999 252.022 141.618 252.176 141.266C252.333 140.937 252.56 140.646 252.841 140.413C253.152 140.162 253.504 139.967 253.881 139.835C254.333 139.685 254.8 139.583 255.273 139.53V138.552H256.79V139.506C257.264 139.532 257.737 139.584 258.205 139.663C258.655 139.732 259.097 139.842 259.527 139.991V141.876C258.642 141.579 257.721 141.401 256.79 141.344V143.463C257.191 143.526 257.588 143.612 257.978 143.721C258.351 143.813 258.705 143.966 259.026 144.175C259.345 144.369 259.613 144.637 259.808 144.956C260.013 145.341 260.113 145.772 260.097 146.208C260.114 146.571 260.045 146.934 259.897 147.266C259.749 147.598 259.526 147.892 259.245 148.123C258.533 148.637 257.69 148.938 256.813 148.991V150H255.296L255.288 149.046ZM254.053 142.29C254.047 142.409 254.072 142.528 254.126 142.635C254.179 142.742 254.259 142.833 254.358 142.9C254.643 143.071 254.959 143.182 255.288 143.228V141.399C254.942 141.409 254.606 141.518 254.319 141.711C254.236 141.784 254.17 141.873 254.124 141.972C254.079 142.072 254.054 142.18 254.053 142.29ZM257.963 146.254C257.97 146.137 257.948 146.019 257.897 145.912C257.846 145.805 257.769 145.713 257.673 145.645C257.401 145.481 257.097 145.375 256.782 145.332V147.13C257.595 147.029 257.986 146.731 257.986 146.254H257.963Z" />
      <path d="M267.002 149.046C266.412 149.034 265.824 148.979 265.243 148.882C264.771 148.815 264.31 148.683 263.874 148.491V146.622C264.11 146.713 264.35 146.791 264.594 146.857C264.836 146.927 265.078 146.982 265.321 147.029C265.563 147.076 265.829 147.115 266.103 147.146C266.376 147.177 266.681 147.193 267.002 147.193V145.105C266.599 145.049 266.2 144.971 265.806 144.87C265.429 144.782 265.069 144.631 264.742 144.425C264.422 144.232 264.153 143.963 263.96 143.643C263.748 143.258 263.645 142.823 263.663 142.384C263.657 141.998 263.737 141.616 263.898 141.266C264.048 140.935 264.272 140.643 264.555 140.413C264.865 140.162 265.217 139.967 265.594 139.835C266.047 139.685 266.513 139.583 266.986 139.53V138.552H268.503V139.506C268.978 139.532 269.45 139.584 269.919 139.663C270.37 139.733 270.815 139.843 271.248 139.991V141.876C270.36 141.58 269.437 141.401 268.503 141.344V143.463C268.904 143.525 269.301 143.611 269.692 143.721C270.064 143.813 270.418 143.966 270.74 144.175C271.059 144.369 271.327 144.637 271.522 144.956C271.73 145.339 271.83 145.772 271.811 146.208C271.827 146.571 271.759 146.934 271.611 147.266C271.463 147.598 271.24 147.892 270.959 148.123C270.247 148.638 269.403 148.939 268.527 148.991V150H267.002V149.046ZM265.774 142.29C265.769 142.409 265.794 142.528 265.847 142.635C265.9 142.742 265.98 142.833 266.079 142.9C266.362 143.07 266.675 143.182 267.002 143.228V141.399C266.658 141.411 266.325 141.519 266.04 141.711C265.958 141.784 265.891 141.873 265.846 141.972C265.8 142.072 265.776 142.18 265.774 142.29ZM269.684 146.254C269.691 146.136 269.667 146.018 269.615 145.911C269.563 145.804 269.484 145.712 269.387 145.645C269.115 145.479 268.811 145.373 268.495 145.332V147.13C269.309 147.029 269.708 146.731 269.708 146.254H269.684Z" />
    </SvgIcon>
  );
};

export default Logo;
