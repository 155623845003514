import {
  DependencyList, EffectCallback, useEffect, useRef,
} from 'react';

const useTimeoutEffect = (
  effect: EffectCallback,
  deps: DependencyList | undefined,
  timeout: number,
  onStart: () => void = () => null,
) => {
  const timeourRef = useRef(setTimeout(() => null, 0));

  useEffect(() => {
    let effectReturns: ReturnType<EffectCallback>;

    clearTimeout(timeourRef.current);
    onStart();

    timeourRef.current = setTimeout(async () => {
      effectReturns = effect();
    }, timeout);

    return () => {
      clearTimeout(timeourRef.current);

      if (typeof effectReturns === 'function') {
        effectReturns();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useTimeoutEffect;
