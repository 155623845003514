import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Male: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M28.7856 40.414C26.2195 42.9774 22.8124 44.3887 19.1876 44.3887C15.5605 44.3887 12.1513 42.9774 9.58737 40.4119C7.02557 37.8507 5.61178 34.4444 5.61178 30.8182C5.61178 27.1942 7.02557 23.7858 9.58737 21.2203C12.2341 18.5785 15.7109 17.2543 19.1876 17.2543C22.6599 17.2543 26.1389 18.5785 28.7834 21.2203C34.0769 26.5147 34.0769 35.1218 28.7856 40.414ZM46.7053 3.48785C46.6769 3.45082 46.6464 3.41816 46.6159 3.38549C46.3698 3.13939 46.0365 3 45.6879 3H32.1056C31.3846 3 30.8008 3.58585 30.8008 4.30674C30.8008 5.02762 31.3846 5.61347 32.1056 5.61347H42.551L29.6723 18.4892C23.3201 13.0793 13.7395 13.3755 7.74238 19.3756C4.6839 22.4312 3 26.4951 3 30.8182C3 35.1414 4.6839 39.2053 7.7402 42.2609C10.7965 45.3187 14.8614 47 19.1877 47C23.5096 47 27.5745 45.3187 30.633 42.2609C36.6345 36.263 36.9264 26.689 31.5196 20.3339L44.3526 7.50389L44.2676 17.7618C44.2611 18.4827 44.8427 19.0729 45.5638 19.0772H45.5747C46.2914 19.0772 46.8774 18.5001 46.8817 17.7814L46.9906 4.46572C47.032 4.12379 46.9362 3.7688 46.7053 3.48785Z" />
    </SvgIcon>
  );
};

export default Male;
