import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ROUTES from '@/constants/routes';
import useOnboardingStore from '@/modules/Onboarding/store';
import currencyFormat from '@/utils/currencyFormat';
import { EmailFormData } from './components/EmailForm';

const useEmailPage = () => {
  const [{ loanRequest }, actions] = useOnboardingStore();
  const navigate = useNavigate();

  const form = useForm<EmailFormData>({
    defaultValues: {
      email: '',
      tnc: false,
    },
    mode: 'onChange',
  });

  const handleFormSubmit = async (data: EmailFormData) => {
    await actions.register(data);
    navigate({ pathname: ROUTES.personalInfo });
  };

  useEffect(() => {
    if (!loanRequest) {
      navigate({
        pathname: ROUTES.root,
      });
    }
  }, [actions, loanRequest, navigate]);

  return {
    amount: currencyFormat(loanRequest?.amount as string),
    term: loanRequest?.term ?? '0',
    form,
    handleFormSubmit,
  };
};

export default useEmailPage;
