import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import ROUTES from '@/constants/routes';
import tncConstants from '@/constants/tnc';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import EmailForm from '../components/EmailForm';
import useEmailPage from '../useEmailPage';

const EmailPage = () => {
  const { t } = useTranslation('', { keyPrefix: 'email' });
  const {
    amount, term, form, handleFormSubmit,
  } = useEmailPage();

  return (
    <OnboardingStepsLayout
      steps={{}}
      backButton={{
        link: ROUTES.root,
      }}
      continueButton={{
        type: 'submit',
        disabled: !form.formState.isValid || form.formState.isSubmitting,
      }}
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      <Grid container direction="column" rowSpacing={4}>
        <Grid item>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey="confirmation"
              components={{
                1: <strong />,
                2: <Link href={ROUTES.root} underline="always" />,
              }}
              values={{
                amount,
                term,
              }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <Trans
              t={t}
              i18nKey="noticeOfPrivacy"
              components={{
                1: <strong />,
                2: <Link href={tncConstants.fileUrl} target="_blank" underline="always" />,
              }}
            />
          </Typography>
        </Grid>
        <Grid item>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <EmailForm />
          </FormProvider>
        </Grid>
      </Grid>
    </OnboardingStepsLayout>
  );
};

export default EmailPage;
