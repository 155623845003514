import { LoanRequest } from '@/api/onboarding/types';

export const storage = sessionStorage;

const loanRequestKey = 'loan-request';
const accessTokenKey = 'access-token';

const storageActions = {
  setLoanRequest: ({ amount, term }: LoanRequest) => {
    storage.setItem(loanRequestKey, JSON.stringify({ amount, term }));
  },
  getLoanRequest: () => {
    const loanRequest = storage.getItem(loanRequestKey);
    return loanRequest ? (JSON.parse(loanRequest) as LoanRequest) : null;
  },
  removeLoanRequest: () => {
    storage.removeItem(loanRequestKey);
  },
  setAccessToken: (id: string) => {
    storage.setItem(accessTokenKey, id);
  },
  getAccessToken: () => {
    return storage.getItem(accessTokenKey);
  },
  removeAccessToken: () => {
    return storage.removeItem(accessTokenKey);
  },
};

export default storageActions;
