import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import LoadingDecision from '../components/LoadingDecision';
import MadeDecision, { MadeDecisionProps } from '../components/MadeDecision';
import usePreauthorizationPage from '../usePreauthorizationPage';

const PreauthorizationPage: FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'onboarding' });
  const {
    preauthorizationStatus, firstname, email, amount, loading, isRejected, handleSubmit,
  } = usePreauthorizationPage();

  return (
    <OnboardingStepsLayout
      steps={{
        personal: 'completed',
        location: 'completed',
        incomes: 'completed',
      }}
      continueButton={
        !loading && {
          type: 'submit',
          label: isRejected ? t('finish') : undefined,
          hideIcon: isRejected,
        }
      }
      showDivider={!loading}
      onSubmit={handleSubmit}
    >
      {loading ? (
        <LoadingDecision name={firstname} />
      ) : (
        <MadeDecision
          status={preauthorizationStatus as MadeDecisionProps['status']}
          name={firstname}
          amount={amount}
          email={email}
        />
      )}
    </OnboardingStepsLayout>
  );
};

export default PreauthorizationPage;
