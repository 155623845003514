export default {
  es: {
    occupationTitle: '¿A qué te dedicas, {{name}}?',
    yes: 'Sí',
    no: 'No',
    required: 'Este campo es requerido',
    fields: {
      occupation: {
        label: 'Ocupación',
      },
      monthlyIncome: {
        label: 'Ingreso mensual NETO',
        description: 'Tus ingresos mensuales netos son aquellos después de impuestos',
      },
      mortgage: {
        label: '¿Pagas renta / hipoteca?',
      },
      automotiveCredit: {
        label: '¿Tienes algún crédito / arrendamiento automotriz?',
      },
      creditCard: {
        label: '¿Tienes tarjeta de crédito?',
      },
      monthlyOutcome: {
        label: 'Gastos mensuales',
      },
    },
  },
};
