export interface GetPaymentDataOptions {
  amount: number;
  interestRate: number;
  term: number;
}

export interface PaymentData {
  amount: number;
  interest: number;
  amortization: number;
  payment: number;
  debt: number;
}

const getPaymentData = (data: GetPaymentDataOptions): PaymentData => {
  const {
    amount, interestRate, term,
  } = data;

  const interest = (amount * interestRate) / term;
  const amortization = amount / term;
  const payment = amortization + interest;
  const debt = amount + interest - payment;

  return {
    amount,
    interest,
    amortization,
    payment,
    debt,
  };
};

export default getPaymentData;
