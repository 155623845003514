import { createStore, DispatchType, useStore } from 'react-hookstore';

import api from '@/api/.';
import {
  Address,
  Contract,
  DocumentTypeEnum,
  Incomes,
  LoanRequest,
  MetaAttributes,
  OnboardingDocument,
  OnboardingDocuments,
  OnboardingStatus,
  OnboardingUpsertAddressRequest,
  OnboardingUpsertDocumentsRequest,
  OnboardingUpsertIncomesRequest,
  OnboardingUpsertPersonalInfoRequest,
  PersonalInfo,
} from '@/api/onboarding/types';
import { DocumentItemStatus } from '@/components/DocumentItem';
import contractConfig from '@/config/contract';
import storageActions from './storageActions';

interface OnboardingStoreState extends PersonalInfo, Partial<MetaAttributes> {
  id: string;
  folio: string;
  email: string;
  loanRequest?: LoanRequest;
  tnc: boolean;
  address?: Address;
  incomes?: Incomes;
  documents?: OnboardingDocuments;
  contract?: Contract;
  status?: OnboardingStatus;
}

type Action = { persist?: boolean } & (
  | {
    type: 'RESET';
    payload?: undefined;
  }
  | {
    type: 'SET_LOAN_REQUEST';
    payload: LoanRequest;
  }
  | {
    type: 'UPDATE';
    payload: Partial<OnboardingStoreState>;
  }
  | {
    type: 'UPDATE_ADDRESS';
    payload: Partial<Address>;
  }
  | {
    type: 'UPDATE_INCOMES';
    payload: Partial<Incomes>;
  }
  | {
    type: 'ADD_DOCUMENT';
    payload: {
      type: DocumentTypeEnum;
      document: Partial<OnboardingDocument>;
    };
  }
  | {
    type: 'SET_CONTRACT';
    payload: Contract;
  }
  | {
    type: 'SET_STATUS';
    payload: OnboardingStatus;
  }
);

const initialState: OnboardingStoreState = {
  id: '',
  folio: '',
  email: '',
  tnc: false,
  loanRequest: undefined,
  gender: undefined,
  firstname: '',
  lastname: '',
  secondLastname: '',
  country: '',
  state: '',
  birthdate: '',
  curp: '',
  rfc: '',
  phone: '',
  _createdAt: undefined,
  _updatedAt: undefined,
  _version: 0,
  status: undefined,
};

const OnboardingStore = createStore<OnboardingStoreState, Action>(
  'onboarding',
  initialState,
  (state, { type, payload }) => {
    switch (type) {
      case 'RESET':
        return initialState;
      case 'SET_LOAN_REQUEST':
        return {
          ...state,
          loanRequest: payload,
        };
      case 'UPDATE':
        return {
          ...state,
          ...payload,
        };
      case 'UPDATE_ADDRESS':
        return {
          ...state,
          address: {
            ...state.address,
            ...payload,
          } as Address,
        };
      case 'UPDATE_INCOMES':
        return {
          ...state,
          incomes: {
            ...state.incomes,
            ...payload,
          } as Incomes,
        };
      case 'ADD_DOCUMENT':
        return {
          ...state,
          documents: {
            ...state.documents,
            [payload.type]: {
              ...state.documents?.[payload.type],
              ...payload.document,
            },
          },
        };
      case 'SET_CONTRACT':
        return {
          ...state,
          contract: {
            ...state.contract,
            ...payload,
          },
        };
      case 'SET_STATUS':
        return {
          ...state,
          status: payload,
        };
      default:
        return state;
    }
  },
);

export const OnboardingStoreActions = {
  reset: () => {
    storageActions.removeAccessToken();
    storageActions.removeLoanRequest();
    OnboardingStore.dispatch<Action>({ type: 'RESET' });
  },
  setLoanRequest: ({ amount, term, interestRate }: LoanRequest) => {
    storageActions.setLoanRequest({ amount, term, interestRate });
    OnboardingStore.dispatch<Action>({
      type: 'SET_LOAN_REQUEST',
      payload: { amount, term, interestRate },
    });
  },
  getLoanRequest: () => {
    const loanRequest = storageActions.getLoanRequest();

    return loanRequest;
  },
  getRegister: async (token?: string) => {
    if (token) {
      // This will add the Authorization Header to all requests
      storageActions.setAccessToken(token);
    }

    const { data } = await api.onboarding.getRegister();

    storageActions.removeLoanRequest();
    OnboardingStore.dispatch<Action>({
      type: 'UPDATE',
      payload: data,
    });

    return data;
  },
  register: async (data: { email: string; tnc: boolean }) => {
    const { loanRequest } = OnboardingStore.getState();

    if (!loanRequest) {
      throw new Error('No loan request');
    }

    const { data: res } = await api.onboarding.register({
      email: data.email,
      amount: loanRequest.amount,
      term: loanRequest.term,
      interestRate: loanRequest.interestRate,
    });

    const { accessToken, ...payload } = res;

    OnboardingStore.dispatch<Action>({
      type: 'UPDATE',
      payload: {
        ...payload,
        tnc: data.tnc,
      },
    });
    storageActions.setAccessToken(res.accessToken);
    storageActions.removeLoanRequest();

    return res;
  },
  updatePersonalInfo: async (data: OnboardingUpsertPersonalInfoRequest) => {
    const { data: personalInfo } = await api.onboarding.upsertPersonalInfo(data);

    OnboardingStore.dispatch<Action>({
      type: 'UPDATE',
      payload: personalInfo,
    });

    return personalInfo;
  },
  updateAddress: async (data: OnboardingUpsertAddressRequest) => {
    const { data: address } = await api.onboarding.upsertAddress(data);

    OnboardingStore.dispatch<Action>({
      type: 'UPDATE_ADDRESS',
      payload: address,
    });

    return address;
  },
  updateIncomes: async (data: OnboardingUpsertIncomesRequest) => {
    const { data: incomes } = await api.onboarding.upsertIncomes(data);

    OnboardingStore.dispatch<Action>({
      type: 'UPDATE_INCOMES',
      payload: incomes,
    });

    return incomes;
  },
  getPreauthorizationResponse: async () => {
    const { data } = await api.onboarding.getPreauthorization();

    OnboardingStore.dispatch<Action>({
      type: 'SET_STATUS',
      payload: data.status,
    });

    return data;
  },
  uploadDocuments: async (
    data: Partial<Record<keyof OnboardingDocuments, File>>,
    onUpdate: (
      type: DocumentTypeEnum,
      status: DocumentItemStatus,
      data: OnboardingDocument,
    ) => void,
  ) => {
    const request = Object.keys(data).reduce((obj, key) => {
      const file = data[key as DocumentTypeEnum] as File;

      return {
        ...obj,
        [key]: {
          filename: file.name,
          mimeType: file.type,
          size: file.size,
        },
      };
    }, {} as OnboardingUpsertDocumentsRequest);

    const { data: upsertData } = await api.onboarding.upsertDocuments(request);

    const promises = Object.keys(upsertData.signedUrls).map(async (key) => {
      const docKey = key as DocumentTypeEnum;
      const doc = upsertData.documents[docKey] as OnboardingDocument;

      onUpdate(docKey, 'loading', doc);

      try {
        const uploadData = await api.onboarding.uploadDocumentFile({
          signedUrl: upsertData.signedUrls[docKey],
          file: data[docKey] as File,
        });

        if (!uploadData.ok) {
          throw new Error('Error uploading file');
        }

        const uploadResult = await uploadData.text();

        OnboardingStore.dispatch<Action>({
          type: 'ADD_DOCUMENT',
          payload: {
            type: docKey,
            document: upsertData.documents[docKey] as Partial<OnboardingDocument>,
          },
        });
        onUpdate(docKey, 'completed', doc);

        return uploadResult;
      } catch (err) {
        onUpdate(docKey, 'error', doc);
        return '';
      }
    });

    const uploadData = await Promise.all(promises);

    return {
      upsertData,
      uploadData,
    };
  },
  setContract: async (data: Pick<Contract, 'signature'>) => {
    const { data: contract } = await api.onboarding.upsertContract({
      contractVersion: contractConfig.version,
      termsConditionsVersion: contractConfig.termsConditionsVersion,
      signature: data.signature,
    });

    OnboardingStore.dispatch<Action>({
      type: 'SET_CONTRACT',
      payload: contract,
    });
  },
  setStatus: (status: OnboardingStatus) => {
    OnboardingStore.dispatch<Action>({ type: 'SET_STATUS', payload: status });
  },
};

const useOnboardingStore = (): [
  OnboardingStoreState,
  typeof OnboardingStoreActions,
  DispatchType<OnboardingStoreState, Action>,
] => {
  const [state, dispatch] = useStore(OnboardingStore);

  return [state, OnboardingStoreActions, dispatch];
};

api.interceptors.request.use((config) => {
  const accessToken = storageActions.getAccessToken();

  const headers = {
    ...config.headers,
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return {
    ...config,
    headers,
  };
});

export default useOnboardingStore;
