import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Attach: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="30" height="30" viewBox="0 0 30 30">
      <path d="M18.24 12.2171C18.7693 12.7463 19.0598 13.4492 19.0598 14.1982C19.0598 14.9463 18.7693 15.6492 18.2391 16.1784L14.6891 19.7275C14.3815 20.036 13.9721 20.2058 13.5362 20.2058C13.1004 20.2058 12.6891 20.036 12.3815 19.7275C12.074 19.4209 11.9042 19.0105 11.9042 18.5746C11.9032 18.1388 12.074 17.7284 12.3825 17.4209L15.7617 14.0407C15.9806 13.819 16.34 13.819 16.5617 14.0407C16.7825 14.2614 16.7825 14.6199 16.5617 14.8407L13.1825 18.2199C13.0881 18.3152 13.0353 18.4416 13.0353 18.5746C13.0353 18.7077 13.0881 18.8331 13.1825 18.9265C13.2768 19.0218 13.4023 19.0746 13.5353 19.0746C13.6683 19.0746 13.7947 19.0218 13.89 18.9275L17.4391 15.3775C17.7542 15.0624 17.9287 14.6435 17.9287 14.1982C17.9287 13.752 17.7542 13.3331 17.4391 13.018C16.807 12.3869 15.7098 12.3879 15.0777 13.018L11.9419 16.1539C11.723 16.3746 11.3636 16.3746 11.1428 16.1539C10.9211 15.9331 10.9211 15.5746 11.1428 15.3539L14.2787 12.2171C15.3362 11.1586 17.1806 11.1596 18.24 12.2171ZM21.9818 21.6038C21.9818 22.8519 20.9657 23.8689 19.7167 23.8689H10.2827C9.03459 23.8689 8.01855 22.8519 8.01855 21.6038V8.39623C8.01855 7.14812 9.03459 6.13208 10.2827 6.13208H16.794C17.1412 6.13208 17.4818 6.27359 17.728 6.51887L21.595 10.3859C21.8431 10.6349 21.9818 10.967 21.9818 11.3198V21.6038ZM22.3943 9.58491L18.5283 5.71887C18.0641 5.25566 17.449 5 16.7943 5H10.283C8.41034 5 6.8877 6.52359 6.8877 8.39623V21.6038C6.8877 23.4774 8.41034 25 10.283 25H19.7169C21.5905 25 23.1132 23.4774 23.1132 21.6038V11.3198C23.1132 10.6642 22.8584 10.0481 22.3943 9.58491Z" />
    </SvgIcon>
  );
};

export default Attach;
