import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Plus: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M37.9912 24.9375C37.9912 25.4997 37.4915 25.9369 36.9919 25.9369H25.9992V36.9295C25.9992 37.4917 25.4995 37.9913 24.9999 37.9913C24.4378 37.9913 24.0005 37.4917 24.0005 36.9295V25.9369H13.0079C12.4458 25.9369 12.0085 25.4997 12.0085 25C12.0085 24.4379 12.4458 23.9382 13.0079 23.9382H24.0005V12.9455C24.0005 12.4459 24.4378 12.0087 24.9999 12.0087C25.4995 12.0087 25.9992 12.4459 25.9992 12.9455V23.9382H36.9919C37.4915 23.9382 37.9912 24.4379 37.9912 24.9375Z" />
    </SvgIcon>
  );
};

export default Plus;
