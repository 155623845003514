import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  label: {
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  list: {
    '& > li': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default useStyles;
