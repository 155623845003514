import { AxiosInstance } from 'axios';
import { AmortizationGetFileRequest, AmortizationGetPaymentRequest, AmortizationGetPaymentResponse } from './types';

const amortizationApi = (instance: AxiosInstance) => {
  const baseUrl = '/amortization';

  return {
    getPaymentData: async ({
      amount, interestRate, term,
    }: AmortizationGetPaymentRequest) => {
      return instance.get<AmortizationGetPaymentResponse>(`${baseUrl}/payment`, {
        params: {
          amount,
          interestRate,
          term,
        },
      });
    },
    getFile: async ({
      amount, interestRate, term, date,
    }: AmortizationGetFileRequest) => {
      return instance.get<Blob>(`${baseUrl}/file`, {
        responseType: 'blob',
        params: {
          amount,
          interestRate,
          term,
          date,
        },
      });
    },
  };
};

export default amortizationApi;
