import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import despreocupateTallImg from '@/assets/images/despreocupate-tall.webp';
import despreocupateWideImg from '@/assets/images/despreocupate-wide.webp';
import disfrutaTallImg from '@/assets/images/disfruta-tall.webp';
import disfrutaWideImg from '@/assets/images/disfruta-wide.webp';
import gustoTallImg from '@/assets/images/gusto-tall.webp';
import gustoWideImg from '@/assets/images/gusto-wide.webp';
import Carousel from '@/components/Carousel';
import Footer from '@/components/Footer';
import InfoImgCard from '@/components/InfoImgCard';
import ResponsiveTypography from '@/components/ResponsiveTypography';
import Logo from '@/icons/Logo';
import useStyles from './styles';

const OnboardingCarouselLayout: FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'onboarding' });
  const { classes, theme } = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTall = useMediaQuery('(min-height:800px)');

  const carouselItems = [
    {
      label: t('imagesTitle1'),
      description: t(isDesktop ? 'imagesText1' : 'imagesText1Mobile'),
      imgSrc: isDesktop && isTall ? despreocupateTallImg : despreocupateWideImg,
      alt: t('imagesTitle2'),
    },
    {
      label: t('imagesTitle2'),
      description: t(isDesktop ? 'imagesText2' : 'imagesText2Mobile'),
      imgSrc: isDesktop && isTall ? disfrutaTallImg : disfrutaWideImg,
      alt: t('imagesTitle2'),
    },
    {
      label: t('imagesTitle3'),
      description: t(isDesktop ? 'imagesText3' : 'imagesText3Mobile'),
      imgSrc: isDesktop && isTall ? gustoTallImg : gustoWideImg,
      alt: t('imagesTitle3'),
    },
  ];

  return (
    <Grid className={classes.root} item container direction="column" spacing={2}>
      <Grid
        item
        display={{
          xs: 'none',
          lg: 'block',
        }}
      >
        <Logo color="secondary" className={classes.logo} />
      </Grid>

      <Grid className={classes.middleContent} item container direction="column" alignSelf="center">
        <ResponsiveTypography className={classes.title} variant="h3">
          {t('carouselTitle')}
        </ResponsiveTypography>

        <Box className={classes.carouselContainer}>
          <Carousel className={classes.carousel} autoplay>
            {carouselItems.map((item) => (
              <InfoImgCard
                key={item.label}
                label={item.label}
                description={item.description}
                imgSrc={item.imgSrc}
                alt={item.alt}
              />
            ))}
          </Carousel>
        </Box>
      </Grid>

      <Grid item>
        <Footer className={classes.footer} showLogo={!isDesktop} />
      </Grid>
    </Grid>
  );
};

export default OnboardingCarouselLayout;
