export interface MetaAttributes {
  _createdAt: string;
  _updatedAt: string;
  _version: number;
}

export interface LoanRequest {
  amount: string;
  term: number;
  interestRate: number;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface PersonalInfo {
  gender?: Gender;
  firstname: string;
  lastname: string;
  secondLastname: string;
  country: string;
  state: string;
  /**
   * @format YYYY-MM-DD
   */
  birthdate: string;
  curp: string;
  rfc: string;
  phone: string;
}

export interface Address {
  privateName: string;
  street: string;
  exterior: string;
  interior: string;
  colony: string;
  postalCode: string;
  municipality: string;
  state: string;
}

export interface Incomes {
  occupation: string;
  monthlyIncome: string;
  monthlyOutcome: string;
  mortgage: boolean;
  automotiveCredit: boolean;
  creditCard: boolean;
}

export enum PreauthorizationStatus {
  REJECTED = 'REJECTED',
  REVISION = 'REVISION',
  APPROVED = 'APPROVED',
}

export interface OnboardingDocument {
  key: string;
  filename: string;
  originalFilename: string;
  mimeType: string;
  size: number;
}

export enum DocumentTypeEnum {
  officialId = 'officialId',
  accountStatement = 'accountStatement',
  proofOfAddress = 'proofOfAddress',
  selfie = 'selfie',
  creditBureau = 'creditBureau',
  accountStatementConfirm = 'accountStatementConfirm',
}

export type OnboardingDocuments = Partial<Record<DocumentTypeEnum, OnboardingDocument>>;

export interface Account {
  fullname: string;
  clabe: string;
  accountNumber: string;
  bankName: string;
}

export interface Contract {
  contractVersion: string;
  termsConditionsVersion: string;
  /** Signature SVG */
  signature: string;
  signDate: string;
}

export enum OnboardingStatus {
  ACTIVE = 'ACTIVE',
  PRE_AUTHORIZED = 'PRE_AUTHORIZED',
  REVISION = 'REVISION',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  AUTHORIZED = 'AUTHORIZED',
  REQUEST_SIGNED = 'REQUEST_SIGNED',
  DEPOSITED = 'DEPOSITED',
}

export interface OnboardingRegisterRequest {
  email: string;
  amount: string;
  term: number;
  interestRate: number;
}

export interface OnboardingRegisterResponse extends MetaAttributes {
  id: string;
  email: string;
  loanRequest: LoanRequest;
  accessToken: string;
}

export interface OnboardingGetRegisterResponse extends PersonalInfo, MetaAttributes {
  email: string;
  loanRequest: LoanRequest;
  address?: Address;
  incomes?: Incomes;
  documents?: OnboardingDocuments;
  status?: OnboardingStatus;
}

export interface OnboardingUpsertPersonalInfoRequest extends PersonalInfo {}

export interface OnboardingUpsertPersonalInfoResponse extends PersonalInfo {}

export interface OnboardingUpsertAddressRequest extends Address {}

export interface OnboardingUpsertAddressResponse extends Address {}

export interface OnboardingUpsertIncomesRequest extends Incomes {}

export interface OnboardingUpsertIncomesResponse extends Incomes {}

export interface OnboardingGetPreauthorizationResponse {
  preauthorizationStatus: PreauthorizationStatus;
  status: OnboardingStatus;
  score: number;
  scoreDetail: {
    gender: number;
    age: number;
    incomes: number;
  };
  loanRequest: LoanRequest;
}

export type OnboardingUpsertDocumentsRequest = Record<
keyof OnboardingDocuments,
Pick<OnboardingDocument, 'filename' | 'mimeType' | 'size'>
>;

export interface OnboardingUpsertDocumentResponse {
  documents: OnboardingDocuments;
  signedUrls: Record<keyof OnboardingDocuments, string>;
}

export interface OnboardingUploadDocumentRequest {
  signedUrl: string;
  file: File;
}

export interface OnboardingUpsertAccountRequest extends Account {}

export interface OnboardingUpsertAccountResponse extends Account {}

export interface OnboardingUpsertContractRequest extends Omit<Contract, 'signDate'> {}

export interface OnboardingUpsertContractResponse extends Contract {}
