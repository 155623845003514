export default {
  es: {
    title: 'Cuéntanos sobre ti',
    titleWithName: 'Cuéntanos sobre ti, {{name}}',
    birthPlaceTitle: '¿Dónde naciste?',
    required: 'Este campo es requerido',
    minLength: 'Este campo debe contener al menos {{length}} caracteres',
    maxLength: 'Este campo debe contener máximo {{length}} caracteres',
    fields: {
      gender: {
        Mujer: 'Mujer',
        Hombre: 'Hombre',
      },
      firstname: {
        label: 'Nombre/s',
      },
      lastname: {
        label: 'Primer Apellido',
      },
      secondLastname: {
        label: 'Segundo Apellido',
      },
      country: {
        label: 'País',
      },
      state: {
        label: 'Estado',
      },
      birthdate: {
        label: 'Fecha de nacimiento',
        required: 'Este campo es requerido con una fecha válida',
        isAdult: 'Debes tener al menos 18 años',
      },
      curp: {
        label: 'CURP',
        pattern: 'Este campo debe tener un formato válido de CURP',
      },
      rfc: {
        label: 'RFC',
        pattern: 'Este campo debe tener un formato válido de RFC (Persona física)',
      },
      phone: {
        label: 'Teléfono (10 dígitos)',
        pattern: 'Este campo debe tener un formato válido de teléfono',
      },
    },
  },
};
