import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'moment/locale/es-mx';
import AppRoutes from './routes';
import getTheme from './theme';

const App: FC = () => {
  const theme = getTheme('dark');

  return (
    <LocalizationProvider dateAdapter={DateAdapter} locale="es-mx">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
