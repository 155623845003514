import {
  FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { OnboardingStatus, PreauthorizationStatus } from '@/api/onboarding/types';
import ROUTES from '@/constants/routes';
import useOnboardingStore from '../Onboarding/store';
import { MadeDecisionProps } from './components/MadeDecision';

const continueStatuses = [OnboardingStatus.PRE_AUTHORIZED, OnboardingStatus.REVISION];

const usePreauthorizationPage = () => {
  const [{
    email, firstname, loanRequest, incomes, status,
  }, actions] = useOnboardingStore();
  const navigate = useNavigate();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [preauthorizationStatus, setPreauthorizationStatus] = useState<MadeDecisionProps['status']>();

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (continueStatuses.includes(status as OnboardingStatus)) {
        navigate({ pathname: ROUTES.documents });
      } else {
        navigate({ pathname: ROUTES.root });
      }
    },
    [navigate, status],
  );

  useEffect(() => {
    const getPreauthorization = async () => {
      if (continueStatuses.includes(status as OnboardingStatus)) {
        setPreauthorizationStatus(
          status === OnboardingStatus.PRE_AUTHORIZED
            ? PreauthorizationStatus.APPROVED
            : status as MadeDecisionProps['status'],
        );
        return;
      }

      const start = performance.now();
      let result: MadeDecisionProps['status'];

      if (status === OnboardingStatus.ACTIVE) {
        const data = await actions.getPreauthorizationResponse();
        result = data.preauthorizationStatus;
      } else {
        result = status === OnboardingStatus.PRE_AUTHORIZED
          ? PreauthorizationStatus.APPROVED
          // eslint-disable-next-line max-len
          : status as MadeDecisionProps['status'];
      }

      const end = performance.now();
      const diff = end - start;

      timeoutRef.current = setTimeout(() => {
        setPreauthorizationStatus(result);
      }, Math.max(2000 - diff, 0));
    };

    if (incomes) {
      getPreauthorization();
    } else {
      navigate({ pathname: ROUTES.incomes });
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [actions, incomes, navigate, status]);

  return {
    preauthorizationStatus,
    firstname,
    email,
    amount: loanRequest?.amount as string,
    loading: !preauthorizationStatus,
    isRejected: preauthorizationStatus === 'REJECTED',
    handleSubmit,
  };
};

export default usePreauthorizationPage;
