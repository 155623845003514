export interface AmortizationInterestItem {
  value: number;
  match: (term: number) => boolean;
}

export interface Amortization {
  interestItems: AmortizationInterestItem[];
}

const amortization: Amortization = {
  interestItems: [
    {
      value: 0.6,
      match: (term) => term <= 6,
    },
    {
      value: 1,
      match: (term) => term > 6,
    },
  ],
};

export default amortization;
