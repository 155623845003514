import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  label: {
    fontSize: 28,
    whiteSpace: 'pre-line',
  },
  icon: {
    fontSize: 100,
    '& > .MuiSvgIcon-root': {
      display: 'block',
    },
  },
}));

export default useStyles;
