import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  amortizationLink: {
    textDecoration: 'underline',
    display: 'inline',
    cursor: 'pointer',
    background: 'none',
    margin: 0,
    padding: 0,
    outline: 'none',
    border: 'none',
    fontSize: theme.typography.body2.fontSize,
  },
}));

export default useStyles;
