import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Money: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M29.4811 30.2441C29.4811 31.9658 28.191 33.4162 26.4151 33.9351V26.553C28.191 27.0719 29.4811 28.5224 29.4811 30.2441ZM23.5849 23.2818C21.8113 22.7606 20.5189 21.3125 20.5189 19.5884C20.5189 17.8667 21.8113 16.4186 23.5849 15.8974V23.2818ZM26.5967 15.6757C28.1887 15.6757 29.4811 16.7865 29.4811 18.145C29.4811 18.9281 30.1156 19.5625 30.8962 19.5625C31.6792 19.5625 32.3113 18.9281 32.3113 18.145C32.3113 15.2252 29.7476 12.8479 26.5967 12.8479H26.4151V10.8479C26.4151 10.0648 25.783 9.43513 25 9.43513C24.2193 9.43513 23.5849 10.0648 23.5849 10.8479V12.9799C20.2288 13.5884 17.6887 16.3196 17.6887 19.5884C17.6887 22.8597 20.2288 25.5884 23.5849 26.1993V34.1097C21.8561 33.8856 20.5189 32.6026 20.5189 31.0436C20.5189 30.2606 19.8868 29.6285 19.1038 29.6285C18.3231 29.6285 17.6887 30.2606 17.6887 31.0436C17.6887 34.1521 20.2925 36.6828 23.5849 36.9375V39.1498C23.5849 39.9328 24.2193 40.5648 25 40.5648C25.783 40.5648 26.4151 39.9328 26.4151 39.1498V36.8549C29.7712 36.2441 32.3113 33.5153 32.3113 30.2441C32.3113 26.9729 29.7712 24.2441 26.4151 23.6332V15.6757H26.5967ZM25 47.171C12.7759 47.171 2.83019 37.2252 2.83019 24.9988C2.83019 12.7747 12.7759 2.82898 25 2.82898C37.2241 2.82898 47.1698 12.7747 47.1698 24.9988C47.1698 37.2252 37.2241 47.171 25 47.171ZM25 0.00115967C11.2146 0.00115967 0 11.2158 0 24.9988C0 38.7842 11.2146 49.9988 25 49.9988C38.7854 49.9988 50 38.7842 50 24.9988C50 11.2158 38.7854 0.00115967 25 0.00115967Z" />
    </SvgIcon>
  );
};

export default Money;
