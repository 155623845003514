import { Breakpoint } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ breakpoint?: Breakpoint }>()((theme, { breakpoint = 'lg' }) => ({
  root: {
    textAlign: 'center',
    [theme.breakpoints.up(breakpoint)]: {
      textAlign: 'left',
    },
  },
}));

export default useStyles;
