const accountDataConfig = {
  name: process.env.REACT_APP_ACCOUNT_CONFIG_NAME as string,
  account: process.env.REACT_APP_ACCOUNT_CONFIG_ACCOUNT as string,
  clabe: process.env.REACT_APP_ACCOUNT_CONFIG_CLABE as string,
  bankName: process.env.REACT_APP_ACCOUNT_CONFIG_BANKNAME as string,
  email: process.env.REACT_APP_ACCOUNT_CONFIG_EMAIL as string,
  phone: process.env.REACT_APP_ACCOUNT_CONFIG_PHONE as string,
  availability: process.env.REACT_APP_ACCOUNT_CONFIG_AVAILABILITY as string,
};

export default accountDataConfig;
