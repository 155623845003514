import { useEffect } from 'react';
import useOnboardingStore from '../Onboarding/store';

const useLandingPage = () => {
  const [{ id }, actions] = useOnboardingStore();

  useEffect(() => {
    if (id) {
      actions.reset();
    }
  }, [actions, id]);
};

export default useLandingPage;
