import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  content: {
    marginTop: theme.spacing(22),
  },
  footerTitle: {
    marginBottom: theme.spacing(3),
  },
  footerButton: {
    maxWidth: 360,
  },
  footer: {
    marginTop: theme.spacing(14),
  },
}));

export default useStyles;
