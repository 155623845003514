const regexPatterns = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  curp: /^[A-Z]{4}[0-9]{6}[A-Z]{6}[0-9]{2}$/,
  rfc: /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/,
  phone: /^\d{10}$/,
  postalCode: /^\d{5}$/,
  clabe: /^\d{18}$/,
  accountNumber: /^\d+$/,
};

export default regexPatterns;
