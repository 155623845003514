import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import despreocupateWideImg from '@/assets/images/despreocupate-wide.webp';
import disfrutaWideImg from '@/assets/images/disfruta-wide.webp';
import gustoWideImg from '@/assets/images/gusto-wide.webp';
import Footer from '@/components/Footer';
import InfoImgCard from '@/components/InfoImgCard';
import Link from '@/components/Link';
import ResponsiveTypography from '@/components/ResponsiveTypography';
import ROUTES from '@/constants/routes';
import ChatIcon from '@/icons/Chat';
import ContractIcon from '@/icons/Contract';
import MoneyIcon from '@/icons/Money';
import ThumbIcon from '@/icons/Thumb';
import Banner from '../components/Banner';
import SummaryItem from '../components/SummaryItem';
import useStyles from './styles';
import useLandingPage from '../useLandingPage';

const LandingPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'landing' });
  useLandingPage();

  const summaryItems = [
    {
      label: t('step1'),
      icon: <ChatIcon color="info" fontSize="inherit" />,
    },
    {
      label: t('step2'),
      icon: <MoneyIcon color="info" fontSize="inherit" />,
    },
    {
      label: t('step3'),
      icon: <ContractIcon color="info" fontSize="inherit" />,
    },
    {
      label: t('step4'),
      icon: <ThumbIcon color="info" fontSize="inherit" />,
    },
  ];

  const infoCards = [
    {
      label: t('imagesTitle1'),
      description: t('imagesText1'),
      imgSrc: gustoWideImg,
      alt: t('imagesTitle1'),
    },
    {
      label: t('imagesTitle2'),
      description: t('imagesText2'),
      imgSrc: despreocupateWideImg,
      alt: t('imagesTitle2'),
    },
    {
      label: t('imagesTitle3'),
      description: t('imagesText3'),
      imgSrc: disfrutaWideImg,
      alt: t('imagesTitle3'),
    },
  ];

  return (
    <>
      <Banner />

      <Container className={classes.content} fixed maxWidth="xl">
        <Grid container spacing={6} direction="column">
          {/* Summary items */}
          <Grid container item spacing={8} alignItems="center" justifyContent="center">
            <Grid item xl={4}>
              <ResponsiveTypography variant="h3">
                <Trans
                  t={t}
                  i18nKey="stepsTitle"
                  components={{
                    1: <Typography variant="h3" component="span" color="info.main" />,
                  }}
                />
              </ResponsiveTypography>
            </Grid>
            <Grid item container xl={8} spacing={2} rowSpacing={5}>
              {summaryItems.map(({ label, icon }) => (
                <Grid key={label} item container xs={12} sm={6} lg={3} justifyContent="center">
                  <SummaryItem label={label} icon={icon} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* InfoImgCard */}
          <Grid container item spacing={3} alignItems="center" justifyContent="center">
            {infoCards.map(({
              label, description, imgSrc, alt,
            }) => (
              <Grid key={label} item md={6} lg={4}>
                <InfoImgCard label={label} description={description} imgSrc={imgSrc} alt={alt} />
              </Grid>
            ))}
          </Grid>
          <Grid container item alignItems="center" direction="column">
            <Typography className={classes.footerTitle} variant="h4">
              {t('bottomStartTitle')}
            </Typography>
            <Link to={ROUTES.email}>
              <Button
                className={classes.footerButton}
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
              >
                {t('requestLoan')}
              </Button>
            </Link>
          </Grid>
          <Grid className={classes.footer} container item>
            <Footer showLogo />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LandingPage;
