import { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import ROUTES from '@/constants/routes';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import IncomesForm from '../components/IncomesForm';
import useIncomesPage from '../useIncomesPage';

const IncomesPage: FC = () => {
  const { form, firstname, handleFormSubmit } = useIncomesPage();

  return (
    <OnboardingStepsLayout
      steps={{
        personal: 'completed',
        location: 'completed',
        incomes: 'active',
      }}
      backButton={{
        link: ROUTES.location,
      }}
      continueButton={{
        type: 'submit',
        disabled: !form.formState.isValid || form.formState.isSubmitting,
      }}
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <IncomesForm name={firstname} />
      </FormProvider>
    </OnboardingStepsLayout>
  );
};

export default IncomesPage;
