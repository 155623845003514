import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
  },
  icon: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginBottom: theme.spacing(1),
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.completed': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  },
  label: {
    fontWeight: 700,
    fontStyle: 'italic',
  },
  checkContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
  },
}));

export default useStyles;
