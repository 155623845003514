import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(9),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(14),
    },
  },
  logo: {
    display: 'block',
    fontSize: 100,
    height: 50,
    width: 50,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
  },
  content: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 0),
    },
  },
  supportText: {
    marginBottom: theme.spacing(2),
  },
  link: {
    width: '100%',
    display: 'flex',
  },
  linkRight: {
    justifyContent: 'flex-end',
  },
  button: {
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
    },
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
