import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AvatarRadio from '@/components/AvatarRadio';
import FormTextField from '@/components/FormTextField';
import ResponsiveTypography from '@/components/ResponsiveTypography';
import regexPatterns from '@/constants/regexPatterns';
import states from '@/constants/states';
import useStyles from './styles';

export interface LocationFormData {
  livesInSame: 'yes' | 'no' | '';
  privateName: string;
  street: string;
  exterior: string;
  interior: string;
  colony: string;
  postalCode: string;
  municipality: string;
  state: string;
}

export interface LocationFormProps {
  name: string;
}

const LocationForm: FC<LocationFormProps> = ({ name = '' }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'location' });
  const { control, watch } = useFormContext();

  const livesInSame: 'yes' | 'no' | '' = watch('livesInSame');

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12} sm>
          <ResponsiveTypography className={classes.title} variant="h5" breakpoint="sm">
            {t('title', { name })}
          </ResponsiveTypography>
        </Grid>
        <Grid item container xs={12} sm="auto" justifyContent="center">
          <Controller
            name="livesInSame"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <RadioGroup
                  className={classes.livesInSame}
                  row
                  name="location-livesInSame"
                  value={value}
                  onChange={onChange}
                  onMouseUp={onBlur}
                >
                  <FormControlLabel
                    labelPlacement="top"
                    label=""
                    value="yes"
                    control={(
                      <AvatarRadio color="primary">
                        <Typography variant="body1" component="span">
                          {t('fields.livesInSame.yes')}
                        </Typography>
                      </AvatarRadio>
                    )}
                  />
                  <FormControlLabel
                    labelPlacement="top"
                    label=""
                    value="no"
                    control={(
                      <AvatarRadio color="primary">
                        <Typography variant="body1" component="span">
                          {t('fields.livesInSame.no')}
                        </Typography>
                      </AvatarRadio>
                    )}
                  />
                </RadioGroup>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      {livesInSame === 'yes' && (
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} sm="auto">
            <ResponsiveTypography>{t('privateName')}</ResponsiveTypography>
          </Grid>
          <Grid item xs={12} sm>
            <FormTextField
              name="privateName"
              control={control}
              rules={{
                required: t('required') as string,
                minLength: {
                  value: 2,
                  message: t('minLength', { length: 2 }),
                },
                maxLength: {
                  value: 30,
                  message: t('maxLength', { length: 30 }),
                },
              }}
              fullWidth
              label={t('fields.privateName.label')}
              variant="filled"
              color="secondary"
            />
          </Grid>
        </Grid>
      )}
      {livesInSame && (
        <>
          <Grid item xs={12} sm={5} lg={12} xl={5}>
            <FormTextField
              name="street"
              control={control}
              rules={{
                required: t('required') as string,
                minLength: {
                  value: 2,
                  message: t('minLength', { length: 2 }),
                },
                maxLength: {
                  value: 50,
                  message: t('maxLength', { length: 50 }),
                },
              }}
              fullWidth
              label={t('fields.street.label')}
              variant="filled"
              color="secondary"
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
          <Grid item xs={6} sm lg={6} xl>
            <FormTextField
              name="exterior"
              control={control}
              rules={{
                required: t('required') as string,
                maxLength: {
                  value: 20,
                  message: t('maxLength', { length: 20 }),
                },
              }}
              fullWidth
              label={t('fields.exterior.label')}
              variant="filled"
              color="secondary"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={6} sm lg={6} xl>
            <FormTextField
              name="interior"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: t('maxLength', { length: 20 }),
                },
              }}
              fullWidth
              label={t('fields.interior.label')}
              variant="filled"
              color="secondary"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="colony"
              control={control}
              rules={{
                required: t('required') as string,
                minLength: {
                  value: 2,
                  message: t('minLength', { length: 2 }),
                },
                maxLength: {
                  value: 50,
                  message: t('maxLength', { length: 50 }),
                },
              }}
              fullWidth
              label={t('fields.colony.label')}
              variant="filled"
              color="secondary"
              disabled={livesInSame === 'yes'}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="postalCode"
              control={control}
              rules={{
                required: t('required') as string,
                pattern: {
                  value: regexPatterns.postalCode,
                  message: t('fields.postalCode.pattern'),
                },
              }}
              fullWidth
              label={t('fields.postalCode.label')}
              variant="filled"
              color="secondary"
              disabled={livesInSame === 'yes'}
              inputProps={{
                maxLength: 5,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="municipality"
              control={control}
              rules={{
                required: t('required') as string,
                minLength: {
                  value: 2,
                  message: t('minLength', { length: 2 }),
                },
                maxLength: {
                  value: 50,
                  message: t('maxLength', { length: 50 }),
                },
              }}
              fullWidth
              label={t('fields.municipality.label')}
              variant="filled"
              color="secondary"
              disabled={livesInSame === 'yes'}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="state"
              control={control}
              rules={{
                required: t('required') as string,
                minLength: {
                  value: 2,
                  message: t('minLength', { length: 2 }),
                },
                maxLength: {
                  value: 40,
                  message: t('maxLength', { length: 40 }),
                },
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Autocomplete
                  freeSolo
                  disableClearable
                  options={states}
                  value={value}
                  disabled={livesInSame === 'yes'}
                  onInputChange={(_event, v) => {
                    onChange(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      label={t('fields.state.label')}
                      variant="filled"
                      color="secondary"
                      error={!!error}
                      helperText={error?.message}
                      onBlur={onBlur}
                      inputProps={{
                        ...params.inputProps,
                        maxLength: 40,
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LocationForm;
