export default {
  es: {
    title: '¿Vives en Zibatá, {{name}}?',
    privateName: 'Nombre de tu privada',
    required: 'Este campo es requerido',
    minLength: 'Este campo debe contener al menos {{length}} caracteres',
    maxLength: 'Este campo debe contener máximo {{length}} caracteres',
    fields: {
      livesInSame: {
        yes: 'Sí',
        no: 'No',
      },
      privateName: {
        label: 'Nombre privada',
      },
      street: {
        label: 'Calle',
      },
      exterior: {
        label: 'No. Exterior',
      },
      interior: {
        label: 'No. Interior',
      },
      colony: {
        label: 'Colonia',
      },
      postalCode: {
        label: 'CP',
        pattern: 'Este campo debe contener un código postal válido',
      },
      municipality: {
        label: 'Alcaldía/Municipio',
      },
      state: {
        label: 'Estado',
      },
    },
  },
};
