const quoter = {
  amount: {
    defaultValue: 9000,
    min: 3000,
    max: 15000,
    sliderStep: 100,
    buttonStep: 500,
  },
  term: {
    defaultValue: 12,
    min: 1,
    max: 12,
    sliderStep: 1,
    buttonStep: 1,
  },
};

export default quoter;
