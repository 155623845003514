import { useEffect, useRef, useState } from 'react';

import api from '@/api';

const useApiInterceptor = <T = unknown>(
  onFulfilled?: (value: unknown) => T | Promise<T>,
  onRejected?: (error: any) => any,
) => {
  const interceptorId = useRef<number>();
  const [loaded, setLoaded] = useState(false);
  const onFulfilledRef = useRef(onFulfilled);
  const onRejectedRef = useRef(onRejected);

  useEffect(() => {
    onFulfilledRef.current = onFulfilled;
  }, [onFulfilled]);

  useEffect(() => {
    onRejectedRef.current = onRejected;
  }, [onRejected]);

  useEffect(() => {
    interceptorId.current = api.interceptors.response.use(
      onFulfilledRef.current,
      onRejectedRef.current,
    );
    setLoaded(true);

    return () => {
      if (typeof interceptorId.current === 'number') {
        api.interceptors.response.eject(interceptorId.current);
      }
    };
  }, []);

  return {
    loaded,
  };
};

export default useApiInterceptor;
