import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Wallet: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M47.1707 20.885H34.8218C31.4115 20.885 28.6403 23.6585 28.6403 27.0688V31.0404C28.6403 34.4484 31.4115 37.2243 34.8218 37.2243H47.1707V37.9153C47.1707 43.019 42.9514 47.1699 37.7699 47.1699H12.2326C7.04875 47.1699 2.82949 43.019 2.82949 37.9153V20.1939C2.82949 15.0903 7.04875 10.9394 12.2326 10.9394H37.7699C42.9514 10.9394 47.1707 15.0903 47.1707 20.1939V20.885ZM47.1707 34.3941H34.8218C32.9728 34.3941 31.4681 32.8895 31.4681 31.0404V27.0688C31.4681 25.2198 32.9728 23.7151 34.8218 23.7151H47.1707V34.3941ZM32.6332 2.95133C33.0483 2.78624 33.5011 2.79095 33.9162 2.9702C34.3313 3.14944 34.6497 3.48434 34.8218 3.93008L36.5105 8.10925H20.0438L32.6332 2.95133ZM39.6401 8.29557L37.4562 2.89001C37.0128 1.75088 36.1567 0.857026 35.0412 0.373544C33.928 -0.109937 32.6969 -0.124088 31.5672 0.331092L12.5746 8.10925H12.2326C5.48746 8.10925 0.00170898 13.529 0.00170898 20.1939V37.9153C0.00170898 44.5779 5.48746 50 12.2326 50H37.7699C44.5127 50 49.9984 44.5779 49.9984 37.9153V20.1939C49.9984 14.1634 45.4914 9.19178 39.6401 8.29557Z" />
    </SvgIcon>
  );
};

export default Wallet;
