export default {
  es: {
    startTitle: '¡Felicidades, {{name}}! \nTu préstamo autorizado es de {{amount}}',
    startText:
      'Ingresa los datos de tu cuenta bancaria donde depositaremos el monto aprobado y firma bajo protesta de decir verdad que los datos señalados y remitidos son veraces.',
    clean: 'Limpiar',
    required: 'Este campo es requerido',
    selectFile: 'Seleccionar archivo',
    fields: {
      fullname: {
        label: 'Nombre completo del titular de la cuenta',
        pattern: 'El correo electrónico debe tener un formato válido',
      },
      clabe: {
        label: 'Clabe interbancaria',
        pattern: 'La clabe interbancaria debe contener 18 digitos',
      },
      accountNumber: {
        label: 'No. de Cuenta',
        pattern: 'El número de cuenta debe contener solo números',
      },
      bankName: {
        label: 'Institución bancaria',
      },
      accountStatement: {
        label: 'Último estado de cuenta.',
        caption: 'Procura que sea legible y que tu nombre aparezca en él (PDF, JPG o PNG).',
      },
      signature: {
        label: 'Firma aquí',
      },
      tnc: {
        label: 'He leído y acepto los términos y condiciones mostrados en el Aviso de Privacidad',
        required: 'Debes aceptar los términos y condiciones',
      },
    },
    finishedTitle: '¡Enhorabuena, {{name}}! Ya solo hace falta firmar tu contrato.',
    finishedText: 'En breve recibirás un correo para firmar tu contrato, posterior a eso los recursos serán depositados en tu cuenta en un lapso máximo de 24 horas.',
    text: `<1>Recuerda ser <2>PUNTUAL</2> en tus pagos conforme a la tabla de amortización a fin de no verte afectado en buró de crédito y tus redes sociales!</1>
<1>Descarga tu tabla de amortización <3>aquí.</3></1>
<1>Te proporcionamos la cuenta en la que deben ser depositados tus pagos de forma mensual, a más tardar el día <2>{{day}} de cada mes</2>, así como la referencia que deberás poner en cada pago que efectúes. Te recordamos que es muy importante que contenga la misma referencia, de lo contrario tus pagos no serán aplicados.</1>`,
    account: 'Cuenta',
    clabe: 'Clabe',
    holder: 'Titular',
    bank: 'Instirución Financiera',
    reference: 'Referencia',
    helperText: 'Al momento de que envíes tu solicitud te llegara un correo electrónico para que nos apoyes con la firma del contrato.',
    supportText:
      'Si no ves reflejado el recurso o tienes alguna duda, favor de comunicarte al correo <1>{{email}}</1>, o vía whatsapp al número {{phone}} en un horario de {{availability}}.',
  },
};
