import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import useStyles from './styles';

const CenterLoader: FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress color="primary" size={80} />
    </Box>
  );
};

export default CenterLoader;
