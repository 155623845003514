import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import tncConstants from '@/constants/tnc';
import LogoIcon from '@/icons/Logo';
import ResponsiveTypography from '../ResponsiveTypography';
import useStyles from './styles';

export interface FooterProps {
  showLogo?: boolean;
  className?: string;
}

const Footer: FC<FooterProps> = ({ className, showLogo = false }) => {
  const { classes, cx } = useStyles();

  return (
    <Grid
      className={cx(className)}
      component="footer"
      container
      justifyContent="center"
      alignItems="center"
    >
      {showLogo && <LogoIcon className={classes.logo} />}
      <ResponsiveTypography variant="caption" component="span">
        <Trans
          i18nKey="footer.legalText"
          components={{
            1: <Link href={tncConstants.fileUrl} target="_blank" underline="always" />,
            2: <u />,
          }}
        />
      </ResponsiveTypography>
    </Grid>
  );
};

export default Footer;
