import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  logo: {
    display: 'block',
    fontSize: 100,
    height: 50,
  },
  leftContainer: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      borderRadius: 0,
      height: '100vh',
      width: '50%',
      position: 'fixed',
      overflow: 'auto',
      top: 0,
      left: 0,
    },
  },
  rightContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    paddingBottom: theme.spacing(6),
    marginBottom: theme.spacing(-6),
    [theme.breakpoints.up('lg')]: {
      minHeight: '100vh',
      marginLeft: '50%',
      width: '50%',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
}));

export default useStyles;
