import Button, { ButtonProps } from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import MuiLink from '@mui/material/Link';
import { FC, FormEventHandler, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import DashedDivider from '@/components/DashedDivider';
import Link from '@/components/Link';
import ResponsiveTypography from '@/components/ResponsiveTypography';
import accountDataConfig from '@/config/accountData';
import LogoSmall from '@/icons/LogoSmall';
import OnboardingSteps, { OnboardingStepsProps } from '../OnboardingSteps';
import useStyles from './styles';

interface OnboardingStepsLayoutButtonProps {
  label?: string;
  link?: string;
  disabled?: boolean;
  type?: ButtonProps['type'];
}

const OnboardingStepsLayoutButton: FC<
OnboardingStepsLayoutButtonProps &
Pick<ButtonProps, 'className' | 'color' | 'variant' | 'startIcon' | 'endIcon' | 'type'>
> = ({
  label,
  link,
  disabled,
  className,
  color = 'primary',
  variant = 'contained',
  startIcon,
  endIcon,
  type,
}) => {
  const { classes, cx } = useStyles();

  const buttonContent = (
    <Button
      className={classes.button}
      variant={variant}
      color={color}
      type={type}
      fullWidth
      size="large"
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  return link && !disabled ? (
    <Link className={cx(classes.link, className)} to={link}>
      {buttonContent}
    </Link>
  ) : (
    buttonContent
  );
};

export interface OnboardingStepsLayoutProps {
  title?: string;
  steps?: OnboardingStepsProps;
  supportText?: boolean | ReactNode;
  showDivider?: boolean;
  backButton?: (OnboardingStepsLayoutButtonProps & { hideIcon?: boolean }) | false;
  continueButton?: (OnboardingStepsLayoutButtonProps & { hideIcon?: boolean }) | false;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

const OnboardingStepsLayout: FC<OnboardingStepsLayoutProps> = ({
  children,
  title,
  steps,
  supportText = true,
  showDivider = true,
  backButton,
  continueButton,
  onSubmit,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'onboarding' });
  const { classes } = useStyles();

  return (
    <Container component="form" className={classes.root} maxWidth="md" onSubmit={onSubmit}>
      <Grid
        container
        rowSpacing={2}
        direction={{
          lg: 'row-reverse',
        }}
      >
        <Grid
          item
          display={{
            lg: 'none',
          }}
        >
          <LogoSmall color="primary" className={classes.logo} />
        </Grid>

        {steps && (
          <Grid item container xs lg={7} justifyContent="flex-end">
            <OnboardingSteps
              personal={steps.personal}
              location={steps.location}
              incomes={steps.incomes}
            />
          </Grid>
        )}

        <Grid item xs={12} lg={steps ? 5 : 12}>
          <ResponsiveTypography className={classes.title} variant="h3">
            {title || t('title')}
          </ResponsiveTypography>
        </Grid>
      </Grid>

      <Grid container className={classes.content}>
        {children}
      </Grid>

      {supportText && (
        <Grid className={classes.supportText} container>
          <ResponsiveTypography variant="body2" color="text.secondary">
            {typeof supportText !== 'boolean' ? supportText : supportText && (
            <Trans
              i18nKey="footer.supportText"
              values={{
                email: accountDataConfig.email,
                phone: accountDataConfig.phone,
                availability: accountDataConfig.availability,
              }}
              components={{
                1: (
                  <MuiLink href={`mailto:${accountDataConfig.email}`} underline="always" target="_blank" />
                ),
              }}
            />
            )}
          </ResponsiveTypography>
        </Grid>
      )}

      {showDivider && <DashedDivider className={classes.divider} />}

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          {backButton && (
            <OnboardingStepsLayoutButton
              label={backButton.label || t('return')}
              link={backButton.link}
              disabled={backButton.disabled}
              variant="outlined"
              startIcon={
                !backButton.hideIcon && (
                  <Icon baseClassName="fas" className="fa-angle-left" color="inherit" />
                )
              }
              type={backButton.type}
            />
          )}
        </Grid>

        {continueButton && (
          <Grid item container xs={6} justifyContent="flex-end">
            <OnboardingStepsLayoutButton
              className={classes.linkRight}
              label={continueButton.label || t('continue')}
              link={continueButton.link}
              disabled={continueButton.disabled}
              endIcon={
                !continueButton.hideIcon && (
                  <Icon baseClassName="fas" className="fa-angle-right" color="inherit" />
                )
              }
              type={continueButton.type}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default OnboardingStepsLayout;
