import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Pin: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M46.0155 48.4317C45.3056 49.4128 44.1618 50 42.9519 50H7.04798C5.83813 50 4.69431 49.4128 3.98444 48.4317C3.2675 47.4364 3.07883 46.1959 3.4656 45.0333L6.61168 35.5974C7.1258 34.055 8.56206 33.0173 10.1917 33.0173H15.5664C16.3471 33.0173 16.9791 33.6517 16.9791 34.4323C16.9791 35.2153 16.3471 35.8497 15.5664 35.8497H10.1917C9.78605 35.8497 9.42522 36.1068 9.29787 36.4936L6.15179 45.9271C6.0551 46.2172 6.09991 46.5261 6.27915 46.7761C6.46074 47.0261 6.74139 47.1676 7.04798 47.1676H42.9519C43.2585 47.1676 43.5392 47.0261 43.7184 46.7761C43.9 46.5261 43.9448 46.2172 43.8481 45.9271L40.702 36.4936C40.5723 36.1068 40.2139 35.8497 39.8082 35.8497H34.4335C33.6528 35.8497 33.0184 35.2153 33.0184 34.4323C33.0184 33.6517 33.6528 33.0173 34.4335 33.0173H39.8082C41.4379 33.0173 42.8741 34.055 43.3882 35.5997L46.5343 45.0309C46.9211 46.1959 46.7324 47.4364 46.0155 48.4317ZM24.9999 2.83242C30.7213 2.83242 35.3767 7.4855 35.3767 13.2069C35.3767 18.9307 30.7213 23.5838 24.9999 23.5838C19.2785 23.5838 14.6207 18.9307 14.6207 13.2069C14.6207 7.4855 19.2785 2.83242 24.9999 2.83242ZM23.5849 26.336V40.5736C23.5849 41.3565 24.2193 41.9909 25 41.9909C25.7806 41.9909 26.4126 41.3565 26.4126 40.5736V26.336C33.0326 25.6285 38.2045 20.0132 38.2045 13.2069C38.2045 5.92661 32.2826 0 25 0C17.7173 0 11.793 5.92661 11.793 13.2069C11.793 20.0132 16.9673 25.6285 23.5849 26.336Z" />
    </SvgIcon>
  );
};

export default Pin;
