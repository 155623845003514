import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Female: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M25.0011 31.3438C17.1044 31.3438 10.6829 24.9178 10.6829 17.0233C10.6829 9.12654 17.1044 2.70058 25.0011 2.70058C32.8979 2.70058 39.3216 9.12654 39.3216 17.0233C39.3216 24.9178 32.8979 31.3438 25.0011 31.3438ZM42.0244 17.0233C42.0244 7.63548 34.3889 0 25.0011 0C15.6133 0 7.97559 7.63548 7.97559 17.0233C7.97559 25.9539 14.8926 33.301 23.6497 33.9925V39.3216H17.0233C16.2778 39.3216 15.6696 39.9275 15.6696 40.673C15.6696 41.4185 16.2778 42.0222 17.0233 42.0222H23.6497V48.6508C23.6497 49.3964 24.2533 50 25.0011 50C25.7489 50 26.3525 49.3964 26.3525 48.6508V42.0222H32.979C33.7245 42.0222 34.3304 41.4185 34.3304 40.673C34.3304 39.9275 33.7245 39.3216 32.979 39.3216H26.3525V33.9925C35.1097 33.301 42.0244 25.9539 42.0244 17.0233Z" />
    </SvgIcon>
  );
};

export default Female;
