export default {
  es: {
    title: 'Por favor, sube una copia de los siguientes documentos:',
    formCaption:
      '*Tendras que subir todos los documentos solicitados debajo, de lo contrario no podrás continuar con el proceso.',
    required: 'Este campo es requerido',
    fields: {
      officialId: {
        label: 'Identificación oficial.',
        caption:
          'Asegurate de subir ambos lados de tu identificación (INE, pasaporte o cédula profesional).',
      },
      accountStatement: {
        label: 'Último estado de cuenta.',
        caption: 'Procura que sea legible y que tu nombre aparezca en él (PDF, JPG o PNG).',
      },
      accountStatementOrBureau: {
        label: 'Últimos estados de cuenta o reporte de Buró de Crédito.',
        caption:
          'Una copia de tus últimos 2 estados de cuenta o del reporte de Buró de Crédito no mayor a 3 meses de antigüedad. Procura que sea legible y que tu nombre aparezca en él (PDF, JPG o PNG).',
      },
      proofOfAddress: {
        label: 'Comprobante de domicilio.',
        caption: 'No mayor a 3 meses de antigüedad.',
      },
      selfie: {
        label: 'Foto tipo selfie.',
        caption:
          'Procura que haya suficiente luz en el ambiente y que tu rostro descubierto (sin cubrebocas, lentes, capucha, etc.)',
      },
      creditBureau: {
        label: 'Buró de crédito.',
        caption: 'No mayor a 3 meses de antigüedad.',
      },
    },
    selectFile: 'Seleccionar archivo',
    allowed: 'Formatos aceptados: {{formats}}. Peso max. {{size}}.',
    maxSizeError: 'El archivo debe pesar maximo {{size}}.',
    uploadedTitle: 'Gracias {{name}}. Hemos recibido tus documentos.',
    uploadedText:
      'El número de solicitud es el <1>{{folio}}</1>. En un lapso máximo de 48 horas sabrás si tu crédito fue autorizado.',
  },
};
