import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SliderControl from '@/components/SliderControl';
import quoter from '@/constants/quoter';
import ROUTES from '@/constants/routes';
import currencyFormat from '@/utils/currencyFormat';
import useStyles from './styles';
import useQuoterCard from './useQuoterCard';

export type LoanButtonType = 'decrease' | 'increase';

const QuoterCard: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'landing' });
  const {
    amount, term, monthlyPayment, loading, handleAmountChange, handleTermChange,
  } = useQuoterCard();

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Grid container rowSpacing={3} direction="column" alignItems="stretch">
          <Grid item>
            <Typography variant="h4" gutterBottom>
              {t('quoterTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <SliderControl
              value={amount}
              textValue={currencyFormat(amount)}
              size="medium"
              min={quoter.amount.min}
              max={quoter.amount.max}
              step={quoter.amount.sliderStep}
              onChange={handleAmountChange}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.termsTitle} variant="body1" component="p">
              {t('quoterTerm')}
            </Typography>
            <SliderControl
              value={term}
              size="small"
              min={quoter.term.min}
              max={quoter.term.max}
              step={quoter.term.sliderStep}
              onChange={handleTermChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="p" gutterBottom>
              {t('quoterPaymentText')}
            </Typography>
            <Typography
              className={classes.monthlyPayment}
              variant="h4"
              component="div"
              color="success.main"
            >
              {loading ? (
                <CircularProgress data-testid="loadingPayment" size={40} color="success" />
              ) : (
                currencyFormat(monthlyPayment)
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Link to={ROUTES.email} fullWidth>
          <Button fullWidth variant="contained" color="secondary" size="large">
            {t('requestNow')}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default QuoterCard;
