import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Gender } from '@/api/onboarding/types';
import ROUTES from '@/constants/routes';
import useOnboardingStore from '@/modules/Onboarding/store';
import { PersonalInfoFormData } from './components/PersonalInfoForm';

const usePersonalInfo = () => {
  const [
    {
      gender, firstname, lastname, secondLastname, country, state, birthdate, curp, rfc, phone,
    },
    actions,
  ] = useOnboardingStore();
  const navigate = useNavigate();

  const form = useForm<PersonalInfoFormData>({
    defaultValues: {
      gender: (gender || '') as PersonalInfoFormData['gender'],
      firstname,
      lastname,
      secondLastname,
      country,
      state,
      birthdate: birthdate ? new Date(`${birthdate}T12:00:00`) : undefined,
      curp,
      rfc,
      phone,
    },
    mode: 'onTouched',
  });
  const formFirstname = form.watch('firstname');

  const handleFormSubmit = async (data: PersonalInfoFormData) => {
    await actions.updatePersonalInfo({
      gender: Gender[data.gender as Gender],
      firstname: data.firstname,
      lastname: data.lastname,
      secondLastname: data.secondLastname,
      country: data.country,
      state: data.state,
      birthdate: moment(data.birthdate).format('YYYY-MM-DD'),
      curp: data.curp,
      rfc: data.rfc,
      phone: data.phone,
    });
    navigate({ pathname: ROUTES.location });
  };

  useEffect(() => {
    form.trigger('gender');
  }, [form]);

  return {
    form,
    name: formFirstname,
    handleFormSubmit,
  };
};

export default usePersonalInfo;
