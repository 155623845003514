export default {
  es: {
    confirmation:
      'Tu solicitud se hará por la cantidad de <1>{{amount}}</1> a un plazo de <1>{{term}}</1> meses. Si deseas cambiar esto, por favor <2>regresa al inicio</2>.',
    noticeOfPrivacy:
      'Para <1>QRETEK</1> es muy importante que conozcas cómo manejamos tus datos personales, por lo que antes de continuar, te pedimos revisar nuestro <2>Aviso de Privacidad</2>.',
    fields: {
      email: {
        label: 'Correo electrónico',
        required: 'Este campo es requerido',
        pattern: 'El correo electrónico debe tener un formato válido',
      },
      tnc: {
        label: 'He leído y acepto los términos y condiciones mostrados en el Aviso de Privacidad.',
        required: 'Debes aceptar los términos y condiciones',
      },
    },
  },
};
