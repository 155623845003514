import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import OnboardingCarouselLayout from './OnboardingCarouselLayout';
import useStyles from './styles';

export interface OnboardingLayoutProps {}

const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      direction={{
        xs: 'column-reverse',
        xl: 'row',
      }}
      justifyContent={{
        xs: 'flex-end',
        lg: 'flex-start',
      }}
    >
      <Grid className={classes.leftContainer} item>
        <OnboardingCarouselLayout />
      </Grid>

      <Grid className={classes.rightContainer} item>
        {children || <Outlet />}
      </Grid>
    </Grid>
  );
};

export default OnboardingLayout;
