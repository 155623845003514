import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import placeConfig from '@/config/place';
import ROUTES from '@/constants/routes';
import useOnboardingStore from '@/modules/Onboarding/store';
import mapYesNoValue from '@/utils/mapYesNoValue';
import { LocationFormData } from './components/LocationForm';

const useLocationPage = () => {
  const navigate = useNavigate();
  const [{ firstname, address }, actions, dispatch] = useOnboardingStore();
  const form = useForm<LocationFormData>({
    defaultValues: {
      livesInSame: mapYesNoValue(address && address.privateName !== ''),
      privateName: address?.privateName || '',
      street: address?.street || '',
      exterior: address?.exterior || '',
      interior: address?.interior || '',
      colony: address?.colony || '',
      postalCode: address?.postalCode || '',
      municipality: address?.municipality || '',
      state: address?.state || '',
    },
    mode: 'onTouched',
    shouldUnregister: true,
  });

  const handleFormSubmit = async (data: LocationFormData) => {
    await actions.updateAddress(data);
    navigate({ pathname: ROUTES.incomes });
  };

  useEffect(() => {
    if (!firstname) {
      navigate({ pathname: ROUTES.personalInfo });
    }
  }, [firstname, navigate]);

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name === 'livesInSame' && type === 'change') {
        if (value.livesInSame === 'yes') {
          form.setValue('colony', placeConfig.colony);
          form.setValue('postalCode', placeConfig.postalCode);
          form.setValue('municipality', placeConfig.municipality);
          form.setValue('state', placeConfig.state);
        } else {
          form.setValue('colony', address?.colony || '');
          form.setValue('postalCode', address?.postalCode || '');
          form.setValue('municipality', address?.municipality || '');
          form.setValue('state', address?.state || '');
        }

        form.trigger(['colony', 'postalCode', 'municipality', 'state']);
      }

      if (
        name
        && ['colony', 'postalCode', 'municipality', 'state'].includes(name)
        && value.livesInSame === 'no'
      ) {
        dispatch({
          type: 'UPDATE_ADDRESS',
          payload: {
            [name]: value[name],
          },
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    address?.colony,
    address?.municipality,
    address?.postalCode,
    address?.state,
    dispatch,
    form,
    form.watch,
  ]);

  return {
    form,
    firstname,
    handleFormSubmit,
  };
};

export default useLocationPage;
