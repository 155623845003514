import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fileUploadContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  termsCaption: {
    fontSize: 15,
    lineHeight: '120%',
  },
  signatureContainer: {
    position: 'relative',
    width: '100%',
    height: 250,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
  },
  clearButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 12,
  },
  signature: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
