import TextField, { TextFieldProps } from '@mui/material/TextField';
import currencyFormat, {
  CurrencyFormatOptions,
  defaultCurrencyFormatOptions,
} from '@/utils/currencyFormat';
import {
  ChangeEvent, ForwardedRef, forwardRef, KeyboardEvent, UIEvent, useState,
} from 'react';

export interface CurrencyTextFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  formatOptions?: CurrencyFormatOptions;
  allowNegatives?: boolean;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => void;
}

const CurrencyTextField = (
  {
    value = '',
    allowNegatives = false,
    formatOptions = {},
    onChange = () => null,
    ...textFieldProps
  }: CurrencyTextFieldProps,
  ref?: ForwardedRef<HTMLDivElement>,
) => {
  const options = { ...defaultCurrencyFormatOptions, ...formatOptions };
  const numberValue = Number(value);
  const finalValue = Number.isNaN(numberValue) ? 0 : Math.floor(numberValue);
  const absValue = Math.abs(finalValue);
  const [factor, setFactor] = useState(absValue === 0 ? 1 : absValue / finalValue);
  const divide = 10 ** (options.minimumFractionDigits || 0);

  const isNotEmpty = typeof value === 'number' || (typeof value === 'string' && value.trim() !== '');

  const formattedValue = isNotEmpty
    ? `${currencyFormat((absValue / divide) * factor, options)}`
    : '';

  const handleValueChange = (event: UIEvent<HTMLDivElement>, v: string) => {
    const sendEvent = event as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    sendEvent.target.value = v;
    sendEvent.currentTarget.value = v;
    onChange(sendEvent, v);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace') {
      if (absValue === 0) {
        if (factor === -1) {
          setFactor(1);
        }

        handleValueChange(event, '');
        return;
      }

      const nValue = +`${absValue}`.slice(0, -1);
      handleValueChange(event, absValue <= 1 ? '0' : `${nValue * factor}`);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (allowNegatives && event.key === '-') {
      setFactor(-factor);
      handleValueChange(event, `${absValue * -factor}`);
      return;
    }

    if (+event.key >= 0 && +event.key <= 9) {
      const nValue = +`${absValue}${event.key}`;
      handleValueChange(event, `${nValue * factor}`);
    }
  };

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...textFieldProps}
      ref={ref}
      value={formattedValue}
      onChange={() => null}
      onKeyPress={handleKeyPress}
      onKeyDown={handleKeyDown}
    />
  );
};

export const CurrencyTextFieldFormatter = (
  value?: string | number,
  formatOptions: CurrencyFormatOptions = defaultCurrencyFormatOptions,
): string => {
  const numberValue = Number(value);
  const finalValue = Number.isNaN(numberValue) ? 0 : Math.floor(numberValue);
  const absValue = Math.abs(finalValue);
  const factor = absValue === 0 ? 1 : absValue / finalValue;
  const divide = 10 ** (formatOptions.minimumFractionDigits || 0);

  return `${currencyFormat((absValue / divide) * factor, formatOptions)}`;
};

export default forwardRef(CurrencyTextField);
