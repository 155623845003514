import ResponsiveTypography from '@/components/ResponsiveTypography';
import { FC } from 'react';
import useStyles from './styles';

export interface StepsListProps {
  label: string;
  items: { label: string }[];
}

const StepsList: FC<StepsListProps> = ({ label, items }) => {
  const { classes } = useStyles();

  return (
    <>
      <ResponsiveTypography className={classes.label} variant="h5">
        {label}
      </ResponsiveTypography>
      {items.length > 0 && (
        <ol className={classes.list}>
          {items.map((item) => (
            <li key={item.label}>
              <ResponsiveTypography breakpoint="md">{item.label}</ResponsiveTypography>
            </li>
          ))}
        </ol>
      )}
    </>
  );
};

export default StepsList;
