import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  item: {
    width: '100%',
    height: '100%',
  },
  indicators: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: theme.spacing(4),
    zIndex: 99,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  indicatorItem: {
    padding: 0,
    minWidth: 0,
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(2),
    border: '1px solid #A4DFFF',
    '&.active': {
      backgroundColor: '#A4DFFF',
    },
  },
  container: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
