import Avatar from '@mui/material/Avatar';
import { RadioProps } from '@mui/material/Radio';
import { useRadioGroup } from '@mui/material/RadioGroup';
import { createChainedFunction } from '@mui/material/utils';
import {
  ChangeEvent, ChangeEventHandler, FC, MouseEvent, ReactNode,
} from 'react';
import useStyles from './styles';

export interface AvatarRadioProps extends RadioProps {
  children: ReactNode;
  variant?: 'filled' | 'outlined';
}

const areEqualValues = (a: unknown, b: unknown) => {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  // The value could be a number, the DOM will stringify it anyway.
  return String(a) === String(b);
};

const AvatarRadio: FC<AvatarRadioProps> = ({
  children,
  name: nameProp,
  checked: checkedProp,
  onChange: onChangeProp = () => null,
  color,
  onClick = () => null,
  value,
  variant = 'filled',
  ...radioProps
}) => {
  const { classes, cx } = useStyles({ color });
  const radioGroup = useRadioGroup();
  const onChange = createChainedFunction(
    onChangeProp,
    radioGroup?.onChange as ChangeEventHandler<HTMLInputElement>,
  );
  let checked = checkedProp;
  let name = nameProp;

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = areEqualValues(radioGroup.value, value);
    }

    if (typeof name === 'undefined') {
      name = radioGroup.name;
    }
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    onClick(event as unknown as MouseEvent<HTMLButtonElement>);
    const changeEvent = event as unknown as ChangeEvent<HTMLInputElement>;
    changeEvent.target.value = value as string;
    onChange(changeEvent, true);
  };

  return (
    <Avatar
      data-testid={(radioProps as any)['data-testid']}
      className={cx(classes.root, classes[variant], checked && 'checked')}
      onClick={handleClick}
    >
      {children}
    </Avatar>
  );
};

export default AvatarRadio;
