import Check from '@/icons/Check';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, ReactNode } from 'react';
import useStyles from './styles';

export type StepsItemState = 'none' | 'active' | 'completed';
export interface StepsItemProps {
  label: string;
  icon: ReactNode;
  state?: StepsItemState;
  className?: string;
}

const StepsItem: FC<StepsItemProps> = ({
  label, icon, state = 'none', className,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx(classes.root, className)}>
      <Avatar className={cx(classes.icon, state)}>
        {icon}
      </Avatar>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
      {state === 'completed' && (
        <Box className={classes.checkContainer}>
          <Check color="primary" fontSize="inherit" />
        </Box>
      )}
    </Box>
  );
};

export default StepsItem;
