import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2),
    },
  },
  logo: {
    display: 'block',
    fontSize: 100,
    height: 50,
  },
  middleContent: {
    flexGrow: 1,
    overflow: 'hidden',
    '&.MuiGrid-item': {
      maxWidth: 480,
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  carouselContainer: {
    flexGrow: 1,
    minHeight: 270,
    maxHeight: 618,
    position: 'relative',
  },
  carousel: {
    position: 'absolute',
  },
  footer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default useStyles;
