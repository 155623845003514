import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import StepsItem, { StepsItemState } from '@/components/StepItem';
import PinIcon from '@/icons/Pin';
import UserIcon from '@/icons/User';
import WalletIcon from '@/icons/Wallet';
import useStyles from './styles';

export type OnboardingStepState = 'active' | 'completed';

export interface OnboardingStepsProps {
  personal?: StepsItemState;
  location?: StepsItemState;
  incomes?: StepsItemState;
}

const OnboardingSteps: FC<OnboardingStepsProps> = ({ personal, location, incomes }) => {
  const { t } = useTranslation('', { keyPrefix: 'onboarding.steps' });
  const { classes } = useStyles();

  return (
    <Grid className={classes.root} container alignItems="center">
      <Box className={classes.line} component="span" />
      <StepsItem
        className={classes.item}
        label={t('personal')}
        icon={<UserIcon color="inherit" fontSize="inherit" />}
        state={personal}
      />
      <StepsItem
        className={classes.item}
        label={t('location')}
        icon={<PinIcon color="inherit" fontSize="inherit" />}
        state={location}
      />
      <StepsItem
        className={classes.item}
        label={t('incomes')}
        icon={<WalletIcon color="inherit" fontSize="inherit" />}
        state={incomes}
      />
    </Grid>
  );
};

export default OnboardingSteps;
