import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useRef, useState } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import DocumentItem, { DocumentItemStatus } from '@/components/DocumentItem';
import FormTextField from '@/components/FormTextField';
import regexPatterns from '@/constants/regexPatterns';
import { maxSize } from '@/modules/Documents/components/DocumentsForm';
import useStyles from './styles';

export interface ContractFormProps {
  uploadStatus?: DocumentItemStatus;
}

export interface ContractFormData {
  fullname: string;
  clabe: string;
  accountNumber: string;
  bankName: string;
  accountStatement: File;
  signature: string;
  tnc: boolean;
}

const accept = ['image/jpeg', 'image/png', 'application/pdf'];

const ContractForm: FC<ContractFormProps> = ({ uploadStatus }) => {
  const { t } = useTranslation('', { keyPrefix: 'contract' });
  const { classes, theme } = useStyles();
  const { control } = useFormContext<ContractFormData>();
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const handleBegin = () => {
    setShowPlaceholder(false);
  };

  const handleSignatureEnd = (onChange: (...e: any[]) => void, event: MouseEvent) => {
    event.preventDefault();

    const svgBase64 = (signatureRef.current as SignatureCanvas)
      .getSignaturePad()
      .toDataURL('image/svg+xml');
    const decoded = window.atob(svgBase64.replace('data:image/svg+xml;base64,', ''));

    onChange(decoded);
  };

  const handleClear = (onChange: ControllerRenderProps['onChange']) => {
    (signatureRef.current as SignatureCanvas).clear();
    setShowPlaceholder(true);
    onChange('');
  };

  const handleFileChange = (fn: ControllerRenderProps['onChange'], file?: File) => {
    if (file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')) || '';

      if (accept.includes(file.type) || accept.includes(fileExtension)) {
        fn(file);
      }
    } else {
      fn(undefined);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormTextField
          name="fullname"
          control={control}
          rules={{
            required: t('required') as string,
          }}
          fullWidth
          label={t('fields.fullname.label')}
          variant="filled"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="clabe"
          control={control}
          rules={{
            required: t('required') as string,
            pattern: {
              value: regexPatterns.clabe,
              message: t('fields.clabe.pattern'),
            },
          }}
          fullWidth
          label={t('fields.clabe.label')}
          variant="filled"
          color="secondary"
          inputProps={{
            maxLength: 18,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="accountNumber"
          control={control}
          rules={{
            required: t('required') as string,
            pattern: {
              value: regexPatterns.accountNumber,
              message: t('fields.accountNumber.pattern'),
            },
          }}
          fullWidth
          label={t('fields.accountNumber.label')}
          variant="filled"
          color="secondary"
          inputProps={{
            maxLength: 18,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="bankName"
          control={control}
          rules={{
            required: t('required') as string,
          }}
          fullWidth
          label={t('fields.bankName.label')}
          variant="filled"
          color="secondary"
        />
      </Grid>
      <Grid className={classes.fileUploadContainer} item xs={12}>
        <Controller
          name="accountStatement"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { value, onBlur, onChange } }) => (
            <DocumentItem
              label={t('fields.accountStatement.label')}
              caption={t('fields.accountStatement.caption')}
              TextFieldProps={{
                label: t('selectFile'),
                onBlur,
              }}
              file={value}
              status={uploadStatus}
              accept={accept}
              maxSize={maxSize}
              onChange={(file) => handleFileChange(onChange, file)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="signature"
          control={control}
          rules={{
            required: t('required') as string,
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <ClickAwayListener onClickAway={onBlur}>
              <FormControl fullWidth error={!!error}>
                <Box className={classes.signatureContainer}>
                  {value && (
                    <Button
                      className={classes.clearButton}
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={() => handleClear(onChange)}
                    >
                      {t('clean')}
                    </Button>
                  )}
                  {showPlaceholder && (
                    <Typography variant="body1" color="text.secondary">
                      {t('fields.signature.label')}
                    </Typography>
                  )}
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor={theme.palette.common.black}
                    canvasProps={{ className: classes.signature }}
                    clearOnResize={false}
                    onBegin={handleBegin}
                    onEnd={(e) => handleSignatureEnd(onChange, e)}
                  />
                </Box>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            </ClickAwayListener>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="tnc"
          control={control}
          rules={{
            required: t('fields.tnc.required') as string,
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} onBlur={onBlur} />}
                label={(
                  <Typography
                    className={classes.termsCaption}
                    variant="caption"
                    color="text.secondary"
                  >
                    {t('fields.tnc.label')}
                  </Typography>
                )}
              />
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContractForm;
