import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  termsCaption: {
    fontSize: 15,
    lineHeight: '120%',
  },
}));

export default useStyles;
