import apiConfig from '@/config/api';
import axios from 'axios';
import amortizationApi from './amortization';
import onboardingApi from './onboarding';

const instance = axios.create({
  baseURL: apiConfig.baseURL,
});

const api = {
  interceptors: instance.interceptors,
  amortization: amortizationApi(instance),
  onboarding: onboardingApi(instance),
};

export default api;
