import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom';

import { OnboardingStatus } from '@/api/onboarding/types';
import { ApiException } from '@/api/types';
import ROUTES from '@/constants/routes';
import useApiInterceptor from '@/hooks/useApiInterceptor';
import OnboardingLayout from '@/layouts/Onboarding';
import ContractPage from '@/modules/Contract/page';
import DocumentsPage from '@/modules/Documents/page';
import EmailPage from '@/modules/Email/page';
import IncomesPage from '@/modules/Incomes/page';
import LandingPage from '@/modules/Landing/page';
import LocationPage from '@/modules/Location/page';
import { OnboardingRequireAuth, OnboardingRequireStatus } from '@/modules/Onboarding/routes';
import PersonalInfoPage from '@/modules/PersonalInfo/page';
import PreauthorizationPage from '@/modules/Preauthorization/page';

const closeTimeout = 1000 * 10; // 10 seconds

const AppRoutes: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [apiError, setApiError] = useState('');

  const { loaded: loadedInterceptor } = useApiInterceptor(undefined, (err: AxiosError | Error) => {
    const resError = err as AxiosError<ApiException>;

    if (resError.response?.data?.name) {
      setApiError(resError.response.data.name);
    } else if (err instanceof Error && err.message === 'Network Error') {
      setApiError('INTERNAL_SERVER_ERROR');
    }

    setTimeout(() => {
      setApiError('');
    }, closeTimeout);

    return Promise.reject(err);
  });

  const apiErrorMessage = apiError && t(`apiExceptions.${apiError}`);

  const handleAlertClose = () => {
    setApiError('');
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location]);

  if (!loadedInterceptor) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: 1000,
          padding: 2,
        }}
      >
        <Fade in={!!apiErrorMessage}>
          <Box
            sx={{
              margin: '0 auto',
              maxWidth: '1200px',
            }}
          >
            <Alert severity="warning" variant="filled" onClose={handleAlertClose}>
              {apiErrorMessage}
            </Alert>
          </Box>
        </Fade>
      </Box>
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="landing" element={<LandingPage />} />

        <Route element={<OnboardingLayout />}>
          <Route path="email" element={<EmailPage />} />

          <Route element={<OnboardingRequireAuth />}>
            <Route element={<OnboardingRequireStatus statuses={[OnboardingStatus.ACTIVE]} />}>
              <Route path="personal-info" element={<PersonalInfoPage />} />
              <Route path="location" element={<LocationPage />} />
              <Route path="incomes" element={<IncomesPage />} />
            </Route>
            <Route
              path="preauthorization"
              element={(
                <OnboardingRequireStatus
                  statuses={[
                    OnboardingStatus.ACTIVE,
                    OnboardingStatus.PRE_AUTHORIZED,
                    OnboardingStatus.REVISION,
                    OnboardingStatus.REJECTED,
                  ]}
                  redirectPath={ROUTES.incomes}
                >
                  <PreauthorizationPage />
                </OnboardingRequireStatus>
              )}
            />
            <Route
              path="documents"
              element={(
                <OnboardingRequireStatus
                  statuses={[OnboardingStatus.PRE_AUTHORIZED, OnboardingStatus.REVISION]}
                  redirectPath={ROUTES.incomes}
                >
                  <DocumentsPage />
                </OnboardingRequireStatus>
              )}
            />
            <Route
              path="contract"
              element={(
                <OnboardingRequireStatus
                  statuses={[OnboardingStatus.AUTHORIZED, OnboardingStatus.REQUEST_SIGNED]}
                  redirectPath={ROUTES.root}
                >
                  <ContractPage />
                </OnboardingRequireStatus>
              )}
            />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to={ROUTES.root} replace />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
