import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ROUTES from '@/constants/routes';
import useOnboardingStore from '@/modules/Onboarding/store';
import { parseToCents } from '@/utils/currencyFormat';
import mapYesNoValue from '@/utils/mapYesNoValue';
import { IncomesFormData } from './components/IncomesForm';

const useIncomesPage = () => {
  const navigate = useNavigate();
  const [{ firstname, address, incomes }, actions] = useOnboardingStore();
  const form = useForm<IncomesFormData>({
    defaultValues: {
      occupation: incomes?.occupation || '',
      monthlyIncome: parseToCents(incomes?.monthlyIncome),
      monthlyOutcome: parseToCents(incomes?.monthlyOutcome),
      mortgage: mapYesNoValue(incomes?.mortgage),
      automotiveCredit: mapYesNoValue(incomes?.automotiveCredit),
      creditCard: mapYesNoValue(incomes?.creditCard),
    },
    mode: 'onTouched',
  });

  const handleFormSubmit = async (data: IncomesFormData) => {
    await actions.updateIncomes({
      occupation: data.occupation,
      monthlyIncome: `${+data.monthlyIncome / 100}`,
      monthlyOutcome: `${+data.monthlyOutcome / 100}`,
      mortgage: data.mortgage === 'yes',
      automotiveCredit: data.automotiveCredit === 'yes',
      creditCard: data.creditCard === 'yes',
    });
    navigate({ pathname: ROUTES.preauthorization });
  };

  useEffect(() => {
    if (!address) {
      navigate({ pathname: ROUTES.location });
    }
  }, [address, navigate]);

  return {
    form,
    firstname,
    handleFormSubmit,
  };
};

export default useIncomesPage;
