import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconContainer: {
    marginBottom: theme.spacing(2),
    fontSize: 50,
  },
  label: {
    fontStyle: 'italic',
    textAlign: 'center',
  },
}));

export default useStyles;
