import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const Minus: FC<SvgIconProps> = ({ ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <path d="M37.9912 25C37.9912 25.5621 37.4915 25.9993 36.9919 25.9993H13.0079C12.4458 25.9993 12.0085 25.5621 12.0085 25.0625C12.0085 24.5003 12.4458 24.0007 13.0079 24.0007H36.9919C37.4915 24.0007 37.9912 24.5003 37.9912 25Z" />
    </SvgIcon>
  );
};

export default Minus;
