import { AxiosInstance } from 'axios';
import {
  OnboardingGetPreauthorizationResponse,
  OnboardingGetRegisterResponse,
  OnboardingRegisterRequest,
  OnboardingRegisterResponse,
  OnboardingUploadDocumentRequest,
  OnboardingUpsertAccountRequest,
  OnboardingUpsertAccountResponse,
  OnboardingUpsertAddressRequest,
  OnboardingUpsertAddressResponse,
  OnboardingUpsertContractRequest,
  OnboardingUpsertContractResponse,
  OnboardingUpsertDocumentsRequest,
  OnboardingUpsertDocumentResponse,
  OnboardingUpsertIncomesRequest,
  OnboardingUpsertIncomesResponse,
  OnboardingUpsertPersonalInfoRequest,
  OnboardingUpsertPersonalInfoResponse,
} from './types';

const onboardingApi = (instance: AxiosInstance) => {
  const baseUrl = '/onboarding';

  return {
    register: async ({
      email, amount, term, interestRate,
    }: OnboardingRegisterRequest) => {
      return instance.post<OnboardingRegisterResponse>(baseUrl, {
        email,
        amount,
        term,
        interestRate,
      });
    },
    getRegister: async () => {
      return instance.get<OnboardingGetRegisterResponse>(baseUrl);
    },
    upsertPersonalInfo: async ({
      gender,
      firstname,
      lastname,
      secondLastname,
      country,
      state,
      birthdate,
      curp,
      rfc,
      phone,
    }: OnboardingUpsertPersonalInfoRequest) => {
      return instance.post<OnboardingUpsertPersonalInfoResponse>(`${baseUrl}/personal-info`, {
        gender,
        firstname,
        lastname,
        secondLastname,
        country,
        state,
        birthdate,
        curp,
        rfc,
        phone,
      });
    },
    upsertAddress: async ({
      privateName,
      street,
      exterior,
      interior,
      colony,
      postalCode,
      municipality,
      state,
    }: OnboardingUpsertAddressRequest) => {
      return instance.post<OnboardingUpsertAddressResponse>(`${baseUrl}/address`, {
        privateName,
        street,
        exterior,
        interior,
        colony,
        postalCode,
        municipality,
        state,
      });
    },
    upsertIncomes: async ({
      occupation,
      monthlyIncome,
      mortgage,
      automotiveCredit,
      creditCard,
      monthlyOutcome,
    }: OnboardingUpsertIncomesRequest) => {
      return instance.post<OnboardingUpsertIncomesResponse>(`${baseUrl}/incomes`, {
        occupation,
        monthlyIncome,
        mortgage,
        automotiveCredit,
        creditCard,
        monthlyOutcome,
      });
    },
    getPreauthorization: async () => {
      return instance.get<OnboardingGetPreauthorizationResponse>(`${baseUrl}/preauthorization`);
    },
    upsertDocuments: async (documents: OnboardingUpsertDocumentsRequest) => {
      return instance.post<OnboardingUpsertDocumentResponse>(`${baseUrl}/documents`, documents);
    },
    uploadDocumentFile: async ({ signedUrl, file }: OnboardingUploadDocumentRequest) => {
      const headers = new Headers();
      headers.append('Content-Type', file.type);

      return fetch(signedUrl, {
        method: 'PUT',
        headers,
        body: file,
      });
    },
    upsertAccount: async ({
      fullname,
      clabe,
      accountNumber,
      bankName,
    }: OnboardingUpsertAccountRequest) => {
      return instance.post<OnboardingUpsertAccountResponse>(`${baseUrl}/account`, {
        fullname,
        clabe,
        accountNumber,
        bankName,
      });
    },
    upsertContract: async ({
      contractVersion,
      termsConditionsVersion,
      signature,
    }: OnboardingUpsertContractRequest) => {
      return instance.post<OnboardingUpsertContractResponse>(`${baseUrl}/contract`, {
        contractVersion,
        termsConditionsVersion,
        signature,
      });
    },
  };
};

export default onboardingApi;
