import { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import ROUTES from '@/constants/routes';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import LocationForm from '../components/LocationForm';
import useLocationPage from '../useLocationPage';

const LocationPage: FC = () => {
  const { form, firstname, handleFormSubmit } = useLocationPage();

  return (
    <OnboardingStepsLayout
      steps={{
        personal: 'completed',
        location: 'active',
      }}
      backButton={{
        link: ROUTES.personalInfo,
      }}
      continueButton={{
        type: 'submit',
        disabled: !form.formState.isValid || form.formState.isSubmitting,
      }}
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <LocationForm name={firstname} />
      </FormProvider>
    </OnboardingStepsLayout>
  );
};

export default LocationPage;
