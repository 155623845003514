import { Resource } from 'i18next';

import contract from '@/modules/Contract/translations';
import documents from '@/modules/Documents/translations';
import email from '@/modules/Email/translations';
import incomes from '@/modules/Incomes/translations';
import landing from '@/modules/Landing/translations';
import location from '@/modules/Location/translations';
import onboarding from '@/modules/Onboarding/translations';
import personalInfo from '@/modules/PersonalInfo/translations';
import preauthorization from '@/modules/Preauthorization/translations';

const apiExceptions = {
  INTERNAL_SERVER_ERROR: 'Ocurrio un error con el servidor. Intente de nuevo más tarde por favor.',
  BAD_REQUEST: 'Hubo un error de validación al enviar los datos',
  NOT_FOUND: 'No se encontró el recurso solicitado.',
  TOKEN_NOT_PROVIDED: '',
  TOKEN_INVALID: '',
  TOKEN_EXPIRED: 'Tu solicitud ha expirado.',
  ONBOARDING: {
    REGISTER_SAVE_FAIL: 'Hubo un error al guardar los datos. Por favor contactenos.',
    REGISTER_NOT_FOUND: 'No se encontró la solicitud.',
    EMAIL_ALREADY_EXISTS: 'El correo electrónico ya esta registrado. Intenta con otro por favor.',
    REQUIRED_STATUS: 'El status de tu solicitud es invalido para realizar esta operación.',
  },
  PERSONAL_INFO: {
    CURP_ALREADY_EXISTS: 'El CURP ya esta registrado. Intenta con otro por favor.',
  },
};

const resources: Resource = {
  es: {
    translation: {
      apiExceptions,
      landing: landing.es,
      footer: {
        legalText:
          '©QRETEK 2022. Todos los derechos reservados | <1>Aviso de Privacidad</1> | Diseño y desarrollo por <2>Nebi</2>.',
        supportText:
          'Para cualquier duda o aclaración favor de comunicarte al correo <1>{{email}}</1> o vía WhatsApp al número {{phone}} en un horario de {{availability}}.',
      },
      onboarding: onboarding.es,
      email: email.es,
      personalInfo: personalInfo.es,
      location: location.es,
      incomes: incomes.es,
      preauthorization: preauthorization.es,
      documents: documents.es,
      contract: contract.es,
    },
  },
  en: {
    translation: {
      apiExceptions,
      landing: landing.es,
      footer: {
        legalText:
          '©QRETEK 2022. Todos los derechos reservados | <1>Aviso de Privacidad</1> | Diseño y desarrollo por <2>Nebi</2>.',
        supportText:
          'Para cualquier duda o aclaración favor de comunicarte al correo <1>{{email}}</1> o vía WhatsApp al número {{phone}} en un horario de {{availability}}.',
      },
      onboarding: onboarding.es,
      email: email.es,
      personalInfo: personalInfo.es,
      location: location.es,
      incomes: incomes.es,
      preauthorization: preauthorization.es,
      documents: documents.es,
      contract: contract.es,
    },
  },
};

export default resources;
