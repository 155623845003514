import { useCallback, useEffect, useState } from 'react';

import amortization from '@/constants/amortization';
import quoter from '@/constants/quoter';
import useTimeoutEffect from '@/hooks/useTimeoutEffect';
import useOnboardingStore from '@/modules/Onboarding/store';
import getPaymentData from '@/utils/getPaymentData';

const useQuoterCard = () => {
  const [, actions] = useOnboardingStore();
  const [amount, setAmount] = useState(quoter.amount.defaultValue);
  const [term, setTerm] = useState(quoter.term.defaultValue);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [loading, setLoading] = useState(false);

  const getMonthlyPayment = useCallback((data: { amount: number; term: number }) => {
    const interestItem = amortization.interestItems.find((item) => item.match(data.term));
    const interestRate = interestItem?.value as number;

    const paymentData = getPaymentData({
      amount: data.amount,
      term: data.term,
      interestRate,
    });

    return {
      ...paymentData,
      interestRate,
    };
  }, []);

  const handleAmountChange = (_e: Event, value: number) => {
    setAmount(value);
  };

  const handleTermChange = (_e: Event, value: number) => {
    setTerm(value);
  };

  useEffect(() => {
    const res = actions.getLoanRequest();

    if (res) {
      setAmount(+res.amount);
      setTerm(res.term);
    }
  }, [actions]);

  useTimeoutEffect(
    () => {
      const { payment, interestRate } = getMonthlyPayment({ amount, term });
      setMonthlyPayment(payment);
      actions.setLoanRequest({ amount: `${amount}`, term, interestRate });
      setLoading(false);
    },
    [actions, amount, getMonthlyPayment, term],
    1000,
    () => setLoading(true),
  );

  return {
    amount,
    term,
    monthlyPayment,
    loading,
    handleAmountChange,
    handleTermChange,
  };
};

export default useQuoterCard;
