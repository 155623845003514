import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ResponsiveTypography from '@/components/ResponsiveTypography';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import PersonalInfoForm from '../components/PersonalInfoForm';
import usePersonalInfo from '../usePersonalInfo';
import useStyles from './styles';

const PersonalInfoPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'personalInfo' });

  const { form, name, handleFormSubmit } = usePersonalInfo();

  return (
    <OnboardingStepsLayout
      steps={{
        personal: 'active',
      }}
      continueButton={{
        type: 'submit',
        disabled: !form.formState.isValid || form.formState.isSubmitting,
      }}
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ResponsiveTypography className={classes.title} variant="h5" breakpoint="sm">
            {t(name ? 'titleWithName' : 'title', {
              name,
            })}
          </ResponsiveTypography>
        </Grid>
        <Grid item xs={12}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <PersonalInfoForm />
          </FormProvider>
        </Grid>
      </Grid>
    </OnboardingStepsLayout>
  );
};

export default PersonalInfoPage;
