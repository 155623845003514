import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AvatarRadio from '@/components/AvatarRadio';
import FormTextField from '@/components/FormTextField';
import countries from '@/constants/countries';
import regexPatterns from '@/constants/regexPatterns';
import states from '@/constants/states';
import CalendarIcon from '@/icons/Calendar';
import FemaleIcon from '@/icons/Female';
import MaleIcon from '@/icons/Male';
import useStyles from './styles';

export interface PersonalInfoFormData {
  gender?: 'MALE' | 'FEMALE';
  firstname: string;
  lastname: string;
  secondLastname: string;
  country: string;
  state: string;
  birthdate?: Date;
  curp: string;
  rfc: string;
  phone: string;
}

const isAdult = (birthdate?: Date) => {
  if (birthdate) {
    const diff = Math.floor(moment().diff(birthdate, 'years', true));
    const age = Math.floor(diff);

    return age >= 18;
  }

  return false;
};

const PersonalInfoForm: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'personalInfo' });
  const isTouch = useMediaQuery('(hover: none)');
  const { control, formState, watch } = useFormContext<PersonalInfoFormData>();

  const DatePickerComponent = isTouch ? MobileDatePicker : DesktopDatePicker;

  const formCountry = watch('country');
  const statesOptions = useMemo(
    () => (formCountry
      .trim()
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') === 'MEXICO'
      ? states
      : []),
    [formCountry],
  );

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid
        item
        container
        spacing={2}
        alignItems={formState.errors.firstname ? 'flex-start' : 'flex-end'}
      >
        <Grid item xs>
          <FormTextField
            name="firstname"
            control={control}
            rules={{
              required: t('required') as string,
              minLength: {
                value: 2,
                message: t('minLength', { length: 2 }),
              },
              maxLength: {
                value: 40,
                message: t('maxLength', { length: 40 }),
              },
            }}
            fullWidth
            label={t('fields.firstname.label')}
            variant="filled"
            color="secondary"
            inputProps={{
              maxLength: 40,
            }}
          />
        </Grid>
        <Grid item container xs="auto" justifyContent="center">
          <Controller
            name="gender"
            control={control}
            rules={{
              required: t('required') as string,
            }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  classes={{
                    popper: classes.genderTooltip,
                  }}
                  placement="top"
                  arrow
                  open={!!error}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={error?.message || ''}
                >
                  <RadioGroup
                    className={classes.gender}
                    row
                    value={value}
                    onChange={onChange}
                    onMouseUp={onBlur}
                  >
                    <FormControlLabel
                      labelPlacement="top"
                      label={<Typography variant="caption">{t('fields.gender.Mujer')}</Typography>}
                      value="FEMALE"
                      control={(
                        <AvatarRadio color="primary">
                          <FemaleIcon color="inherit" />
                        </AvatarRadio>
                      )}
                    />
                    <FormControlLabel
                      labelPlacement="top"
                      label={<Typography variant="caption">{t('fields.gender.Hombre')}</Typography>}
                      value="MALE"
                      control={(
                        <AvatarRadio color="primary">
                          <MaleIcon color="inherit" />
                        </AvatarRadio>
                      )}
                    />
                  </RadioGroup>
                </Tooltip>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="lastname"
          control={control}
          rules={{
            required: t('required') as string,
            minLength: {
              value: 2,
              message: t('minLength', { length: 2 }),
            },
            maxLength: {
              value: 30,
              message: t('maxLength', { length: 30 }),
            },
          }}
          fullWidth
          label={t('fields.lastname.label')}
          variant="filled"
          color="secondary"
          inputProps={{
            maxLength: 30,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="secondLastname"
          control={control}
          rules={{
            required: t('required') as string,
            minLength: {
              value: 2,
              message: t('minLength', { length: 2 }),
            },
            maxLength: {
              value: 30,
              message: t('maxLength', { length: 30 }),
            },
          }}
          fullWidth
          label={t('fields.secondLastname.label')}
          variant="filled"
          color="secondary"
          inputProps={{
            maxLength: 30,
          }}
        />
      </Grid>
      <Grid item container xs={12} md={4} lg={12} xl={4} justifyContent="center" alignSelf="center">
        <Typography>{t('birthPlaceTitle')}</Typography>
      </Grid>
      <Grid item xs={6} md={4} lg={6} xl={4}>
        <Controller
          name="country"
          control={control}
          rules={{
            required: t('required') as string,
            minLength: {
              value: 2,
              message: t('minLength', { length: 2 }),
            },
            maxLength: {
              value: 40,
              message: t('maxLength', { length: 40 }),
            },
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <Autocomplete
              freeSolo
              disableClearable
              options={countries}
              value={value}
              onInputChange={(_event, v) => {
                onChange(v);
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  label={t('fields.country.label')}
                  variant="filled"
                  color="secondary"
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 40,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={6} xl={4}>
        <Controller
          name="state"
          control={control}
          rules={{
            required: t('required') as string,
            minLength: {
              value: 2,
              message: t('minLength', { length: 2 }),
            },
            maxLength: {
              value: 40,
              message: t('maxLength', { length: 40 }),
            },
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <Autocomplete
              freeSolo
              disableClearable
              options={statesOptions}
              value={value}
              onInputChange={(_event, v) => {
                onChange(v);
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  label={t('fields.state.label')}
                  variant="filled"
                  color="secondary"
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 40,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="birthdate"
          control={control}
          rules={{
            required: t('fields.birthdate.required') as string,
            validate: {
              isAdult,
            },
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <DatePickerComponent
              label={t('fields.birthdate.label')}
              disableFuture
              value={value || null}
              // eslint-disable-next-line max-len
              onChange={(date: Moment | null) => onChange(date && date.isValid() ? date.toDate() : undefined)}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              onClose={onBlur}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="filled"
                  fullWidth
                  color="secondary"
                  error={!!error}
                  helperText={error?.type && t(`fields.birthdate.${error?.type}`)}
                  onBlur={onBlur}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="curp"
          control={control}
          rules={{
            required: t('required') as string,
            pattern: {
              value: regexPatterns.curp,
              message: t('fields.curp.pattern'),
            },
          }}
          transformValue={(value) => value.toUpperCase()}
          fullWidth
          label={t('fields.curp.label')}
          variant="filled"
          color="secondary"
          inputProps={{
            maxLength: 18,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="rfc"
          control={control}
          rules={{
            required: t('required') as string,
            pattern: {
              value: regexPatterns.rfc,
              message: t('fields.rfc.pattern'),
            },
          }}
          transformValue={(value) => value.toUpperCase()}
          fullWidth
          label={t('fields.rfc.label')}
          variant="filled"
          color="secondary"
          inputProps={{
            maxLength: 13,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="phone"
          control={control}
          rules={{
            required: t('required') as string,
            pattern: {
              value: regexPatterns.phone,
              message: t('fields.phone.pattern'),
            },
          }}
          fullWidth
          label={t('fields.phone.label')}
          variant="filled"
          color="secondary"
          InputProps={{
            startAdornment: <InputAdornment position="start">+52</InputAdornment>,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            maxLength: 10,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfoForm;
