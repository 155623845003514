import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontWeight: 'normal',
  },
  radioItem: {
    '& > .MuiFormControlLabel-root': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
}));

export default useStyles;
