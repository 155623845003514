import AvatarRadio from '@/components/AvatarRadio';
import CurrencyTextField from '@/components/CurrencyTextField';
import DashedDivider from '@/components/DashedDivider';
import FormTextField from '@/components/FormTextField';
import ResponsiveTypography from '@/components/ResponsiveTypography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export interface IncomesFormData {
  occupation: string;
  monthlyIncome: string;
  mortgage: 'yes' | 'no' | '';
  automotiveCredit: 'yes' | 'no' | '';
  creditCard: 'yes' | 'no' | '';
  monthlyOutcome: string;
}

export interface IncomesFormProps {
  name?: string;
}

const IncomesForm: FC<IncomesFormProps> = ({ name = '' }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'incomes' });
  const { control } = useFormContext();

  const radioItems = [
    {
      key: 'mortgage',
      label: t('fields.mortgage.label'),
    },
    {
      key: 'automotiveCredit',
      label: t('fields.automotiveCredit.label'),
    },
    {
      key: 'creditCard',
      label: t('fields.creditCard.label'),
    },
  ];

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12} md="auto">
          <ResponsiveTypography>{t('occupationTitle', { name })}</ResponsiveTypography>
        </Grid>
        <Grid item xs={12} md>
          <FormTextField
            name="occupation"
            control={control}
            rules={{
              required: t('required') as string,
            }}
            fullWidth
            label={t('fields.occupation.label')}
            variant="filled"
            color="secondary"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="monthlyIncome"
          rules={{
            required: t('required') as string,
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <CurrencyTextField
              fullWidth
              label={t('fields.monthlyIncome.label')}
              variant="filled"
              color="secondary"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              formatOptions={{
                style: 'decimal',
              }}
              value={value}
              error={!!error}
              helperText={error?.message || t('fields.monthlyIncome.description')}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </Grid>

      {radioItems.map((item, idx) => (
        <Grid key={item.key} item container xs={12} spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="body1">{item.label}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Controller
              name={item.key}
              control={control}
              rules={{
                required: t('required') as string,
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <RadioGroup
                    className={classes.radioItem}
                    row
                    name={`incomes-${item.key}`}
                    value={value}
                    onChange={onChange}
                    onMouseUp={onBlur}
                  >
                    <FormControlLabel
                      labelPlacement="top"
                      label=""
                      value="yes"
                      control={(
                        <AvatarRadio color="primary">
                          <Typography variant="body1" component="span">
                            {t('yes')}
                          </Typography>
                        </AvatarRadio>
                      )}
                    />
                    <FormControlLabel
                      labelPlacement="top"
                      label=""
                      value="no"
                      control={(
                        <AvatarRadio color="primary">
                          <Typography variant="body1" component="span">
                            {t('no')}
                          </Typography>
                        </AvatarRadio>
                      )}
                    />
                  </RadioGroup>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          {idx < radioItems.length - 1 && (
            <Grid item xs={12}>
              <DashedDivider />
            </Grid>
          )}
        </Grid>
      ))}

      <Grid item xs={12}>
        <Controller
          name="monthlyOutcome"
          rules={{
            required: t('required') as string,
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <CurrencyTextField
              fullWidth
              label={t('fields.monthlyOutcome.label')}
              variant="filled"
              color="secondary"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              formatOptions={{
                style: 'decimal',
              }}
              value={value}
              error={!!error}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default IncomesForm;
