import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import useStyles from './styles';

export interface InfoImgCardClasses {}

export interface InfoImgCardProps {
  label: string;
  description: string;
  imgSrc: string;
  alt: string;
  className?: string;
}

const InfoImgCard: FC<InfoImgCardProps> = ({
  label, description, imgSrc, alt, className,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Card elevation={0} className={cx(classes.root, className)}>
      <CardMedia component="img" image={imgSrc} alt={alt} className={classes.media} />
      <CardContent className={classes.content}>
        <Typography variant="h3" component="h4" color="common.white" gutterBottom>
          {label}
        </Typography>
        <Typography variant="body1" component="h5" color="common.white">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default InfoImgCard;
