import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import {
  ChangeEvent, FC, MouseEvent, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveTypography from '@/components/ResponsiveTypography';
import AttachIcon from '@/icons/Attach';
import CheckIcon from '@/icons/Check';
import ErrorIcon from '@/icons/Error';
import formatBytes from '@/utils/formatBytes';
import useStyles from './styles';

export type DocumentItemStatus = 'loading' | 'completed' | 'error';

export interface DocumentItemProps {
  label: string;
  caption: string;
  textFieldLabel?: string;
  file?: { name: string; size: number; type: string };
  status?: DocumentItemStatus;
  accept?: string[];
  maxSize?: number;
  TextFieldProps?: Omit<
  MuiTextFieldProps,
  'color' | 'fullWidth' | 'helperText' | 'type' | 'value' | 'variant'
  >;
  onChange?: (file?: File) => void;
}

const mimeTypeRegex = /^(application|image|video|audio)\//;

const DocumentItem: FC<DocumentItemProps> = ({
  label,
  caption,
  file,
  status,
  accept = [],
  maxSize = 0,
  TextFieldProps,
  onChange = () => null,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation('', { keyPrefix: 'documents' });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const sizeLabel = formatBytes(maxSize);
  const formatsLabel = useMemo(
    () => accept.map((a) => a.replace(mimeTypeRegex, '').toUpperCase()).join(', '),
    [accept],
  );
  const fileValueLabel = file ? `${file.name} (${formatBytes(file.size)})` : '';

  const endAdornment = useMemo(() => {
    if (status === 'loading') {
      return <CircularProgress color="info" size={32} />;
    }
    if (status === 'completed') {
      return <CheckIcon color="success" fontSize="medium" />;
    }
    if (status === 'error') {
      return <ErrorIcon color="error" fontSize="medium" />;
    }

    return <AttachIcon color="primary" fontSize="medium" />;
  }, [status]);

  const handleTextFieldClick = (e: MouseEvent) => {
    e.preventDefault();

    if (fileInputRef.current && status !== 'loading') {
      fileInputRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const eventFile = e.target.files?.[0];

    if (eventFile && eventFile.size <= maxSize) {
      onChange(eventFile);
    } else {
      // eslint-disable-next-line no-alert
      alert(t('maxSizeError', { size: sizeLabel }));
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ResponsiveTypography breakpoint="md" variant="body1" gutterBottom>
          {label}
        </ResponsiveTypography>
        <ResponsiveTypography
          breakpoint="md"
          variant="caption"
          component="p"
          color="text.secondary"
        >
          {caption}
        </ResponsiveTypography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TextFieldProps}
          InputProps={{
            ...TextFieldProps?.InputProps,
            readOnly: true,
            disabled: status === 'loading',
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          fullWidth
          variant="filled"
          color="secondary"
          value={fileValueLabel}
          helperText={(
            <ResponsiveTypography className={classes.caption} variant="caption">
              {t('allowed', { formats: formatsLabel, size: sizeLabel })}
            </ResponsiveTypography>
          )}
          onClick={handleTextFieldClick}
        />

        <input
          style={{ display: 'none' }}
          type="file"
          ref={fileInputRef}
          onChange={handleChange}
          accept={accept.join(',')}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentItem;
