import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

const getTheme = (mode: Required<ThemeOptions>['palette']['mode'] = 'dark') => {
  const lightThemePalette: ThemeOptions['palette'] = {
    mode: 'light',
    background: {
      default: '#FCFAF2',
      paper: '#FCFAF2',
    },
    text: {
      primary: '#081420',
      secondary: '#6C7C93',
      disabled: '#C6BD9F',
    },
    primary: {
      main: '#FCFAF2',
      contrastText: '#081420',
    },
    secondary: {
      main: '#081420',
      contrastText: '#FCFAF2',
    },
    action: {
      disabled: '#C6BD9F',
      disabledBackground: '#ECE9DF',
    },
  };

  const darkThemePalette: ThemeOptions['palette'] = {
    mode: 'dark',
    background: {
      default: '#081420',
      paper: '#081420',
    },
    text: {
      primary: '#FCFAF2',
      secondary: '#6C7C93',
      disabled: '#C6BD9F',
    },
    primary: {
      main: '#081420',
      contrastText: '#FCFAF2',
    },
    secondary: {
      main: '#FCFAF2',
      contrastText: '#081420',
    },
    action: {
      disabled: '#C6BD9F',
      disabledBackground: '#ECE9DF',
    },
  };

  const finalTheme = createTheme({
    palette: {
      ...(mode === 'dark' ? darkThemePalette : lightThemePalette),
      info: {
        main: '#C5E2F2',
        contrastText: '#081420',
      },
      success: {
        main: '#C3EECF',
        contrastText: '#081420',
      },
    },
    typography: {
      fontFamily: ['Fira Sans', 'sans-serif'].join(','),
      fontSize: 18,
      fontWeightBold: 700,
      fontWeightMedium: 500,
      fontWeightRegular: 400,
      h1: {
        fontWeight: 700,
        fontSize: 48,
      },
      h2: {
        fontWeight: 600,
        fontSize: 42,
      },
      h3: {
        fontWeight: 500,
        fontSize: 38,
      },
      h4: {
        fontWeight: 800,
        fontSize: 32,
      },
      h5: {
        fontWeight: 800,
        fontSize: 24,
      },
      h6: {
        fontWeight: 600,
        fontSize: 20,
      },
      button: {
        fontWeight: 700,
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 30,
          },
          standardInfo: ({ theme }) => ({
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            '& > .MuiAlert-icon': {
              color: 'inherit',
            },
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme }) => ({
            width: theme.spacing(7.5),
            height: theme.spacing(7.5),
            backgroundColor: theme.palette.action.disabledBackground,
            fontSize: 30,
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 40,
            borderWidth: 3,
            ':hover': {
              borderWidth: 3,
            },
          },
          outlined: {
            borderWidth: 3,
          },
          containedPrimary: ({ theme }) => ({
            borderColor: theme.palette.primary.main,
          }),
          outlinedPrimary: ({ theme }) => ({
            borderColor: theme.palette.primary.main,
          }),
          sizeSmall: ({ theme }) => ({
            padding: theme.spacing(0.5, 3),
            '&.MuiButton-root': {
              fontSize: 17,
            },
          }),
          sizeMedium: ({ theme }) => ({
            padding: theme.spacing(1, 4),
          }),
          sizeLarge: ({ theme }) => ({
            padding: theme.spacing(1.5, 5),
          }),
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
          color: 'inherit',
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            height: 2,
          },
          thumb: {
            borderWidth: 4,
            borderStyle: 'solid',
            width: 30,
            height: 30,
          },
          thumbSizeSmall: {
            width: 24,
            height: 24,
          },
          thumbColorPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.primary.main,
          }),
          thumbColorSecondary: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.secondary.main,
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiInputLabel-filled': {
              left: theme.spacing(0.5),
              top: 2,
            },
            '&.MuiInputLabel-shrink': {
              top: 5,
              fontSize: theme.typography.body1.fontSize,
            },
          }),
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.primary.contrastText,
            '& .MuiInputLabel-root.MuiFormLabel-colorPrimary': {
              color: theme.palette.grey[600],
            },
            '& .MuiInputLabel-root.MuiFormLabel-colorPrimary.Mui-focused': {
              color: theme.palette.grey[400],
            },
            '& .MuiInputLabel-root.MuiFormLabel-colorSecondary': {
              color: theme.palette.grey[400],
            },
            '& .MuiInputLabel-root.MuiFormLabel-colorSecondary.Mui-focused': {
              color: theme.palette.text.secondary,
            },
          }),
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: ({ theme }) => {
            const backgroundColor = theme.palette.mode === 'dark'
              ? theme.palette.common.black
              : theme.palette.common.white;
            const color = theme.palette.primary.contrastText;

            return {
              overflow: 'hidden',
              border: `2px solid ${backgroundColor}`,
              borderRadius: 20,
              backgroundColor,
              color,
              transition: theme.transitions.create(['border-color']),
              '&:hover': {
                backgroundColor,
              },
              '&.Mui-focused': {
                backgroundColor,
                borderColor: theme.palette.grey[400],
              },
              '& .MuiInputAdornment-root .MuiIconButton-root': {
                color: theme.palette.secondary.light,
              },
              '& > .MuiSvgIcon-root': {
                color: 'inherit',
              },
            };
          },
          colorSecondary: ({ theme }) => {
            const backgroundColor = theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.black;
            const color = theme.palette.secondary.contrastText;

            return {
              borderColor: backgroundColor,
              backgroundColor,
              color,
              '&:hover': {
                backgroundColor,
              },
              '&.Mui-focused': {
                backgroundColor,
                borderColor: theme.palette.action.disabledBackground,
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.action.disabledBackground,
              },
              '& .MuiInputAdornment-root .MuiIconButton-root': {
                color: theme.palette.primary.light,
              },
              '& > .MuiSvgIcon-root': {
                color: 'inherit',
              },
            };
          },
          input: ({ theme }) => ({
            padding: theme.spacing(3.25, 1.75, 1),
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #fff inset',
              WebkitTextFillColor: theme.palette.secondary.contrastText,
            },
          }),
          adornedEnd: ({ theme }) => ({
            paddingRight: theme.spacing(2),
          }),
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&:before': {
              borderRadius: 10,
            },
          },
        },
      },
    },
  });

  return responsiveFontSizes(finalTheme);
};

export default getTheme;
