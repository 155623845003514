import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveTypography from '@/components/ResponsiveTypography';
import TitleWithIcon from '@/components/TitleWithIcon';
import ROUTES from '@/constants/routes';
import ErrorIcon from '@/icons/Error';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import useOnboardingStore from '../../store';

const ExpiredRequest: FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'onboarding' });
  const [{ firstname }] = useOnboardingStore();

  return (
    <OnboardingStepsLayout
      steps={{
        personal: 'completed',
        location: 'completed',
        incomes: 'completed',
      }}
      backButton={{
        link: ROUTES.root,
      }}
      showDivider
    >
      <Grid container direction="column" rowSpacing={4}>
        <Grid item>
          <TitleWithIcon
            label={t('expiredTitle', {
              name: firstname ? ` ${firstname}` : '',
            })}
            icon={<ErrorIcon color="primary" fontSize="inherit" />}
          />
        </Grid>
        <Grid item>
          <ResponsiveTypography variant="h5" fontWeight={400}>
            {t('expiredMessage')}
          </ResponsiveTypography>
        </Grid>
      </Grid>
    </OnboardingStepsLayout>
  );
};

export default ExpiredRequest;
