import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  label: {
    fontWeight: 400,
  },
  progress: {
    display: 'block',
  },
}));

export default useStyles;
