import Typography, { TypographyProps, TypographyTypeMap } from '@mui/material/Typography';
import { Breakpoint } from '@mui/material/styles';
import { ReactElement } from 'react';
import useStyles from './styles';

export interface ResponsiveTypographyProps {
  breakpoint?: Breakpoint;
}

const ResponsiveTypography = <
  D extends React.ElementType = TypographyTypeMap['defaultComponent'],
  P = {},
>({
    className,
    breakpoint,
    ...typographyProps
  }: TypographyProps<D, P> & ResponsiveTypographyProps): ReactElement => {
  const { classes, cx } = useStyles({ breakpoint });

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Typography {...typographyProps} className={cx(classes.root, className)} />;
};

export default ResponsiveTypography;
