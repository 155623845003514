import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { FC } from 'react';
import useStyles from './styles';

export interface LinkProps extends Omit<MuiLinkProps, keyof RouterLinkProps>, RouterLinkProps {
  fullWidth?: boolean;
}

const Link: FC<LinkProps> = ({ fullWidth, className, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <MuiLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={cx(fullWidth && classes.fullWidth, className)}
      component={RouterLink}
    />
  );
};

export default Link;
