export interface CurrencyFormatOptions extends Intl.NumberFormatOptions {
  locales?: string | string[];
}

export const defaultCurrencyFormatOptions: CurrencyFormatOptions = {
  locales: 'es-MX',
  currency: 'MXN',
  minimumFractionDigits: 2,
};

const currencyFormat = (
  amount: number | string,
  options: CurrencyFormatOptions = {},
): string => {
  const { locales = defaultCurrencyFormatOptions.locales, ...numberFormatOptions } = options;
  const currencyFormatter = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: defaultCurrencyFormatOptions.currency,
    minimumFractionDigits: defaultCurrencyFormatOptions.maximumFractionDigits,
    ...numberFormatOptions,
  });

  return currencyFormatter.format(+amount);
};

const centsRegex = /\.(\d+)$/;

export const parseToCents = (value?: string) => {
  if (!value) {
    return '';
  }

  if (centsRegex.test(value)) {
    return value.replace(centsRegex, (sub) => sub.replace('.', '').padEnd(2, '0'));
  }

  return `${value}00`;
};

export default currencyFormat;
