export default {
  es: {
    loading: {
      title: 'Hemos completado los datos necesarios para solicitar tu préstamo.',
      text: 'Espera un momento mientras procesamos tu solicitud, {{name}}.',
    },
    madeDecision: {
      approved: {
        title: '¡Felicidades, {{name}}! tienes un préstamo preautorizado por {{amount}}.',
        stepsLabel:
          'Para finalizar tu solicitud, será necesario compartirnos los siguientes documentos:',
      },
      revision: {
        title: 'Gracias, {{name}}. Estamos revisando la información que nos enviaste.',
        stepsLabel:
          'Para finalizar tu solicitud, será necesario compartirnos los siguientes documentos:',
      },
      rejected: {
        title: 'Lo sentimos, {{name}}.\n No nos fue posible otorgar un préstamo por ahora.',
        stepsLabel: '¿Qué puedes hacer ahora?',
        text: 'Deberás esperar 3 meses para poder solicitar nuevamente un crédito.',
      },
      infoText: 'Hemos enviado esta misma información a tu correo <1>{{email}}</1>',
    },
    documents: {
      officialId: 'Identificación oficial por ambos lados (INE, pasaporte o cédula profesional).',
      accountStatement: 'Tu último estado de cuenta.',
      accountStatementOrBureau:
        'Una copia de tus últimos 2 estados de cuenta o del reporte de Buró de Crédito no mayor a 3 meses de antigüedad.',
      proofOfAddress:
        'Comprobante de domicilio no mayor a 3 meses de antigüedad (Luz, Agua, Predial).',
      selfie: 'Tomarte una foto tipo selfie.',
    },
  },
};
