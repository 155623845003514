import ResponsiveTypography from '@/components/ResponsiveTypography';
import Grid from '@mui/material/Grid';
import { FC, ReactNode } from 'react';
import useStyles from './styles';

export interface TitleWithIconProps {
  label: string;
  icon?: ReactNode;
}

const TitleWithIcon: FC<TitleWithIconProps> = ({ label, icon }) => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      direction={{ xs: 'column-reverse', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      rowSpacing={3}
      columnSpacing={6}
    >
      <Grid item md>
        <ResponsiveTypography className={classes.label} variant="h4" breakpoint="md">
          {label}
        </ResponsiveTypography>
      </Grid>
      {icon && (
        <Grid className={classes.icon} item md="auto">
          {icon}
        </Grid>
      )}
    </Grid>
  );
};

export default TitleWithIcon;
