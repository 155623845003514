export default {
  es: {
    carouselTitle: 'Tu préstamo QRETEK en 3 sencillos pasos.',
    imagesTitle1: 'Despreocúpate',
    imagesText1:
      'Porque sabemos que hay imprevistos, decidimos ayudarte de una forma sencilla y sin tanto papeleo parea que hoy puedas dormir tranquilo.',
    imagesText1Mobile: 'Soluciona pequeños imprevistos que nos impiden dormir.',
    imagesTitle2: 'Disfruta',
    imagesText2:
      'Escápate de la cotidianidad y Disfruta tu préstamos QRETEK para hacer ese viaje con tus seres queridos a donde nunca han ido.',
    imagesText2Mobile: 'Haz ese viaje con tus seres queridos a donde nunca han ido.',
    imagesTitle3: 'Date ese gusto',
    imagesText3:
      'Es momento de recompensarte por ese gran esfuerzo. Seguro hay algo que te mueve y no te has podido comprar. ¡Llegó el momento!',
    imagesText3Mobile: 'Seguro hay algo que te mueve y no te has podido comprar.',
    title: 'Solicitud de Préstamo',
    continue: 'Continuar',
    return: 'Regresar',
    help: 'Ayuda',
    finish: 'Terminar',
    send: 'Enviar',
    steps: {
      personal: 'Personal',
      location: 'Ubicación',
      incomes: 'Ingresos',
    },
    expiredTitle: 'Lo sentimos{{name}}, Tu solicitud ha expirado.',
    expiredMessage: 'Deberás esperar 3 meses para volver a hacer una solicitud de prestamo.',
  },
};
