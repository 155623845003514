import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import ResponsiveTypography from '@/components/ResponsiveTypography';
import accountDataConfig from '@/config/accountData';
import OnboardingStepsLayout from '@/layouts/Onboarding/OnboardingStepsLayout';
import currencyFormat from '@/utils/currencyFormat';
import ContractForm from '../components/ContractForm';
import useContractPage from '../useContractPage';
import useStyles from './styles';

const ContractPage: FC = () => {
  const { t } = useTranslation('', { keyPrefix: 'contract' });
  const { t: tOnboarding } = useTranslation('', { keyPrefix: 'onboarding' });
  const { classes } = useStyles();
  const {
    form,
    firstname,
    amount,
    signDate,
    accountData,
    uploadStatus,
    finished,
    handleFormSubmit,
    handleDownloadClick,
  } = useContractPage();

  const accountDataItems = [
    {
      label: t('holder'),
      value: accountData.name,
    },
    {
      label: t('account'),
      value: accountData.account,
    },
    {
      label: t('clabe'),
      value: accountData.clabe,
    },
    {
      label: t('bank'),
      value: accountData.bankName,
    },
    {
      label: t('reference'),
      value: accountData.reference,
    },
  ];

  const paymentDay = `${moment(signDate).get('date')}`;

  return (
    <OnboardingStepsLayout
      title={t(finished ? 'finishedTitle' : 'startTitle', {
        name: firstname,
        amount: currencyFormat(amount),
      })}
      supportText={
        finished ? (
          <Trans
            t={t}
            i18nKey="supportText"
            values={{
              email: accountDataConfig.email,
              phone: accountDataConfig.phone,
              availability: accountDataConfig.availability,
            }}
            components={{
              1: (
                <MuiLink
                  href={`mailto:${accountDataConfig.email}`}
                  target="_blank"
                  underline="always"
                />
              ),
            }}
          />
        ) : (
          true
        )
      }
      continueButton={{
        type: 'submit',
        label: tOnboarding(finished ? 'finish' : 'send'),
        hideIcon: finished,
        disabled: !form.formState.isValid || form.formState.isSubmitting,
      }}
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body1">{t(finished ? 'finishedText' : 'startText')}</Typography>
        </Grid>
        {finished ? (
          <>
            <Grid item xs={12}>
              <Trans
                t={t}
                i18nKey="text"
                components={{
                  1: <Typography className={classes.paragraph} variant="body2" />,
                  2: <strong />,
                  3: (
                    <button
                      type="button"
                      aria-label="Download"
                      className={classes.amortizationLink}
                      onClick={handleDownloadClick}
                    />
                  ),
                }}
                values={{ day: paymentDay }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                {accountDataItems.map((item) => (
                  <Grid key={item.label} container alignItems="center">
                    <Grid item xs={7} md={5}>
                      <strong>{`${item.label}:`}</strong>
                    </Grid>
                    <Grid item xs={5} md={7}>
                      {item.value}
                    </Grid>
                  </Grid>
                ))}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormProvider {...form}>
                <ContractForm uploadStatus={uploadStatus} />
              </FormProvider>
            </Grid>
            <Grid item xs={12}>
              <ResponsiveTypography variant="body2">{t('helperText')}</ResponsiveTypography>
            </Grid>
          </>
        )}
      </Grid>
    </OnboardingStepsLayout>
  );
};

export default ContractPage;
